import { Dispatch } from 'react';
import { PermissionsEnum } from '../../util/enum/permissions.enum';
import * as types from '../types/user.types';

export const LoginAction = (data: { user: any, permissions: PermissionsEnum[], token: string, refreshToken: string }) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: types.LOGIN_USER,
    payload: {
      user: data.user,
      permissions: data.permissions,
      token: data.token,
      refreshToken: data.refreshToken
    }
  });
}

export const LogoutAction = () => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: types.LOGOUT_USER
  });
}
