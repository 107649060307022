import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/prerequisites.api";
import Head from "../components/Head";
import React from "react";
import Button from "../components/Button";
import { handleResponseError } from "./recipesDatabase/utils";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import coursesApi from "../api/courses.api";
import ComponentGuard from "../components/guards/ComponentGuard";

const Prerequisite = () => {

    let [prerequisite, setPrerequisite] = useState({} as any);
    let [courses, setCourses] = useState([] as any[])
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    async function loadPrerequisite() {
        try {
            setLoading(true);
            let { data: prerequisite } = await api.findOne(+id);
            parsePrerequisite(prerequisite);
            setLoading(false);

            try {
                let { data: { data: courses } } = await coursesApi.find();
                setCourses(courses);
            } catch {
                // could not get all courses
            }
        } catch {
            history.push("/");
        }
    }

    function parsePrerequisite(prerequisite: any) {
        setPrerequisite(prerequisite);
    }

    useEffect(() => {
        (async () => {
            loadPrerequisite();
        })();
    }, []);

    const refresh = loadPrerequisite;
    return (

        <React.Fragment>
            <Head title='Prerequisite' path={`/prerequisite/${id}`} />
            <SingleComponent
                title="Prerequisite Info"
                fields={[
                    {
                        name: "Name",
                        value: prerequisite.name,
                    },
                ]}
            />

            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.EditCoursePreRequisite]}>
                    <Button
                        color="primary"
                        label="Add alternative course"
                        withForm
                        form={{
                            title: "Add an alternative course",
                            description:
                                "Choose the course you would like to add as an alternative to this prerequisite.",
                            apiRequest: async (data: any) => { return await api.addCourseToPrerequisite(+id, data.courseId) },
                            inputs: [
                                {
                                    type: "select",
                                    name: "Course",
                                    keyName: "courseId",
                                    required: true,
                                    options: ((courses) => {
                                        let courseOptions = [];
                                        for (let course of courses) {
                                            courseOptions.push({
                                                label: course.department.code + ":" + course.code,
                                                value: course.id,
                                            });
                                        }
                                        return courseOptions;
                                    })(courses),
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Prerequisite added successfully.";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <ComponentGuard requiredPermissions={[PermissionsEnum.ViewPrerequisiteDetails]}>
                <CollectionComponent
                    title="Prerequisites"
                    loading={loading}
                    data={prerequisite.prerequisiteCourses}
                    columns={[
                        {
                            title: "Course Code",
                            field: "name",
                            editable: "never",
                            render: ((data) => {
                                return data.department.code + ':' + data.code;
                            }),
                        },
                    ]}
                    backendPagination
                    noEdit
                    onRowDelete={async (oldData: any) => {
                        await api.deleteCourseFromPrerequisite(+id, oldData.id);
                        refresh();
                    }}
                    disableKey="archived"
                    mainPermission={PermissionsEnum.EditCoursePreRequisite}
                />
            </ComponentGuard>
        </React.Fragment>
    );
};

export default Prerequisite;