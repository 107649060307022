import axios from "axios";

const getOffering = (id: number) => axios.get(`/programs/offerings/${id}`);

const getOfferingBase = (id: number) => axios.get(`/programs/offering/${id}`);

const addCourseToOffering = (offeringId: number, data: any) =>
  axios.post(`/programs/offering/${offeringId}/course`, data);

const removeCourseFromOffering = (
  offeringId: number,
  programCourseId: number
) => axios.delete(`/programs/offering/${offeringId}/course/${programCourseId}`);

const addConcentrationOffering = (offeringId: number, data: any) =>
  axios.post(`/programs/offering/${offeringId}/concentration`, data);

const archiveConcentrationOffering = (concentrationOfferingId: number) =>
  axios.delete(`/programs/offering/concentration/${concentrationOfferingId}`);

const restoreConcentrationOffering = (concentrationOfferingId: number) =>
  axios.patch(`/programs/offering/concentration/${concentrationOfferingId}`);

const startApplicationProcess = (id: number, data: any) => {
  return axios.post(`/programs/offering/${id}/start-application-process`, data);
};

const getCourses = (id: number) => axios.get(`/programs/offerings/${id}/programCourses`);

const getConcentrations = (id: number) => axios.get(`/programs/offerings/${id}/concentrations`);

const getProgramCourses = (id: number) => axios.get(`/programs/${id}/programCourses`);

const getProgramConcentrations = (id: number) => axios.get(`/programs/${id}/concentrations`);

const getGradingSchemas = (id: number) => axios.get(`/programs/offerings/${id}/gradingSchemas`);

const routes = {
  getOffering,
  getOfferingBase,
  addCourseToOffering,
  removeCourseFromOffering,
  addConcentrationOffering,
  archiveConcentrationOffering,
  restoreConcentrationOffering,
  startApplicationProcess,
  getCourses,
  getConcentrations,
  getProgramCourses,
  getProgramConcentrations,
  getGradingSchemas,
};

export default routes;
