import React from "react";
import { SnackbarProvider } from "notistack";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const styles = () =>
  makeStyles(() => ({
    success: { backgroundColor: "#137cbd !important" },
    error: { backgroundColor: "blue !important" },
    warning: { backgroundColor: "#f79009 !important" },
    info: { backgroundColor: "#667085 !important" },
  }));

const SnackBar: React.FunctionComponent = (props) => {
  const notistackRef = React.useRef<SnackbarProvider>(null);
  const onClickDismiss = (id: any) => () => {
    if (notistackRef !== null && notistackRef.current !== null) {
      notistackRef.current.closeSnackbar(id);
    }
  };

  const classes = styles()();
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClickDismiss(key)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      iconVariant={{
        success: "✅ ",
        error: "✖️ ",
        warning: "⚠️ ",
        info: "ℹ️ ",
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default SnackBar;
