import { Info, Create } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router";
import api from "../api/courseOfferings.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import Head from "../components/Head";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { MTableBodyRow } from "material-table";
import { makeStyles } from "@material-ui/core";

const CourseRegistrations: React.FunctionComponent<{
  language: string;
}> = (props) => {
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
  ] = useFindRequestQueryParams(async () => {
    let { count, data } = (await api.find()).data;
    data = data.map((entity: any) => {
      for (let translation of entity.course.translations) {
        if (translation.language === props.language)
          entity[`course__name`] = translation.name;
      }
      for (let translation of entity.course.department.translations) {
        if (translation.language === props.language)
          entity[`dept__name`] = translation.name;
      }
      return entity;
    });
    return {
      data: {
        count,
        data,
      },
    };
  }, "courseRegistrations");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const classes = makeStyles({
    row: {
      opacity: 0.5,
    },
  })();

  return (
    <React.Fragment>
      <Head title="Course Registrations" path="/course-registration" />
      <CollectionComponent
        title="Course Offerings"
        loading={loading}
        data={data}
        viewOnly
        customComponents={{
          Row: (props: any) => {
            if (!props.data.fulfilled) {
              return <MTableBodyRow className={classes.row} {...props} />

            }
            return <MTableBodyRow {...props} />

          }
        }}
        columns={[
          {
            title: t("CODE"),
            field: "course_code",
          },
          {
            title: t("COURSE"),
            field: "course__name",
          },
          {
            title: t("DEPARTMENT"),
            field: "dept__name",
          },
          {
            title: t("YEAR"),
            field: "semester.year",
          },
          {
            title: t("SEMESTER"),
            field: "semester.semester",
          },
          // {
          //   title: "Department",
          //   field: "dept_en_name",
          // },
          // {
          //   title: "Arabic Department name",
          //   field: "dept_ar_name",
          // },
          // {
          //   title: "English name",
          //   field: "en_name",
          // },
          // {
          //   title: "Arabic name",
          //   field: "ar_name",
          // },
        ]}
        // backendPagination
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        actions={[
          {
            icon: () => <Info />,
            onClick: (_: any, row: any) =>
              /*history.push(`/course/${row.course.id}`)*/ history.push(
              `/course-offering/${row.id}`
            ),
            tooltip: "View Course Info",
          },
          {
            icon: () => <Create />,
            condition: (data: any) => data.fulfilled,
            onClick: async (_: any, row: any) => {
              await api.register(row.id);
              refresh();
              enqueueSnackbar("Registered successfully", {
                variant: "success",
              });
            },
            tooltip: "Register for Course",
          },
        ]}
        disableKey="full"
      />
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(CourseRegistrations);
