/**
 * Contains all course (offering) permissions that will be used in course(offering)-role-based access middleware
 */

/*
* File needs to populated with the appropriate course (offering) based permissions
*/

export enum CourseOfferingPermissionsEnum {
  // InviteEmployees = "InviteEmployees",
  // BanEmployees = "BanEmployees",
  // EditEmployees = "EditEmployees",
  // ViewEmployees = "ViewEmployees",

  // ViewRoles = "ViewRoles",
  // ModifyRoles = "ModifyRoles",
  // AssignCourseOfferingRoles = "AssignCourseOfferingRoles",

  // ViewPrograms = "ViewPrograms",
  // ModifyPrograms = "ModifyPrograms",
  // SetProgramPricePerCreditHour = "SetProgramPricePerCreditHour",

  // InviteInstructor = "InviteInstructor",
  // ViewInstructors = "ViewInstructors",
  // ModifyInstructors = "ModifyInstructors",

  // ViewRecipeDatabase = "ViewRecipeDatabase",
  // ModifyRecipeDatabase = "ModifyRecipeDatabase",

  // ViewCampuses = "ViewCampuses",
  // ModifyCampuses = "ModifyCampuses",

  // ViewRooms = "ViewRooms",
  // ModifyRooms = "ModifyRooms",

  // ViewBuildings = "ViewBuildings",
  // ModifyBuildings = "ModifyBuildings",

  // ViewCourses = "ViewCourses",
  // ModifyCourses = "ModifyCourses",
  // ViewCoursePrograms = "ViewCoursePrograms",
  // OpenCourseForProgram = "OpenCourseForProgram",
  // ViewCourseOfferings = "ViewCourseOfferings",
  // ViewCourseOffering = "ViewCourseOffering",

  // ViewPrerequisites = "ViewPrerequisites",
  // ModifyPrerequisites = "ModifyPrerequisites",

  // EditDefaultCoursePrice = "EditDefaultCoursePrice",

  // ViewStudents = "ViewStudents",
  // ViewCourseStudents = "ViewCourseStudents",
  // ViewStudentDetails = "ViewStudentDetails",
  // EditStudents = "EditStudents",
  // EditCourseStudents = "EditCourseStudents",
  // ViewStudentExpenses = "ViewStudentExpenses",
  // AddStudentExpense = "AddStudentExpense",
  // EditStudentExpense = "EditStudentExpense",
  // ArchiveStudentExpense = "ArchiveStudentExpense",
  // RestoreStudentExpense = "RestoreStudentExpense",

  // ViewTimeSlots = "ViewTimeSlots",
  // EditTimeSlots = "EditTimeSlots",

  // EditCourseSchedules = "EditCourseSchedules",
  // EditCourseStaff = "EditCourseStaff",
  // EditCourseOfferingSchedules = "EditCourseOfferingSchedules",
  // EditCourseOfferingStaff = "EditCourseOfferingStaff",

  // CreateDepartments = "CreateDepartments",
  // ViewDepartments = "ViewDepartments",
  // EditDepartmentTranslations = "EditDepartmentTranslations",
  // DeleteDepartments = "DeleteDepartments",
  // RestoreDepartments = "RestoreDepartments",

  // CreateCourses = "CreateCourses",
  // AddCoursePreRequisites = "AddCoursePreRequisites",
  // EditCoursePreRequisite = "EditCoursePreRequisite",
  // DeleteCoursePreRequisites = "DeleteCoursePreRequisites",
  // RestoreCoursePreRequisites = "RestoreCoursePreRequisites",
  // ViewPrerequisiteDetails = "ViewPrerequisiteDetails",

  // ViewEnrollmentDetails = "ViewEnrollmentDetails",
  // CreateStudentEnrollments = "CreateStudentEnrollments",
  // AddStudentCourseHistory = "AddStudentCourseHistory",

  // ArchivePrograms = "ArchivePrograms",
  // RestorePrograms = "RestorePrograms",
  // CreatePrograms = "CreatePrograms",
  // ModifyProgramGradingSchemas = "ModifyProgramGradingSchemas",
  // ViewProgramOfferings = "ViewProgramOfferings",
  // CreateProgramOffering = "CreateProgramOffering",
  // ArchiveProgramOffering = "ArchiveProgramOffering",
  // RestoreProgramOffering = "RestoreProgramOffering",
  // ViewProgramOffering = "ViewProgramOffering",
  // ViewProgramAdditonalForm = "ViewProgramAdditonalForm",
  // ModifyProgramAdditionalForm = "ModifyProgramAdditionalForm",
  // AddProgramCourseToProgramOffering = "AddProgramCourseToProgramOffering",
  // RemoveProgramCourseFromProgramOffering = "RemoveProgramCourseFromProgramOffering",
  // AddConcentrationOfferingToProgramOffering = "AddConcentrationOfferingToProgramOffering",
  // ArchiveConcentrationOfferingFromProgramOffering = "ArchiveConcentrationOfferingFromProgramOffering",
  // RestoreConcentrationOfferingToProgramOffering = "RestoreConcentrationOfferingToProgramOffering",

  // ViewConcentrations = "ViewConcentrations",
  // ViewConcentration = "ViewConcentration",
  // ViewConcentrationCourses = "ViewConcentrationCourses",
  // AddConcentrationCourseToConcentration = "AddConcentrationCourseToConcentration",
  // ArchiveConcentrationCourseFromConcentration = "ArchiveConcentrationCourseFromConcentration",
  // RestoreConcentrationCourseToConcentration = "RestoreConcentrationCourseToConcentration",
  // ViewConcentrationElectiveCourses = "ViewConcentrationElectiveCourses",
  // AddConcentrationElectiveCourseToConcentration = "AddConcentrationElectiveCourseToConcentration",
  // ArchiveConcentrationElectiveCourseFromConcentration = "ArchiveConcentrationElectiveCourseFromConcentration",
  // RestoreConcentrationElectiveCourseToConcentration = "RestoreConcentrationElectiveCourseToConcentration",

  // ViewConcentrationOfferings = "ViewConcentrationOfferings",
  // ViewConcentrationOffering = "ViewConcentrationOffering",
  // ViewConcentrationOfferingCourses = "ViewConcentrationOfferingCourses",
  // AddConcentrationCourseToConcentrationOffering = "AddConcentrationCourseToConcentrationOffering",
  // RemoveConcentrationCourseFromConcentrationOffering = "RemoveConcentrationCourseFromConcentrationOffering",
  // ViewConcentrationOfferingElectiveCourses = "ViewConcentrationOfferingElectiveCourses",
  // AddConcentrationElectiveCourseToConcentrationOffering = "AddConcentrationElectiveCourseToConcentrationOffering",
  // RemoveConcentrationElectiveCourseFromConcentrationOffering = "RemoveConcentrationElectiveCourseFromConcentrationOffering",

  // ViewPendingConcentrationChoices = "ViewPendingConcentrationChoices",
  // ModifyPendingConcentrationChoices = "ModifyPendingConcentrationChoices",

  // ViewPendingProgramChoices = "ViewPendingProgramChoices",
  // ModifyPendingProgramChoices = "ModifyPendingProgramChoices",

  // UnlockCourseGrades = "UnlockCourseGrades",
  // ViewCourseGrades = "ViewCourseGrades",
  // ModifyCourseGrades = "ModifyCourseGrades",
  // HideCourseGradeFromTranscript = "HideCourseGradeFromTranscript",
  // ShowCourseGradeInTranscript = "ShowCourseGradeInTranscript",
  UnlockCourseGrades = "UnlockCourseGrades",
  // ViewCourseGrades = "ViewCourseGrades",
  ModifyCourseGrades = "ModifyCourseGrades",
  // HideCourseGradeFromTranscript = "HideCourseGradeFromTranscript",
  // ShowCourseGradeInTranscript = "ShowCourseGradeInTranscript",

  // CreateGradingSchemas = "CreateGradingSchemas",
  // ViewGradingSchemas = "ViewGradingSchemas",
  // ViewProgramGradingSchemas = "ViewProgramGradingSchemas",
  // ViewProgramOfferingGradingSchemas = "ViewProgramOfferingGradingSchemas",
  // ModifyGradingSchemas = "ModifyGradingSchemas",
  // ArchiveGradingSchema = "ArchiveGradingSchema",
  // RestoreGradingSchema = "RestoreGradingSchema",

  // GenerateEnrollmentTranscript = "GenerateEnrollmentTranscript",

  // ViewStudentScholarshipHistory = "ViewStudentScholarshipHistory",
  // ModifyStudentScholarship = "ModifyStudentScholarship",

  // ViewStudentPayments = "ViewStudentPayments",
  // AddStudentPayments = "AddStudentPayments",
  // EditStudentPayments = "EditStudentPayments",
  // DeleteStudentPayments = "DeleteStudentPayments",
  // RestoreStudentPayments = "RestoreStudentPayments",

  // ViewStudentsWithOutstandingPayments = "ViewStudentsWithOutstandingPayments",
  PullGradesFromMoodle = "PullGradesFromMoodle",
}

//UnlockCourseGrades,ModifyCourseGrades,PullGradesFromMoodle