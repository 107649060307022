import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/faqs.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React, { useEffect } from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { MTableAction } from "material-table";

const Faqs = () => {
    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
        onHideChange,
    ] = useFindRequestQueryParams(api.find, 'faqs');

    const { control, errors } = useForm<{
        inputs: [],
    }>();
    useEffect(function () {
        data = data?.map((entity: any) => {
            for (let translation of entity?.translations) {
                entity[`${translation.language}Question`] = translation.question;
                entity[`${translation.language}Answer`] = translation.answer;
            }
            return entity;
        });
    });
    const { enqueueSnackbar } = useSnackbar();

    return (
        <React.Fragment>
            <Head title="Faqs" path="/faqs" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.CreateFAQ]}>
                    <Button
                        color="primary"
                        label="Add Faq"
                        withForm
                        form={{
                            title: "Add Faq",
                            description:
                                "Add a new faq.",
                            apiRequest: api.create,
                            inputs: [
                                {
                                    name: "English Question",
                                    keyName: "enQuestion",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: "English Answer",
                                    keyName: "enAnswer",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: "Arabic Question",
                                    keyName: "arQuestion",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: "Arabic Answer",
                                    keyName: "arAnswer",
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Faq added successfully.";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <CollectionComponent
                title="Faqs"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "English Question",
                        field: "enQuestion",
                    },
                    {
                        title: "English Answer",
                        field: "enAnswer",
                    },
                    {
                        title: "Arabic Question",
                        field: "arQuestion",
                    },
                    {
                        title: "Arabic Answer",
                        field: "arAnswer",
                    },
                ]}
                backendPagination
                backendSearch
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                noDelete
                itemName="faq"
                onRowUpdate={async (input: any, oldData: any) => {
                    await api.update(oldData.id, input);
                    refresh();
                    enqueueSnackbar('Faq edited successfully!', { variant: 'success' });
                }}
                searchText={search}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
                disableKey="archived"
                mainPermission={PermissionsEnum.EditFAQ}
                viewDisabledInSeparateTab
                onHideChange={onHideChange}
                actions={[
                    {
                        icon: "undo",
                        tooltip: "Restore FAQ",
                        onClick: async (e: any, data: any) => {
                            let confirmed = window.confirm(
                                `Are you sure you want to restore this FAQ?`
                            );
                            if (confirmed) {
                                await api.restore(data.id);
                                refresh();
                            }
                        },
                        errorMessage: handleResponseError,
                        requiredPermission: PermissionsEnum.RestoreFAQ,
                        render: (p: any) => {
                            if (p.data.archived === false) return <></>;
                            return (
                                <PermissionsGuard
                                    requiredPermissions={[PermissionsEnum.RestoreFAQ]}
                                >
                                    <MTableAction {...p} />
                                </PermissionsGuard>
                            );
                        },
                    },
                    {
                        icon: () => <DeleteIcon />,
                        tooltip: "Archive FAQ",
                        requiredPermission: PermissionsEnum.ArchiveFAQ,
                        onClick: async (_e: any, data: any) => {
                            if (data.archived) {
                                alert("FAQ already deleted");
                                return;
                            }
                            const confirmed = window.confirm(
                                "Are you sure you want to delete this FAQ?"
                            );
                            if (!confirmed) return;
                            await api.archive(data.id);
                            refresh();
                        },
                        render: (p: any) => {
                            if (p.data.archived === true) return <></>;
                            return (
                                <PermissionsGuard
                                    requiredPermissions={[PermissionsEnum.ArchiveFAQ]}
                                >
                                    <MTableAction {...p} />
                                </PermissionsGuard>
                            );
                        },
                    },
                ]}
            />

        </React.Fragment>
    )
};

export default Faqs;
