import { useSnackbar } from "notistack";
import {useQuery, useQueryClient} from "react-query";
import FindOptions from "../api/interfaces/findOptions.interface";
import extractErrorText from "../util/functions/extractErrorText";

function useFindRequest(options: {
  findAction: Function;
  queryKey?: string;
  page: number;
  pageSize: number;
  searchString?: string;
  viewKey?: string;
  ignoreErrors?: boolean;
}): {data: any, pagesCount: number, loading: boolean, refresh: Function} {
  const { enqueueSnackbar } = useSnackbar();

  let params: FindOptions = {
    page: options.page || 1,
    page_size: options.pageSize || 10,
  };
  if (options.searchString) params.query = options.searchString;
  if (options.viewKey) params.view = options.viewKey;

  let queryName = [options.queryKey, options.page, options.pageSize, options.searchString, options.viewKey];

  let query = useQuery(queryName, () => options.findAction(params), {
    keepPreviousData: true,
    onError: async (err: any) => {
      if (options.ignoreErrors) return;
      enqueueSnackbar(await extractErrorText(err, "Could not load table data"), { variant: "error" });
    }
  });

  const queryClient = useQueryClient();
  const refresh = () => {
    queryClient.invalidateQueries(queryName);
    query.refetch();
  }

  return {
    data: query.data?.data?.data || [],
    pagesCount: query.data?.data.count || 0,
    loading: query.isLoading,
    refresh,
  };
}

export default useFindRequest;
