import { AxiosResponse } from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/concentrationOfferings.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import ComponentGuard from "../components/guards/ComponentGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText from "../util/functions/extractErrorText";
import { toOptions } from "./recipesDatabase/utils";
import concentrationApi from "../api/concentrations.api";

const ConcentrationOffering = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState({} as any);
  const [courseOptions, setCourseOptions] = useState([]);
  const [electiveCourseOptions, setElectiveCourseOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    loadConcentrationOffering();
  }, []);
  const loadConcentrationCourseOptions = async (id: number) => {
    let courses = await concentrationApi.getAllConcentrationCourses(+id);
    if (courses.data) setCourseOptions(toOptions(courses.data, ["course", "translations"]));
  };

  const loadConcentrationElectiveCourseOptions = async (id: number) => {
    let courses = await concentrationApi.getAllConcentrationElectiveCourses(+id);
    if (courses.data) setElectiveCourseOptions(toOptions(courses.data, ["course", "translations"]));
  };

  async function loadConcentrationOffering() {
    try {
      setLoading(true);
      const concentrationOffering = await api.getOffering(+id);
      parseConcentrationOffering(concentrationOffering);
      setLoading(false);
    } catch {
      history.push("/");
    }
  }

  async function parseConcentrationOffering(response: AxiosResponse) {
    const { data } = response;
    if (data?.concentration?.translations)
      for (let translation of data.concentration.translations) {
        data[`${translation.language}Name`] = translation.name;
        data[`${translation.language}Description`] = translation.description;
      }
    if (data.concentrationCourses)
      for (let course of data.concentrationCourses) {
        for (let translation of course.course.translations) {
          course[`${translation.language}Name`] = translation.name;
          course[`${translation.language}Description`] = translation.description;
        }
      }
    if (data.concentrationElectiveCourses)
      for (let course of data.concentrationElectiveCourses) {
        for (let translation of course.course.translations) {
          course[`${translation.language}Name`] = translation.name;
          course[`${translation.language}Description`] = translation.description;
        }
      }
    if (data?.concentration) {
      await loadConcentrationCourseOptions(data.concentration.id);
      await loadConcentrationElectiveCourseOptions(data.concentration.id);
    }
    setData(data);
  }

  const refresh = loadConcentrationOffering;

  return (
    <React.Fragment>
      <Head title="Concentration" path="/concentration" />
      <div className="buttons-section">
        <ComponentGuard requiredPermissions={[PermissionsEnum.AddConcentrationCourseToConcentrationOffering]}>
          <Button
            color="primary"
            label="Add course to concentration offering"
            withForm
            form={{
              title: "Add course to concentration offering",
              description: "Select course to add to concentration offering",
              apiRequest: (data: any) => api.addCourseToOffering(+id, data),
              inputs: [
                {
                  type: "select",
                  name: "Concentration Course",
                  keyName: "concentrationCourseId",
                  required: true,
                  options: courseOptions,
                },
              ],
              successMessage: () => {
                refresh();
                return "Course added successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </ComponentGuard>
        <ComponentGuard requiredPermissions={[PermissionsEnum.AddConcentrationElectiveCourseToConcentrationOffering]}>
          <Button
            color="primary"
            label="Add elective course to concentration offering"
            withForm
            form={{
              title: "Add elective course to concentration offering",
              description: "Select course to add as an elective to concentration offering",
              apiRequest: (data: any) => api.addElectiveCourseToOffering(+id, data),
              inputs: [
                {
                  type: "select",
                  name: "Concentration Elective Course",
                  keyName: "concentrationElectiveCourseId",
                  required: true,
                  options: electiveCourseOptions,
                },
              ],
              successMessage: () => {
                refresh();
                return "Elective Course added successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </ComponentGuard>
      </div>
      <SingleComponent
        title="Concentration"
        fields={[
          {
            name: "Code",
            value: data?.concentration?.code,
          },
          {
            name: "English name",
            value: data.enName,
          },
          {
            name: "English description",
            value: data.enDescription,
          },
          {
            name: "Arabic name",
            value: data.arName,
          },
          {
            name: "Arabic description",
            value: data.arDescription,
          },
          {
            name: "Semester",
            value: data?.semester?.semester,
          },
          {
            name: "Year",
            value: data?.semester?.year,
          },
        ]}
      />
      <br />
      <br />
      <ComponentGuard requiredPermissions={[PermissionsEnum.ViewConcentrationOfferingCourses]}>
        <CollectionComponent
          title="Concentration Courses"
          loading={loading}
          noEdit
          data={data.concentrationCourses || []}
          columns={[
            {
              title: "Code",
              field: "course.code",
            },
            {
              title: "Credit Hours",
              field: "course.creditHours",
            },
            {
              title: "English name",
              field: "enName",
            },
            {
              title: "English description",
              field: "enDescription",
            },
            {
              title: "Arabic name",
              field: "arName",
            },
            {
              title: "Arabic description",
              field: "arDescription",
            },
          ]}
          itemName="course"
          mainPermission={PermissionsEnum.RemoveConcentrationCourseFromConcentrationOffering}
          onRowDelete={async (oldData: any) => {
            if (oldData.archived) {
              alert("Course already deleted");
              return;
            }
            await api.removeCourseFromOffering(+id, oldData.id);
            refresh();
          }}
        />
      </ComponentGuard>
      <br />
      <br />
      <ComponentGuard requiredPermissions={[PermissionsEnum.ViewConcentrationOfferingElectiveCourses]}>
        <CollectionComponent
          title="Concentration Elective Courses"
          loading={loading}
          noEdit
          data={data.concentrationElectiveCourses || []}
          columns={[
            {
              title: "Code",
              field: "course.code",
            },
            {
              title: "Credit Hours",
              field: "course.creditHours",
            },
            {
              title: "English name",
              field: "enName",
            },
            {
              title: "English description",
              field: "enDescription",
            },
            {
              title: "Arabic name",
              field: "arName",
            },
            {
              title: "Arabic description",
              field: "arDescription",
            },
          ]}
          itemName="elective course"
          mainPermission={PermissionsEnum.RemoveConcentrationElectiveCourseFromConcentrationOffering}
          onRowDelete={async (oldData: any) => {
            if (oldData.archived) {
              alert("Elective Course already deleted");
              return;
            }
            await api.removeElectiveCourseFromOffering(+id, oldData.id);
            refresh();
          }}
        />
      </ComponentGuard>
    </React.Fragment>
  );
};
export default ConcentrationOffering;
