import { Add } from "@material-ui/icons";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api/programForms.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import ComponentGuard from "../components/guards/ComponentGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { FileType, FormInputProps, FormInputType } from '../components/forms/Form';
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { MTableAction } from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import programsApi from "../api/programs.api";
import sectionsApi from "../api/formSections.api";
import { useHistory } from "react-router";
import { OpenInNew } from "@material-ui/icons";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { MTableBodyRow } from "material-table";
import { makeStyles } from "@material-ui/core";

export interface IQuestion {
  required: boolean,
  deleted: boolean,
  question: string,
  type: QuestionType,
  new?: boolean,
  multipleLines?: boolean,
  options?: string[],
  fileType?: FileType | FileType[];
  _id?: string;
  defaultValue?: any;
  helperText?: string;
  customControlName?: string;
  answerId?: string;
}

export interface ISection {
  name: string;
  title: string;
  description?: string;
  questions: IQuestion[];
  _id: string;
  new?: boolean;
  id?: string;
}


export type QuestionType = 'textQuestion' | 'multiTextQuestion' | 'numberQuestion' | 'emailQuestion' | 'decimalQuestion' | 'selectQuestion' | 'selectAutoCompleteQuestion' | 'multipleSelectAutoCompleteQuestion' | 'multipleSelectQuestion' | 'fileQuestion' | 'dateTimeQuestion' | 'timeQuestion' | 'checkboxQuestion';

export type AnswerType = 'textAnswer' | 'multiTextAnswer' | 'numberAnswer' | 'emailAnswer' | 'decimalAnswer' | 'selectAnswer' | 'selectAutoCompleteAnswer' | 'multipleSelectAutoCompleteAnswer' | 'multipleSelectAnswer' | 'fileAnswer' | 'dateTimeAnswer' | 'timeAnswer' | 'checkboxAnswer';

export interface ProgramFormData {
  code: string,
  id?: string,
  form: {
    _id?: string,
    title: string,
    description?: string,
    questions: IQuestion[],
    sections?: ISection[],
  }
}
export const typeToQuestionType: (type: FormInputType) => QuestionType = (type) => {
  if (type === 'multiSelect')
    return 'multipleSelectQuestion'
  else if (type === 'multipleSelectAutocomplete')
    return 'multipleSelectAutoCompleteQuestion';
  else if (type === 'selectAutocomplete')
    return 'selectAutoCompleteQuestion';
  return (type + 'Question' as QuestionType);
};

export const typeToAnswerType: (type: FormInputType) => AnswerType = (type) => {
  if (type === 'multiSelect')
    return 'multipleSelectAnswer'
  else if (type === 'multipleSelectAutocomplete')
    return 'multipleSelectAutoCompleteAnswer';
  else if (type === 'selectAutocomplete')
    return 'selectAutoCompleteAnswer';
  return (type + 'Answer' as AnswerType);
};

export const questionToType: (question: IQuestion) => FormInputType = (question) => {
  let type = question.type.substring(0, question.type.length - 8);
  if (type === 'multipleSelect') return 'multiSelect';
  else if (type === 'multipleSelectAutoComplete') return 'multipleSelectAutocomplete';
  else if (type === 'selectAutoComplete') return 'selectAutocomplete';
  return (type as FormInputType);
};

const ProgramForm = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [dataa, setData] = useState<ProgramFormData>({ code: '', id, form: { _id: '', title: '', description: '', questions: [], sections: [] } });
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, errors, reset } = useForm<{}>();
  const [programOptions, setProgramOptions] = useState<{ value: any, label: string }[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [sections, setSections] = useState<{ name: string, id: string, section: ISection }[]>([]);
  const [viewDeleted, setViewDeleted] = useState<boolean>(false);
  const inputTypeOptions = [
    { label: "Text", value: 'text' },
    { label: "Multi text", value: 'multiText' },
    { label: "Number", value: 'number' },
    { label: "Decimal", value: 'decimal' },
    { label: "Email", value: 'email' },
    { label: "Select", value: 'select' },
    { label: "Select with auto complete", value: 'selectAutocomplete' },
    { label: "Multiple select with auto complete", value: 'multipleSelectAutocomplete' },
    { label: "Multi select", value: 'multiSelect' },
    { label: "Checkbox", value: 'checkbox' },
    { label: "Time", value: 'time' },
    { label: "Date time", value: 'dateTime' },
    { label: "File", value: 'file' },
    // { label: "Password", value: 'password' },
    // { label: "Confirm password", value: 'confirmPassword' },
  ];
  const fileTypeOptions = [
    { label: 'image', value: 'image' },
    { label: 'excel', value: 'excel' },
    { label: 'word', value: 'word' },
    { label: 'pdf', value: 'pdf' },
    { label: 'video', value: 'video' },
  ];
  const classes = makeStyles({
    row: {
      opacity: 0.5,
    },
  })();

  const toInput: (questions: IQuestion[] | undefined) => FormInputProps[] = (questions) => {
    let filteredQuestions = questions?.filter(question => question.deleted === false) || [];
    let renderedInputs = filteredQuestions.map(question => {
      let type = questionToType(question);
      let input = {
        required: question.required,
        fileType: question.fileType,
        name: question.question,
        type: type,
        upload: true,
        multiline: question.multipleLines,
        options: question.options?.map(option => ({ value: option, label: option })) || [],
        helperText: question.helperText,
        customControlName: question.customControlName,
        answerId: question.answerId,
      }
      return input;
    });
    return renderedInputs || [];
  }
  useEffect(() => {
    (async () => {
      let { data: { data } } = await programsApi.getAll();
      let programOptions: { value: any, label: string }[] = [];
      data?.forEach((program: any) => {
        // if (program?.id === +id) return;  //ETSC-187
        programOptions.push({ label: program?.translations?.find((translation: any) => translation.language === 'en')?.name || 'NA', value: program?.id })
      });
      setProgramOptions(programOptions);
    })();
    loadForm();
  }, []);

  async function loadForm() {
    try {
      setLoading(true);
      const program = await api.getProgramForm(+id);
      await parseProgramForm(program);
      setLoading(false);
    } catch (e) {
      enqueueSnackbar('Could not load program form', {
        variant: "error",
      });
      // history.push("/");
    }
  }

  async function parseProgramForm(response: AxiosResponse) {
    let sectionData = (await sectionsApi.getFullFormSections()).data;
    setSections(sectionData);
    const { data } = response;
    let c = data.form?.sections?.map((sect: ISection) => {
      let section = sectionData.find((section: any) => {
        return section.section?._id === sect._id
      });
      if (!section) return sect;
      sect.id = section.id;
      sect.name = section.name;
      return sect;
    });
    setData({ ...data, form: { ...data.form, sections: c } });
  }

  // const refresh = loadForm;

  return (
    <React.Fragment>
      <Head title="Program Form" path={`/program/${id}/form`} />
      <div className="buttons-section">
        <Button label='Preview Form' color='primary' withForm form={{
          inputs: toInput(dataa.form.questions),
          title: dataa?.form?.title,
          description: dataa?.form?.description,
          sections: dataa.form.sections?.map(section => { return { title: section.title, description: section.description, inputs: toInput(section.questions) } })
        }}
        />
        <Button color="primary" label='Start new Form' handleClick={() => {
          let confirmed = window.confirm(`WARNING! Pressing ok will replace the existing form!`);
          if (confirmed)
            setData({ code: dataa?.code || '', id: dataa?.id, form: { title: 'New Form', questions: [], sections: [] } });
        }}
        />
        <Button color="primary" label="Duplicate another program's form" withForm form={{
          successMessage: () => 'Form duplicated! Do not forget to save for duplication to take effect',
          title: 'Duplicate program form',
          description: "Select the Program who's form you would like to duplicate",
          inputs: [
            {
              type: 'selectAutocomplete',
              required: true,
              name: 'Program',
              keyName: 'programId',
              options: programOptions
            }
          ],
          apiRequest: async (dataaa: any) => {
            let { data } = await api.getProgramForm(dataaa.programId);
            let questions = data?.form?.questions?.map((question: any) => {
              const { _id, ...rest } = question;
              return rest;
            }).filter((q: any) => !q.deleted) || [];
            setData({ form: { title: data?.form?.title || '', description: data?.form?.description, questions: questions, sections: data?.form?.sections || [] }, code: dataa?.code || '', id: dataa?.id });
          },
          errorMessage: extractErrorText,
        }} />
        <Button color="primary" label='Add section to form'
          withForm
          form={{
            inputs: [{
              required: true,
              type: 'number',
              name: "Position",
              keyName: "position"
            }, {
              type: 'selectAutocomplete',
              required: true,
              name: "Section",
              keyName: "section",
              options: sections.map(section => { return { value: section.id, label: section.name } }) || [],
            }],
            apiRequest: (data: any) => {
              let section = sections.find(section => {
                return section.id === data.section
              });
              if (section) {
                let insertSection = { ...section?.section, name: section?.name || '', id: section?.id || '', title: section?.section.title || '', questions: section?.section.questions || [], _id: section?.section._id || '' }
                setData(prevData => {
                  let sectionData = [...prevData.form.sections || []];
                  if (data.position < 1 || data.position > (sectionData.length + 1)) throw new Error('Insert Position out of range')
                  sectionData.splice(data.position - 1, 0, insertSection);
                  return {
                    ...prevData,
                    form: {
                      ...prevData.form,
                      sections: sectionData,
                    }
                  };
                })
              }
            },
            errorMessage: extractErrorText,
          }}
        />
      </div>
      <SingleComponent
        title="Program Form"
        fields={[
          {
            name: "Program Code",
            value: dataa?.code || "",
          },
          {
            name: "Title",
            value: dataa?.form?.title || "",
            edit: {
              editPermissions: [PermissionsEnum.ModifyProgramAdditionalForm],
              form: {
                title: 'Edit form title',
                inputs: [
                  {
                    type: 'text',
                    name: 'Title',
                    keyName: 'title',
                    required: true,
                    multiline: false,
                    defaultValue: dataa?.form.title || ''
                  }
                ],
                apiRequest: (data: any) => {
                  setData({
                    code: dataa?.code || '',
                    form: {
                      ...dataa?.form,
                      questions: dataa?.form?.questions || [],
                      title: data.title,
                    }
                  })
                },
              }
            },

          },
          {
            name: "Description",
            value: dataa?.form?.description || "",
            displayLineBreaks: true,
            edit: {
              editPermissions: [PermissionsEnum.ModifyProgramAdditionalForm],
              form: {
                title: 'Edit form description',
                inputs: [
                  {
                    type: 'text',
                    name: 'Description',
                    keyName: 'description',
                    required: true,
                    multiline: true,
                    defaultValue: dataa?.form.description || ''
                  }
                ],
                apiRequest: (data: any) => {
                  setData({
                    code: dataa?.code || '',
                    form: {
                      ...dataa?.form,
                      title: dataa?.form?.title || '',
                      questions: dataa?.form?.questions || [],
                      description: data.description,
                    }
                  })
                },
              }
            },
          },
        ]}
      />
      <br />
      <br />
      <CollectionComponent
        title="Form Questions"
        loading={loading}
        mainPermission={PermissionsEnum.ModifyProgramAdditionalForm}
        data={dataa.form.questions?.filter(question => viewDeleted ? question.deleted : !question.deleted) || []}
        noDelete
        onRowUpdate={(newData: any, oldData: any) => {
          let actualOldId = oldData.tableData.id;
          for (let i = 0; i < dataa.form.questions.length && i <= actualOldId; i++) {
            if (dataa.form.questions[i].deleted) actualOldId++;
          }
          delete newData.new;
          setData((prevData: any) => {
            let questions: IQuestion[] = [...prevData?.form?.questions || []];
            questions.splice(actualOldId, 1, newData);
            if (newData.number) {
              let number = newData.number;
              delete newData.number;
              if (number - 1 !== actualOldId)
                if (number < 1 || number > (dataa?.form?.questions?.filter(q => !q.deleted).length || 0))
                  enqueueSnackbar(`Could not reorder form questions, invalid question number: ${number}`, { variant: 'error' });
                else {
                  let questionToBeMoved = questions.splice(actualOldId, 1);
                  let actualNumber = number;
                  for (let i = 0; i < dataa.form.questions.length && i + 1 <= actualNumber; i++) {
                    if (dataa.form.questions[i].deleted) actualNumber++;
                  }
                  questions.splice(actualNumber - 1, 0, questionToBeMoved[0]);
                }
            }
            let title = prevData?.form?.title || "";
            return {
              ...prevData,
              form: {
                ...prevData.form,
                title,
                questions
              }
            }
          });
        }}
        onRowEditCancelled={(rowData: any) => {
          let actualOldId = rowData.tableData.id;
          for (let i = 0; i < dataa.form.questions.length && i <= actualOldId; i++) {
            if (dataa.form.questions[i].deleted) actualOldId++;
          }
          let f = dataa?.form?.questions || [];
          if (f.length > actualOldId && f[actualOldId]?.new) {
            f.splice(actualOldId, 1);
            setData({ ...dataa, code: dataa?.code || "", form: { ...dataa?.form, title: dataa?.form?.title || "", questions: f } });
          }
        }}
        renderRow={(p: any) => {
          return viewDeleted ? (p?.data?.deleted ? <MTableBodyRow className={classes.row} {...p} /> : <React.Fragment />) : (p?.data?.deleted ? <React.Fragment /> : <MTableBodyRow {...p} />)
        }}
        columns={[
          {
            title: 'Number',
            field: 'number',
            render: (rowData) => { return rowData.tableData.id + 1 },
            editComponent: (row) => {
              return InputFactory('number', {
                id: 'program-form-question-number',
                control,
                errors,
                name: ' ',
                defaultValue: row?.rowData?.tableData?.id + 1,
                onChange: (event: any) => {
                  row.rowData.number = event.target.value;
                }
              })
            }
          },
          {
            title: "Type",
            field: "type",
            render: (data: any) => {
              return questionToType(data);
            },
            editComponent: (row) => {
              return (InputFactory('selectAutocomplete', {
                id: 'program-form-question-type',
                control,
                name: 'type',
                errors,
                options: inputTypeOptions,
                onChange: (newValue: any) => {
                  let type = newValue?.value;
                  return row.rowData.type = typeToQuestionType(type);
                },
                defaultValue: (() => {
                  return questionToType(row.rowData);
                })(),
              }))
            },
          },
          {
            title: "Question",
            field: "question"
          },
          {
            title: "Helper Text",
            field: "helperText",
            editComponent: (row: any) => {
              return InputFactory('text', {
                id: 'program-form-question-helper-text',
                multiline: true,
                required: true,
                name: 'Helper Text',
                control,
                errors,
                defaultValue: row.rowData.helperText || '',
                onChange: (event: any) => {
                  row.rowData.helperText = event.target.value;
                }
              })
            },
          },
          // {
          //   title: "Description",
          //   field: "description",
          // },
          {
            title: "Selection Options",
            field: "options",
            render: (row) => {
              let renderedString = "";
              if (row?.options)
                for (let option of row.options) {
                  renderedString += (option + ', ');
                }
              renderedString = renderedString.substring(0, renderedString.length - 2)
              return renderedString;
            },
            editComponent: (row) => {
              let renderedString = "";
              if (row?.value)
                for (let option of row.value) {
                  renderedString += (option + '\n');
                }
              return (InputFactory('text', {
                id: 'program-form-question-selection-options',
                control,
                name: 'options',
                errors,
                multiline: true,
                defaultValue: renderedString,
                onChange: (event: any) => {
                  let asArray = event.target.value.split('\n');
                  row.rowData.options = asArray;//.map((item: string) => { return { value: item, label: item } });
                }
              }))
            }
          },
          {
            title: "File Type",
            field: "fileType",
            render: (row) => {
              return row.fileType ? Array.isArray(row.fileType) ? row.fileType.join(', ') : row.fileType : '-';
            },
            editComponent: (row) => {
              return (InputFactory('multipleSelectAutocomplete', {
                id: 'program-form-question-file-type',
                control,
                name: 'fileType',
                errors,
                options: fileTypeOptions,
                onChange: (newValues: any) => {
                  return row.rowData.fileType = newValues?.map((newValue: any) => newValue.value);
                },
                defaultValue: row.rowData.fileType,
              }))
            },
          },
          {
            title: "Required",
            field: "required",
            render: RenderBoolean(['required']),
            editComponent: (row) => {
              return InputFactory('checkbox', {
                id: 'program-form-question-is-required',
                errors,
                control,
                name: 'required',
                onChange: (event: any) => { return row.rowData.required = event; },
                defaultValue: row.value,
              })
            }
          },
          {
            title: "Multiple Line",
            field: "multipleLines",
            render: RenderBoolean(['multipleLines']),
            editComponent: (row) => {
              return InputFactory('checkbox', {
                id: 'program-form-question-is-multiple-lines',
                errors,
                control,
                name: 'multi line',
                onChange: (event: any) => { return row.rowData.multipleLines = event; },
                defaultValue: row.value,
              })
            }
          },
          {
            title: "Custom Control Name",
            field: "customControlName",
            editComponent: (row) => {
              return InputFactory('text', {
                id: 'program-form-custom-control-name',
                errors,
                control,
                name: 'Custom Control Name',
                onChange: (event: any) => { return row.rowData.customControlName = event.target.value; },
                defaultValue: row.value || '',
                multiline: false,
              })
            }
          },
        ]}
        actions={[
          {
            icon: VisibilityIcon,
            tooltip: 'Toggle view disabled',
            onClick: () => {
              setViewDeleted(!viewDeleted);
            },
            iconCondition: () => viewDeleted,
            secondaryIcon: VisibilityOffIcon,
            isFreeAction: true,
          },
          {
            icon: "undo",
            tooltip: "Restore form question",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to restore this form question?"
              );
              if (!confirmed) return;
              setData((prevData: any) => {
                let questions: IQuestion[] = [...prevData?.form?.questions || []];
                let question = questions.find(question => question._id === data._id);
                if (question) question.deleted = false;
                let title = prevData?.form?.title || "";
                return {
                  ...prevData,
                  form: {
                    ...prevData.form,
                    title,
                    questions
                  }
                }
              });
            },
            render: (p: any) => {
              if (p.data.deleted === false) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.ModifyProgramAdditionalForm]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Archive form question",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to delete this form question?"
              );
              if (!confirmed) return;
              setData((prevData: any) => {
                let questions: IQuestion[] = [...prevData?.form?.questions || []];
                let question = questions.find(question => question._id === data._id);
                if (question) question.deleted = true;
                let title = prevData?.form?.title || "";
                return {
                  ...prevData,
                  form: {
                    ...prevData.form,
                    title,
                    questions
                  }
                }
              });
            },
            render: (p: any) => {
              if (p.data.deleted === true) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.ModifyProgramAdditionalForm]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          }, {
            onClick: (_: any, row: any) => {
              let f = dataa?.form?.questions || [];
              let newRow = { type: 'textQuestion', name: '', question: "", required: true, new: true, deleted: false };
              f.splice(f.length, 0, (newRow as IQuestion));
              setData({ ...dataa, code: dataa?.code || '', form: { ...dataa?.form, title: dataa?.form?.title || "", questions: f } });
              (newRow as any).tableData = { editing: 'update' };
            },
            icon: () => <Add />,
            tooltip: "Add new question",
            isFreeAction: true,
          },
          {
            onClick: (_: any, row: any) => {
              let actualOldId = row.tableData.id;
              for (let i = 0; i < dataa.form.questions.length && i <= actualOldId; i++) {
                if (dataa.form.questions[i].deleted) actualOldId++;
              }
              let f = dataa?.form?.questions || [];
              let newRow = { type: 'textQuestion', name: '', question: "", required: true, new: true, deleted: false };
              f.splice(actualOldId + 1, 0, (newRow as IQuestion));
              setData({ ...dataa, code: dataa?.code || '', form: { ...dataa?.form, title: dataa?.form?.title || "", questions: f } });
              (newRow as any).tableData = { editing: 'update' };
            },
            icon: () => <Add />,
            tooltip: "Add new question below",
          },
        ]}
      />
      <CollectionComponent
        title="Form Sections"
        loading={loading}
        mainPermission={PermissionsEnum.ModifyProgramAdditionalForm}
        data={dataa?.form?.sections || []}
        noDelete
        noEdit
        columns={[
          {
            title: 'Number',
            field: 'number',
            editable: 'never',
            render: (rowData) => { return rowData.tableData.id + 1 },
          },
          {
            title: "Section",
            field: "name",
            editable: 'never',
          },
        ]}
        disableKey={'deleted'}
        actions={[
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete form section",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to delete this form section?"
              );
              if (!confirmed) return;
              let formSections = [...dataa.form?.sections || []];
              formSections?.splice(data.tableData.id, 1);
              setData({ ...dataa, form: { ...dataa.form, sections: formSections } });
            },
          },
          {
            onClick: (_: any, row: any) => history.push(`/form-section/${row.id}`),
            icon: () => <OpenInNew />,
          },
        ]}
      />
      <ComponentGuard requiredPermissions={[PermissionsEnum.ModifyProgramAdditionalForm]}>
        <div><Button label='Save Form' color='primary' handleClick={async () => {
          try {
            let description = dataa?.form?.description ? ((dataa?.form?.description?.length > 0) ? dataa?.form.description : '') : '';
            let title = dataa?.form?.title ? ((dataa?.form?.title?.length > 0) ? dataa?.form.title : '') : '';
            const { data } = await api.editProgramForm(+id, { ...dataa?.form, title, description, sections: dataa.form.sections?.map(section => section._id) });
            // setData(data);
            await loadForm();
            enqueueSnackbar('Form edited successfuly!', {
              variant: "success",
            });
          } catch (error: any) {
            enqueueSnackbar(await extractErrorText(error as Err), {
              variant: 'error',
            });
          }
        }} /></div>
      </ComponentGuard>
    </React.Fragment>
  );
};

export default ProgramForm;
