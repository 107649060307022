import React from "react";
import Form from "../components/forms/Form";
import Head from "../components/Head";
import { acceptInvitation } from "../api/users.api";
import { StringParam, useQueryParams } from "use-query-params";
import { useHistory } from "react-router-dom";
import extractErrorText from "../util/functions/extractErrorText";

const Registration = () => {
  const history = useHistory();

  const [query] = useQueryParams({
    eml: StringParam,
  });

  return (
    <React.Fragment>
      <Head title="Registration" path="/invitation" />
      <Form
        title="Register"
        description="Please fill out the following form to complete your registration"
        inputs={[
          {
            name: "First Name",
            keyName: "firstName",
            type: "text",
            required: true,
          },
          {
            name: "Last Name",
            keyName: "lastName",
            type: "text",
            required: true,
          },
          {
            name: "Password",
            keyName: "password",
            type: "password",
            required: true,
          },
        ]}
        apiRequest={acceptInvitation}
        extraArgs={{
          email: query.eml,
        }}
        successMessage={() =>
          "Registrations completed successfully! Please sign in with your credentials"
        }
        errorMessage={extractErrorText}
        callback={() => {
          history.push("/home");
        }}
      />
    </React.Fragment>
  );
};

export default Registration;
