import moment from "moment";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../api/programs.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";

const ProgramChoice = (props: { language: string }) => {
  const { id }: { id: string } = useParams();

  const { data: programChoice, isLoading } = useQuery(
    ["programChoice", +id],
    async () => (await api.getProgramChoice(+id)).data
  );

  return (
    <>
      <Head path={`/program-choices/${+id}`} title="Program choice" />
      <SingleComponent
        title="Program choice"
        fields={[
          {
            name: "Program name",
            value:
              programChoice?.programOffering.program.translations.find(
                (translation: any) => {
                  return translation.language === props.language;
                }
              )?.name || "-",
          },
          {
            name: "Program code",
            value: programChoice?.programOffering.program.code,
          },
          {
            name: "Start semester",
            value: programChoice
              ? (programChoice.startSemester ? (`${programChoice.startSemester.semester} ${programChoice.startSemester.year}`) : "-")
              : "-",
          },
          {
            name: "Status",
            value: programChoice
              ? programChoice./*choiceReviewed*/choiceApproved !== null
                ? programChoice.choiceApproved
                  ? programChoice.approvalDate
                    ? `Approved in ${moment(programChoice.approvalDate).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}`
                    : "Approved"
                  : "Rejected"
                : "Not yet reviewed"
              : "-",
          },
        ]}
      />
      <br />
      <CollectionComponent
        title="Concentration Choices"
        loading={isLoading}
        data={programChoice?.concentrationChoices}
        columns={[
          {
            title: "Concentration Code",
            field: "concentrationOffering.concentration.code",
          },
          {
            title: "Choice Semester",
            render: (choice) =>
              choice.startSemester ? choice.startSemester.semester + " " + choice.startSemester.year : "-",
          },
          {
            title: "Status",
            render: (choice) => {
              return choice./*choiceReviewed*/choiceApproved !== null
                ? choice.choiceApproved
                  ? "Approved"
                  : "Rejected"
                : "Not yet reviewed";
            },
          },
        ]}
        backendPagination
        viewOnly
      />
    </>
  );
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language
  }
}

export default connect(mapStateToProps)(ProgramChoice);
