import React from "react";
import Head from "../../components/Head";
import CollectionComponent from "../../components/CollectionComponent/CollectionComponent";
import { exportStudentsWithOutstandingPayments, findStudentsWithOutstandingPayment } from "../../api/users.api";
import useFindRequestQueryParams from "../../hooks/useFindRequestQueryParams.hook";
import { handleResponseError } from "../recipesDatabase/utils";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useHistory } from "react-router";
import { PermissionsEnum } from "../../util/enum/permissions.enum";

const StudentsWithOutstandingPayments = () => {
  const [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    ,
    onHideChange,
  ] = useFindRequestQueryParams(findStudentsWithOutstandingPayment, "studentsWithOutstandingPayments");
  const history = useHistory();

  return (
    <React.Fragment>
      <Head
        title="Students with outstanding payments"
        path="/students/outstanding-payments"
      />
      <div className="buttons-section"></div>
      <CollectionComponent
        title="Students with outstanding payments"
        loading={loading}
        data={data}
        export={{
          type: "custom",
          fn: exportStudentsWithOutstandingPayments,
        }}
        columns={[
          {
            title: "First name",
            field: "user_firstName",
          },
          {
            title: "Last name",
            field: "user_lastName",
          },
          {
            title: "Email",
            field: "user_email",
          },
          {
            title: "Expense total",
            render: row => row.e || 0,
          },
          {
            title: "Paid amount",
            render: row => row.p || 0,
          },
          {
            title: "Outstanding Amount",
            render: row => (+row.e - +row.p) || 0,
          },
        ]}
        actions={[
          {
            icon: OpenInNewIcon,
            tooltip: "View Student",
            requiredPermission: PermissionsEnum.ViewStudentDetails,
            onClick: (e: any, data: any) => {
              let id: number = data.user_id;
              return history.push(`/students/${id}`);
            },
            errorMessage: handleResponseError,
          },
        ]}
        backendPagination
        onHideChange={onHideChange}
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        viewOnly
        disableKey="user_isBanned"
      />
    </React.Fragment>
  );
};

export default StudentsWithOutstandingPayments;
