import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Guard from '../guards/Guard';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import '../../styles/Modal.scss';

const useStyles = makeStyles((theme) => ({
  closeButtonOption1: {
    float: 'right'
  },
  closeButtonOption2: {
    position: 'relative',
    left: theme.spacing(50.5),
    top: theme.spacing(1.5),
  }
}));

const Modal: React.FunctionComponent<React.PropsWithChildren<{
  title: string,
  titleColor?: string,
  description?: string,
  descriptionColor?: string,
  open: boolean,
  handleSubmit: Function,
  hideButtons?: boolean,
  language?: string,
  handleClose?: Function,
  explicitCloseButton?: boolean,
}>> = (props) => {
  const closeButtonClass = useStyles().closeButtonOption1;
  const [fullWidth] = React.useState(true);
  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth='sm'
        open={props.open}
        aria-labelledby="responsive-dialog-title"
        dir={(props.language === 'ar') ? 'rtl' : 'ltr'}
        style={{
          direction: (props.language === 'ar') ? 'rtl' : 'ltr'
        }}
        onClose={() => {
          if (props.handleClose) if (!props.explicitCloseButton) props.handleClose()
        }}
      >
        <br />
        <DialogTitle id="responsive-dialog-title">
          <Guard condition={props.explicitCloseButton}>
            <Button className={closeButtonClass} title={'close'} color="secondary" variant='outlined' autoFocus
              onClick={() => { if (props.handleClose) props.handleClose(); return; }}
            >
              <CloseIcon></CloseIcon>
            </Button>
          </Guard>
          <InputLabel className="modal-title" style={{ ...(props.titleColor ? { color: props.titleColor } : {}) }}>{props.title}</InputLabel>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="modal-description" style={{ ...(props.descriptionColor ? { color: props.descriptionColor } : {}) }}>
            {props.description}
          </DialogContentText>
          {props.children}
        </DialogContent>
        <Guard condition={!props.hideButtons || false}>
          <DialogActions>
            {/* <Button autoFocus onClick={handleClose} color="primary">
            Disagree
          </Button> */}
            <Button onClick={(event) => props.handleSubmit(event)} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Guard>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    language: state.SettingsReducer.language
  }
}

export default connect(mapStateToProps)(Modal);
