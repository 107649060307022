import React from "react";
import getNestedObject from "../../../util/functions/getNestedObject";

const RenderArray =
  (
    path: string[],
    callback?:
      | ((value: any, index: number, array: any[]) => unknown)
      | undefined
  ) =>
  (rowData: any) => {
    let array: any[] = getNestedObject(rowData, path) || [];
    if (callback) {
      array = array.map(callback);
    }
    return (
      <React.Fragment>
        {array.join(", ") || <p style={{ color: "red" }}>None</p>}
      </React.Fragment>
    );
  };

export default RenderArray;
