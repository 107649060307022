import React from "react";
import Head from "./Head";
import Form from "./forms/Form";
import { signup } from "../api/users.api";
import extractErrorText from "../util/functions/extractErrorText";

const Signup: React.FunctionComponent = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <React.Fragment>
      <Head title="Signup" path="/home" />
      <Form
        // withModal
        title="Signup"
        description="Please fill in the following inputs"
        modalOpen={open}
        onModalClose={() => setOpen(false)}
        apiRequest={signup}
        successMessage={(body: any) => body}
        errorMessage={extractErrorText}
        inputs={[
          {
            type: "text",
            required: true,
            name: "email",
          },
        ]}
      />
    </React.Fragment>
  );
};

export default Signup;
