import axios from "axios";
import { generateRoutes } from "./base.api";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import { ExportFormat } from "./base.api";
import findOptions from "./interfaces/findOptions.interface";

const generatedRoutes = generateRoutes("/admissionRequirements");
export default {
    archive: generatedRoutes.remove,
    restore: generatedRoutes.restore,
    update: generatedRoutes.update,
    create: generatedRoutes.add,
    find: generatedRoutes.find,
    findOne: generatedRoutes.findOne,
    addVideoToAdmissionRequirement: (id: number, data: number) => axios.post(`/admissionRequirements/${id}/video`, data),
    removeVideoFromAdmissionRequirement: (id: number, videoId: number) => axios.delete(`/admissionRequirements/${id}/video/${videoId}`),
    getAll: () => axios.get('/admissionRequirements/all'),
    export: (options: findOptions, format: ExportFormat) => axios.get(`/admissionRequirements/export?${JSONToQueryParams({ ...options, format })}`, { responseType: "blob" }),
}
