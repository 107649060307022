import axios from "axios";

const getOffering = (id: number) => axios.get(`/concentrations/offerings/${id}`);

const addCourseToOffering = (id: number, data: any) => axios.post(`/concentrations/offering/${id}/course`, data);

const removeCourseFromOffering = (offeringId: number, concentrationCourseId: number) => axios.delete(`/concentrations/offering/${offeringId}/course/${concentrationCourseId}`);

const addElectiveCourseToOffering = (id: number, data: any) => axios.post(`/concentrations/offering/${id}/electiveCourse`, data);

const removeElectiveCourseFromOffering = (offeringId: number, concentrationElectiveCourseId: number) => axios.delete(`/concentrations/offering/${offeringId}/electiveCourse/${concentrationElectiveCourseId}`);


export default {
  getOffering,
  addCourseToOffering,
  removeCourseFromOffering,
  addElectiveCourseToOffering,
  removeElectiveCourseFromOffering,
};
