import React from "react";
import getNestedObject from "../../../util/functions/getNestedObject";

const RenderBoolean = (path: string[]) => (rowData: any) => {
  let bool: any = getNestedObject(rowData, path);
  return <React.Fragment>{bool ? "Yes" : "No"}</React.Fragment>;
};

export default RenderBoolean;

export const RenderNullableBoolean = (path: string[]) => (rowData: any) => {
  let bool: any = getNestedObject(rowData, path);
  return <React.Fragment>{bool ? "Yes" : bool === false ? "No" : "-"}</React.Fragment>;
};