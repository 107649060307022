import { generateRoutes } from "./base.api";
import axios from "axios";

const addCourseToPrerequisite = (id: number, courseId: number) => {
    return axios.post(`/prerequisites/${id}/prerequisiteCourses/${courseId}`);
}

const deleteCourseFromPrerequisite = (id: number, courseId: number) => {
    return axios.delete(`/prerequisites/${id}/prerequisiteCourses/${courseId}`);
}

export const routes = {
    ...generateRoutes("/prerequisites"),
    addCourseToPrerequisite,
    deleteCourseFromPrerequisite,
}

export default routes;