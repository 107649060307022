import React from "react";
import Form, { FormInputProps } from "../components/forms/Form";
import Head from "../components/Head";
import { Answer } from "./ApplicationForm";

const ApplicationForm = (props: { title: string, description?: string, inputs: FormInputProps[], formId: string, section?: number, submit: (save: boolean, answers: Answer[], section?: number) => any }) => {
    return (
        <React.Fragment>
            <Head title="Program Application Form" path={`/applicationForm`} />
            <Form
                inputs={props.inputs}
                title={props.title}
                description={props.description}
                apiRequest={async (data: any) => {
                    return await props.submit(data._save, data._questions, props.section);
                }}
                successMessage={(response: any) => response.message || `${response?._submit ? 'submitted' : 'saved'} successfully`}
                additionalData={{ formId: props.formId }}
                dynamicForm
                saveButton
                customControlName={(index: number, sectionIndex?: number) => {
                    if (sectionIndex || sectionIndex === 0) return `section-${sectionIndex}-question-${index}`;
                    return `${index}`;
                }}
            />

        </React.Fragment >
    );
};

export default (ApplicationForm);