import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/schoolInfo.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";

const SchoolInfo = () => {
    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
    ] = useFindRequestQueryParams(api.find, "schoolInfo");


    return (
        <React.Fragment>
            <Head title="School Info" path="/schoolInfo" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.ModifySchoolInfo]}>
                    <Button
                        color="primary"
                        label="Add School Info"
                        withForm
                        form={{
                            title: "Add School Info",
                            apiRequest: api.add,
                            inputs: [
                                {
                                    type: "text",
                                    name: "Academic Dean Name",
                                    keyName: "academicDean",
                                    required: true,
                                },
                                {
                                    type: "text",
                                    name: "Registrar Name",
                                    keyName: "registrar",
                                    required: true,
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "School Info added successfully!";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <CollectionComponent
                title="School Info"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "Academic Dean",
                        field: "academicDean",
                    },
                    {
                        title: "Registrar",
                        field: "registrar",
                    },
                    {
                        title: "Date Added",
                        field: "createdAt",
                        render: RenderDate(["createdAt"]),
                    },
                ]}
                backendPagination
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                viewOnly
                searchText={search}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
            // disableKey="archived"
            />

        </React.Fragment>
    )
};

export default SchoolInfo;
