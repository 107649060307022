import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/campuses.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React, { useEffect, useState } from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import { useHistory } from "react-router-dom";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const Campuses = () => {
    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
    ] = useFindRequestQueryParams(api.find, "campuses");



    let [campuses, setCampuses] = useState([]);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            let { data: getCampuses } = await api.getAll();
            setCampuses(getCampuses);
        })();
    }, []);

    return (
        <React.Fragment>
            <Head title="Campuses" path="/campuses" />
            <div className="buttons-section">
                <Button
                    color="primary"
                    label="Add Campus"
                    withForm
                    form={{
                        title: "Add Campus",
                        description:
                            "Add a new campus.",
                        apiRequest: api.add,
                        inputs: [
                            {
                                type: "text",
                                name: "Name",
                                keyName: "name",
                                required: true,
                            },
                        ],
                        successMessage: () => {
                            refresh();
                            return "Campus added successfully.";
                        },
                        errorMessage: handleResponseError,
                    }}
                />
            </div>
            <CollectionComponent
                title="Campuses"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                    },
                ]}
                backendPagination
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onRowDelete={async (oldData: any) => {
                    if (oldData.archived) {
                        alert("Campus already deleted");
                        return;
                    }
                    await api.remove(oldData.id);
                    refresh();
                }}
                onRowUpdate={async (input: any, oldData: any) => {
                    await api.update(oldData.id, input);
                    refresh();
                }}
                searchText={search}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
                disableKey="archived"
                actions={[
                    {
                        icon: "undo",
                        tooltip: "Restore campus",
                        onClick: async (_e: any, data: any) => {
                            if (!data.archived) {
                                alert("Campus not deleted");
                                return;
                            }
                            const confirmed = window.confirm(
                                "Are you sure you want to restore this campus?"
                            );
                            if (!confirmed) return;
                            await api.restore(data.id);
                            refresh();
                        },
                    },
                    {
                        icon: OpenInNewIcon,
                        tooltip: "View Campus",
                        onClick: (_e: any, data: any) => history.push(`/campus/${data.id}`),
                    }
                ]}
                mainPermission={PermissionsEnum.ModifyCampuses}
            />

        </React.Fragment>
    )
};

export default Campuses;
