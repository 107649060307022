import React from "react";
import moment from "moment-timezone";
import getNestedObject from "../../../util/functions/getNestedObject";

const RenderDate = (path: string[]) => (rowData: any) => {
  let date: any = getNestedObject(rowData, path);
  if (date) return (
    <React.Fragment>
      {moment.tz(date, "Africa/Cairo").format("MMMM Do YYYY, h:mm:ss a")}
    </React.Fragment>
  );
  return <React.Fragment>
    -
  </React.Fragment>
};

export default RenderDate;

const FormatDateToTimeZone: (date: Date, config: { timeZone?: string, includeTimeOfDay?: boolean }) => string = (date: Date, config: { timeZone?: string, includeTimeOfDay?: boolean } = { includeTimeOfDay: true }) => {
  return date ? moment.tz(date, config.timeZone ?? "Africa/Cairo").format(`MMM Do YYYY${config.includeTimeOfDay ? ', h:mm:ss a' : ''}`) : '-';
}
export {
  FormatDateToTimeZone
}