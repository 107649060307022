import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import '../styles/Footer.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
    },
    marginTop: 'auto',
    width: '100%'
  },
  paper: {
    width: '100%',
    textAlign: 'center'
  }
}));

const Footer = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper + ' footer-box'} elevation={3}>
        {/* <p>Powered by <a href="https://solace-software.com" target='blank'>Solace Software</a></p> */}
        <p style={{ whiteSpace: 'pre-line' }}>Powered by {/*<a href="https://solace-software.com" target='blank'>*/}Solace Software{/*</a>*/}{'\n'}contact us at <a href="https://etsc.org" target='blank'>ETSC</a></p>
      </Paper>
    </div>
  )
}

export default Footer;
