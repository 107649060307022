import React from "react";
import { Controller } from "react-hook-form";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { InputComponentProps } from "./InputComponentProps";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import '../../../styles/Form.scss';

const CheckBoxInput: React.FunctionComponent<InputComponentProps> = (
  options
) => {
  const { t } = useTranslation();

  //  for when the name contains full stops
  let nameHops = options.customControlName ? options.customControlName.split('.') : options.name.split('.');
  let thisError: any = options.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <React.Fragment>
            <Controller
              name={options.customControlName || options.name}
              control={options.control}
              defaultValue={options.defaultValue || false}
              errors={options.errors}
              rules={options.rules}
              render={(props) => (
                <CheckBox
                  id={options.id}
                  name={options.name}
                  onChange={(e: any) => {
                    if (options.onChange) options.onChange(e.target.checked);
                    props.onChange(e.target.checked)
                  }}
                  checked={props.value}
                />
              )}
            />
            <InputLabel className='input-label'>{options.name + (options.required ? "*" : "")}</InputLabel>
          </React.Fragment>
        }
        label=''//{options.name}
      />
      {options.helperText && <>{options.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={options.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${options.id}`}>{options.helperText}</InputLabel></>}
      {thisError?.type === "required" && (
        <span className="error">{t("FORM_INPUT_REQUIRED")}</span>
      )}
    </React.Fragment>
  );
};

export default CheckBoxInput;
