import axios from "axios";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import { generateRoutes } from "./base.api";
import findOptions from "./interfaces/findOptions.interface";

const routes = generateRoutes("/concentrations");

const addCourses = (id: number, data: any) => {
  return axios.post(`/concentrations/${id}/courses`, data);
};

const archiveCourse = (id: number) => {
  return axios.delete(`/concentrationCourses/${id}`);
};

const restoreCourse = (id: number) => {
  return axios.patch(`/concentrationCourses/${id}`);
};

const addElectiveCourses = (id: number, data: any) => {
  return axios.post(`/concentrations/${id}/elective-courses`, data);
};

const archiveElectiveCourse = (id: number) => {
  return axios.delete(`/concentrationElectiveCourses/${id}`);
};

const restoreElectiveCourse = (id: number) => {
  return axios.patch(`/concentrationElectiveCourses/${id}`);
};

const getPendingConcentrationChoices = () => {
  return axios.get("/concentrations/concentration-choices/pending");
};

const approveChoice = (id: number) => {
  return axios.patch(`/concentrations/concentration-choices/approve/${id}`);
};

const rejectChoice = (id: number) => {
  return axios.patch(`/concentrations/concentration-choices/reject/${id}`);
};

const getCoreCourses = (id: number, options: findOptions) => axios.get(`concentrations/${id}/courses?${JSONToQueryParams(options)}`);

const getElectiveCourses = (id: number, options: findOptions) => axios.get(`concentrations/${id}/electiveCourses?${JSONToQueryParams(options)}`);

const toggleConcentrationCourseGraduateLevel = (id: number) => axios.patch(`/concentrations/courses/${id}/level`);

const toggleConcentrationElectiveCourseGraduateLevel = (id: number) => axios.patch(`/concentrations/electiveCourses/${id}/level`);

const getAllConcentrationCourses = (id: number) => axios.get(`/concentrations/${id}/courses/all`);

const getAllConcentrationElectiveCourses = (id: number) => axios.get(`/concentrations/${id}/electiveCourses/all`);

const getAll = () => axios.get("/concentrations/all");

export default {
  findOne: routes.findOne,
  addCourses,
  archiveCourse,
  restoreCourse,
  addElectiveCourses,
  archiveElectiveCourse,
  restoreElectiveCourse,
  getPendingConcentrationChoices,
  approveChoice,
  rejectChoice,
  getCoreCourses,
  getElectiveCourses,
  toggleConcentrationCourseGraduateLevel,
  toggleConcentrationElectiveCourseGraduateLevel,
  getAllConcentrationCourses,
  getAllConcentrationElectiveCourses,
  getAll,
};
