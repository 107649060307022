import axios, { AxiosResponse } from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/concentrations.api";
import findOptions from "../api/interfaces/findOptions.interface";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import ComponentGuard from "../components/guards/ComponentGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText from "../util/functions/extractErrorText";
import { toOptions } from "./recipesDatabase/utils";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const Concentration = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState({} as any);
  const [courses, setCourses] = useState([]);
  const history = useHistory();

  let [
    coreCoursesData,
    coreCoursesPagesCount,
    coreCoursesLoading,
    coreCoursesPage,
    coreCoursesPageSize,
    coreCoursesSearch,
    coreCoursesOnPageChange,
    coreCoursesOnPageSizeChange,
    coreCoursesOnSearchChange,
    coreCoursesRefresh,
    coreCoursesOnHideChange,
  ] = useFindRequestQueryParams((options: findOptions) => api.getCoreCourses(+id, options), 'concentrationCoreCourses', 'coreCourses');
  let [
    electiveCoursesData,
    electiveCoursesPagesCount,
    electiveCoursesLoading,
    electiveCoursesPage,
    electiveCoursesPageSize,
    electiveCoursesSearch,
    electiveCoursesOnPageChange,
    electiveCoursesOnPageSizeChange,
    electiveCoursesOnSearchChange,
    electiveCoursesRefresh,
    electiveCoursesOnHideChange,
  ] = useFindRequestQueryParams((options: findOptions) => api.getElectiveCourses(+id, options), 'concentrationElectiveCourses', 'electiveCourses');

  useEffect(() => {
    (async () => {
      let {
        data: { data: courses },
      } = await axios.get("/courses/all");

      setCourses(toOptions(courses, ["translations"], undefined, ['code', 'department_id']));
      loadConcentration();
    })();
  }, []);
  useEffect(() => {
    if (coreCoursesData)
      for (let course of coreCoursesData) {
        for (let translation of course.course.translations) {
          course[`${translation.language}Name`] = translation.name;
        }
      }

    if (electiveCoursesData)
      for (let course of electiveCoursesData) {
        for (let translation of course.course.translations) {
          course[`${translation.language}Name`] = translation.name;
        }
      }
  }, [electiveCoursesData, coreCoursesData]);

  async function loadConcentration() {
    try {
      const concentration = await api.findOne(+id);
      parseConcentration(concentration);
    } catch {
      history.push("/");
    }
  }

  async function parseConcentration(response: AxiosResponse) {
    const { data } = response;
    for (let translation of data.translations) {
      data[`${translation.language}Name`] = translation.name;
      data[`${translation.language}Description`] = translation.description;
    }
    setData(data);
  }

  return (
    <React.Fragment>
      <Head title="Concentration" path="/concentration" />
      <div className="buttons-section">
        <ComponentGuard requiredPermissions={[PermissionsEnum.AddConcentrationCourseToConcentration]}>
          <Button
            color="primary"
            label="Add course to concentration"
            withForm
            form={{
              title: "Add courses to concentration",
              description: "Select courses to add to concentration",
              apiRequest: (data: any) => api.addCourses(+id, data),
              inputs: [
                {
                  type: "multipleSelectAutocomplete",
                  name: "Courses",
                  keyName: "courseKeys",
                  required: true,
                  options: courses,
                },
                {
                  type: 'checkbox',
                  required: false,
                  name: 'Is Under Graduate',
                  keyName: 'isUnderGraduate',
                },
              ],
              successMessage: () => {
                coreCoursesRefresh();
                return "Course(s) added successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </ComponentGuard>
        <ComponentGuard requiredPermissions={[PermissionsEnum.AddConcentrationElectiveCourseToConcentration]}>
          <Button
            color="primary"
            label="Add elective course to concentration"
            withForm
            form={{
              title: "Add elective courses to concentration",
              description: "Select courses to add to concentration",
              apiRequest: (data: any) => api.addElectiveCourses(+id, data),
              inputs: [
                {
                  type: "multipleSelectAutocomplete",
                  name: "Courses",
                  keyName: "courseKeys",
                  required: true,
                  options: courses,
                },
                {
                  type: 'checkbox',
                  required: false,
                  name: 'Is Under Graduate',
                  keyName: 'isUnderGraduate',
                },
              ],
              successMessage: () => {
                electiveCoursesRefresh();
                return "Elective Course(s) added successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </ComponentGuard>
      </div>
      <SingleComponent
        title="Concentration"
        fields={[
          {
            name: "Code",
            value: data.code,
          },
          {
            name: 'Credit Hours',
            value: data.creditHours,
          },
          {
            name: "English name",
            value: data.enName,
          },
          {
            name: "English description",
            value: data.enDescription,
          },
          {
            name: "Arabic name",
            value: data.arName,
          },
          {
            name: "Arabic description",
            value: data.arDescription,
          },
        ]}
      />
      <br />
      <br />
      <ComponentGuard requiredPermissions={[PermissionsEnum.ViewConcentrationCourses]}>
        <CollectionComponent
          title="Concentration Courses"
          loading={coreCoursesLoading}
          noEdit
          data={coreCoursesData || []}
          columns={[
            {
              title: "Code",
              field: "course.code",
            },
            {
              title: "Credit Hours",
              field: "course.creditHours",
            },
            {
              title: "English name",
              field: "enName",
            },
            {
              title: "Arabic name",
              field: "arName",
            },
            {
              title: 'Graduate',
              field: 'isUnderGraduate',
              render: (row) => row.isUnderGraduate ? 'No' : 'Yes',
            },
          ]}
          itemName="course"
          disableKey="archived"
          onPageChange={coreCoursesOnPageChange}
          page={coreCoursesPage}
          pageSize={coreCoursesPageSize}
          pagesCount={coreCoursesPagesCount}
          searchText={coreCoursesSearch}
          onPageSizeChange={coreCoursesOnPageSizeChange}
          onSearchChange={coreCoursesOnSearchChange}
          onHideChange={coreCoursesOnHideChange}
          viewDisabledInSeparateTab
          backendPagination
          backendSearch
          mainPermission={PermissionsEnum.ArchiveConcentrationCourseFromConcentration}
          onRowDelete={async (oldData: any) => {
            if (oldData.archived) {
              alert("Course already deleted");
              return;
            }
            await api.archiveCourse(oldData.id);
            coreCoursesRefresh();
          }}
          actions={[
            {
              icon: "undo",
              tooltip: "Restore concentration course",
              onClick: async (_e: any, row: any) => {
                if (!row.archived) {
                  alert("Concentration course not deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to restore this concentration course?"
                );
                if (!confirmed) return;
                let respose = await api.restoreCourse(row.id);
                coreCoursesRefresh();
                return respose;
              },
              requiredPermission: PermissionsEnum.RestoreConcentrationCourseToConcentration,
            },
            {
              icon: () => <ManageAccountsIcon />,
              tooltip: "Change course graduate level",
              onClick: async (_e: any, row: any) => {
                let response = await api.toggleConcentrationCourseGraduateLevel(row.id);
                coreCoursesRefresh();
                return response;
              },
              requiredPermission: PermissionsEnum.EditConcentration,
            },
          ]}
        />
      </ComponentGuard>
      <br />
      <br />
      <ComponentGuard requiredPermissions={[PermissionsEnum.ViewConcentrationElectiveCourses]}>
        <CollectionComponent
          title="Concentration Elective Courses"
          loading={electiveCoursesLoading}
          noEdit
          data={electiveCoursesData || []}
          columns={[
            {
              title: "Code",
              field: "course.code",
            },
            {
              title: "Credit Hours",
              field: "course.creditHours",
            },
            {
              title: "English name",
              field: "enName",
            },
            {
              title: "Arabic name",
              field: "arName",
            },
            {
              title: 'Graduate',
              field: 'isUnderGraduate',
              render: (row) => row.isUnderGraduate ? 'No' : 'Yes',
            },
          ]}
          itemName="elective course"
          disableKey="archived"
          onPageChange={electiveCoursesOnPageChange}
          page={electiveCoursesPage}
          pageSize={electiveCoursesPageSize}
          pagesCount={electiveCoursesPagesCount}
          searchText={electiveCoursesSearch}
          onPageSizeChange={electiveCoursesOnPageSizeChange}
          onSearchChange={electiveCoursesOnSearchChange}
          onHideChange={electiveCoursesOnHideChange}
          viewDisabledInSeparateTab
          backendPagination
          backendSearch
          mainPermission={PermissionsEnum.ArchiveConcentrationElectiveCourseFromConcentration}
          onRowDelete={async (oldData: any) => {
            if (oldData.archived) {
              alert("Elective Course already deleted");
              return;
            }
            await api.archiveElectiveCourse(oldData.id);
            electiveCoursesRefresh();
          }}
          actions={[
            {
              icon: "undo",
              tooltip: "Restore concentration elective course",
              onClick: async (_e: any, row: any) => {
                if (!row.archived) {
                  alert("Concentration elective course not deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to restore this concentration elective course?"
                );
                if (!confirmed) return;
                let respose = await api.restoreElectiveCourse(row.id);
                electiveCoursesRefresh();
                return respose;
              },
              requiredPermission: PermissionsEnum.RestoreConcentrationElectiveCourseToConcentration,
            },
            {
              icon: () => <ManageAccountsIcon />,
              tooltip: "Change course graduate level",
              onClick: async (_e: any, row: any) => {
                let response = await api.toggleConcentrationElectiveCourseGraduateLevel(row.id);
                electiveCoursesRefresh();
                return response;
              },
              requiredPermission: PermissionsEnum.EditConcentration,
            },
          ]}
        />
      </ComponentGuard>
    </React.Fragment>
  );
};
export default Concentration;
