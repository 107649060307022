import React from 'react';
import { InputComponentProps } from './InputComponentProps';
import { useController } from 'react-hook-form';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import '../../../styles/Form.scss';

const ConfirmPasswordInput: React.FunctionComponent<InputComponentProps> = (props) => {

  const { field, meta } = useController({
    ...props,
    rules: {
      ...props.rules,
      validate: (value: string) => value?.length >= 8 && value === confirmPassword
    },
    name: props.customControlName || props.name,
  });

  const { t } = useTranslation();

  const [displayPassword, setDisplayPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className='input-label'>{props.name + (props.required ? "*" : "")}</InputLabel>
      <TextField
        type={displayPassword ? 'text' : 'password'}
        id={props.id}
        error={meta.invalid}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        inputRef={field.ref}
        InputLabelProps={{
          shrink: true,
        }} />
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      <InputLabel className='input-label'>confirm {props.name + (props.required ? "*" : "")}</InputLabel>
      <TextField
        type={displayPassword ? 'text' : 'password'}
        id={props.id}
        error={meta.invalid}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setConfirmPassword(event.target.value)}
        onBlur={field.onBlur}
        value={confirmPassword}
        inputRef={field.ref}
        InputLabelProps={{
          shrink: true,
        }} />
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      <FormControlLabel
        className='display-password'
        control={
          <Checkbox
            checked={displayPassword}
            onChange={(event, checked) => setDisplayPassword(checked)}
            name="checkedB"
            color="primary"
          />
        }
        label={t('DISPLAY_PASSWORD')}
      />
      {thisError?.type === 'required' && <span className='error'>{t('FORM_INPUT_REQUIRED')}</span>}
      {thisError?.type === 'validate'
        && field.value.length < 8
        && <span className='error'>{t('PASSWORD_ERROR')}</span>}
      {thisError?.type === 'validate'
        && confirmPassword !== field.value
        && <span className='error'>{t('PASSWORD_NOT_MATCHING')}</span>}
    </React.Fragment>
  )
}

export default ConfirmPasswordInput;
