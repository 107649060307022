import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/faqs.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React, { useEffect, useState } from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import { useSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { useTranslation } from "react-i18next";

const FaqGroup = () => {
    const { id } = useParams<{ id: string }>();

    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
        ,
    ] = useFindRequestQueryParams(api.findGroup(+id), "faqGroup");

    const [group, setGroup] = useState<any>();
    const { t } = useTranslation();

    const [faqs, setFaqs] = useState<any[]>([]);
    const getFaqs = async () => {
        let { data: { data: faqData } } = await api.getAll();
        setFaqs(faqData || []);
    }

    useEffect(function () {
        data = data?.map((entity: any) => {
            for (let translation of entity.translations) {
                entity[`${translation.language}Question`] = translation.question;
                entity[`${translation.language}Answer`] = translation.answer;
            }
            return entity;
        });
    });
    useEffect(() => {
        getFaqs();
    }, []);

    useEffect(() => {
        (async () => {
            let { data: base } = await api.findGroupBase(+id);
            setGroup(base);
        })();
    }, [id]);

    const { enqueueSnackbar } = useSnackbar();

    return (
        <React.Fragment>
            <Head title="Faqs" path="/faqs" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.CreateFAQ]}>
                    <Button
                        color="primary"
                        label="Add Faq to group"
                        withForm
                        form={{
                            title: "Add Faq to group",
                            description:
                                "Add a faq to this group.",
                            apiRequest: (data: any) => api.addFaqToGroup(+id, data),
                            inputs: [
                                {
                                    name: "FAQs",
                                    keyName: "faqIds",
                                    type: 'multipleSelectAutocomplete',
                                    required: true,
                                    options: faqs.map ? faqs?.map(faq => { return { value: faq.id, label: faq?.translations?.find((translation: any) => translation?.language === 'en')?.question || '-' }; }) : [],
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Faq added to group successfully.";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <SingleComponent
                title={t("FAQ_GROUP")}
                fields={[{
                    name: 'Name',
                    value: group?.name,
                }]}
            />
            <br /><br />
            <CollectionComponent
                title="Faqs"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "English Question",
                        field: "enQuestion",
                    },
                    {
                        title: "English Answer",
                        field: "enAnswer",
                    },
                    {
                        title: "Arabic Question",
                        field: "arQuestion",
                    },
                    {
                        title: "Arabic Answer",
                        field: "arAnswer",
                    },
                ]}
                backendPagination
                backendSearch
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                viewOnly
                itemName="faq"
                searchText={search}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
                disableKey="archived"
                mainPermission={PermissionsEnum.EditFAQ}
                actions={[
                    {
                        icon: () => <DeleteIcon />,
                        tooltip: "Remove FAQ from group",
                        requiredPermission: PermissionsEnum.RemoveFAQFromFAQGroup,
                        onClick: async (_e: any, data: any) => {
                            const confirmed = window.confirm(
                                "Are you sure you want to remove this FAQ from FAQ Group?"
                            );
                            if (!confirmed) return;
                            await api.removeFaqFromGroup(+id, data.id);
                            enqueueSnackbar('FAQ removed from group successfully!', { variant: 'success' });
                            refresh();
                        },
                    },
                ]}
            />

        </React.Fragment>
    )
};

export default FaqGroup;
