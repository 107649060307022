/**
 * Create to test AWS S3 signed url uploads
 */
import React from 'react';
import axios from 'axios';

const TestUpload = () => {

  const [file, setFile] = React.useState<any>(new Blob());

  const upload = async () => {
    const url = await getSignedUrl();

    //delete axios.defaults.headers.Authorization

    var instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers = {}
    instance.put(url, file, {
      headers: {
        'Content-Type': file.type
      }
    });
  }

  const getSignedUrl = async () => {

    console.log("file", file);
    const { data } = await axios.get(
      `/uploads/signedUploadUrl?fileName=${file.name}&mimeType=${file.type}`
      )
    return data.signedRequest;
  }

  return (
    <React.Fragment>
      <input onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const file = (event) ? 
        (event.currentTarget) ? 
        (event.currentTarget.files) ? 
        (event.currentTarget.files[0]) ? 
        event.currentTarget.files[0] : '' : '' : '': '';
        setFile(file);
      }} type='file' />
      <button onClick={upload}>upload</button>

    </React.Fragment>
  )
}

export default TestUpload;
