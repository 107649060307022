import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import Button from "../components/Button";
import api from "../api/campuses.api";
import apiBuilding from "../api/buildings.api";
import { handleResponseError } from "./recipesDatabase/utils";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { useHistory } from "react-router-dom";


const SingleCampus = () => {
    let [campus, setCampus] = useState<{
        archived: boolean,
        name: string,
        id: number,
        buildings: any[],
        campus_id: number,
    }>();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);

    async function loadCampus() {
        try {
            setLoading(true);
            let { data: campus } = await api.findOne(+id);
            setCampus(campus);
            setLoading(false);
        } catch {
            history.push("/");
        }
    }
    const refresh = loadCampus;

    useEffect(() => {
        loadCampus();
    }, []);


    if (!campus) {
        return (
            <React.Fragment />
        )
    }


    return (
        <React.Fragment>
            <Head title='Campus' path={`/campus/${id}`} />

            <SingleComponent
                title="Campus info"
                fields={[
                    {
                        name: "Campus Name",
                        value: campus.name,
                    }
                ]}
            />
            <div className="buttons-section">
                <Button
                    color="primary"
                    label="Add Building"
                    withForm
                    form={{
                        title: "Add Building",
                        description:
                            "Add a new building.",
                        apiRequest: async (data: any) => { await apiBuilding.add({ name: data.name, campus_id: id }) },

                        inputs: [
                            {
                                type: "text",
                                name: "Name",
                                keyName: "name",
                                required: true,
                            },
                        ],
                        successMessage: () => {
                            refresh();
                            return "Building added successfully.";
                        },
                        errorMessage: handleResponseError,
                    }}
                />
            </div>
            <CollectionComponent
                title="Campus Buildings"
                loading={loading}
                data={campus.buildings}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                    },
                ]}
                backendPagination
                onRowDelete={async (oldData: any) => {
                    if (oldData.archived) {
                        alert("Building already deleted");
                        return;
                    }
                    await apiBuilding.remove(oldData.id);
                    refresh();
                }}
                onRowUpdate={async (input: any, oldData: any) => {
                    await apiBuilding.update(oldData.id, input);
                    refresh();
                }}
                // searchText={searchBuilding}
                // onSearchChange={(text: string) => {
                //     onSearchChangeBuilding(text);
                //
                // }}
                disableKey="archived"
                actions={[
                    {
                        icon: "undo",
                        tooltip: "Restore building",
                        onClick: async (_e: any, data: any) => {
                            if (!data.archived) {
                                alert("Building not deleted");
                                return;
                            }
                            const confirmed = window.confirm(
                                "Are you sure you want to restore this building?"
                            );
                            if (!confirmed) return;
                            await apiBuilding.restore(data.id);
                            refresh();
                        },
                    },
                    {
                        icon: OpenInNewIcon,
                        tooltip: "View Building",
                        onClick: (_e: any, data: any) => history.push(`/building/${data.id}`),
                    }
                ]}
                mainPermission={PermissionsEnum.ModifyCampuses}
            />

        </React.Fragment>
    );
};

export default SingleCampus;