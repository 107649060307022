import React, { useEffect } from "react";
import Guard from "./Guard";
import Login from "../Login";
import { connect } from "react-redux";
import { PermissionsEnum } from "../../util/enum/permissions.enum";

const CheckPermissions: (
  userPermissions: PermissionsEnum[],
  requiredPermissions: PermissionsEnum[]
) => boolean = (
  userPermissions: PermissionsEnum[],
  requiredPermissions: PermissionsEnum[]
) => {
    if (requiredPermissions?.length === 0) return true;
    let found: boolean = false;
    userPermissions?.forEach((userPermission) => {
      if (requiredPermissions.includes(userPermission)) {
        found = true;
      }
    });
    return found;
  };

const PermissionsGuard: React.FunctionComponent<
  React.PropsWithChildren<{
    isLoggedIn: boolean;
    permissions: PermissionsEnum[];
    fallback?: React.ReactNode;
    requiredPermissions: PermissionsEnum[];
    fallbackFunc?: () => any;
  }>
> = (props) => {
  const authorized = CheckPermissions(
    props.permissions,
    props.requiredPermissions
  );

  useEffect(
    () => {
      if (!authorized) props?.fallbackFunc?.call(this);
    }, // eslint-disable-next-line
    []
  );

  if (!props.isLoggedIn) {
    return <Login />;
  }

  return (
    <Guard condition={authorized} fallback={props.fallback}>
      {props.children}
    </Guard>
  );
};
const mapStateToProps = (store: any) => {
  return {
    isLoggedIn: store.UserReducer.isLoggedIn,
    permissions: store.UserReducer.permissions,
  };
};

export default connect(mapStateToProps)(PermissionsGuard);
