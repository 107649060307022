import { useQuery } from "react-query";
import Head from "../components/Head";
import Tab from "../components/Tab";
import { getFacultyActiveCourses, getFacultyPastCourses, getFacultyStudents } from "../api/users.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const Faculty: React.FunctionComponent = () => {
	return <>
		<Head path="/faculty" title="Faculty" />
		<Tab
			tabs={[{
				name: "Active Courses",
				content: ActiveCourses
			}, {
				name: "Past Courses",
				content: PastCourses
			}, {
				name: "My Students",
				content: MyStudents
			}]}
			urls={["faculty/active-courses", "faculty/past-courses", "faculty/my-students"]}
		/>
	</>
}

function ActiveCourses() {
	const history = useHistory();
	const { data, isFetching } = useQuery({
		queryKey: ["faculty", "my-active-courses"],
		async queryFn() {
			return (await getFacultyActiveCourses()).data;
		}
	});

	return <CollectionComponent
		data={data}
		loading={isFetching}
		title="Active Courses"
		viewOnly
		columns={[
			{
				title: "Cohort",
				field: "semester"
			},
			{
				title: "Course Name",
				field: "courseName"
			},
			{
				title: "Number of students",
				field: "totalStudentsCount"
			},
			{
				title: "Number of graded students",
				field: "gradedStudentsCount"
			},
			{
				title: "Number of ungraded students",
				field: "ungradedStudentsCount"
			},
			{
				title: "Number of dropped students",
				field: "droppedStudentsCount"
			}
		]}
		actions={[
			{
				icon: () => <OpenInNew />,
				tooltip: "Open course offering",
				onClick(e, data) {
					history.push(`/course-offering/${data.courseOfferingId}`);
				}
			}
		]}
	/>
}

function PastCourses() {
	const history = useHistory();
	const { data, isFetching } = useQuery({
		queryKey: ["faculty", "my-past-courses"],
		async queryFn() {
			return (await getFacultyPastCourses()).data;
		}
	});

	return <CollectionComponent
		data={data}
		loading={isFetching}
		title="Past Courses"
		viewOnly
		columns={[
			{
				title: "Cohort",
				field: "semester"
			},
			{
				title: "Course Name",
				field: "courseName"
			},
			{
				title: "Number of students",
				field: "totalStudentsCount"
			},
			{
				title: "Number of graded students",
				field: "gradedStudentsCount"
			},
			{
				title: "Number of dropped students",
				field: "droppedStudentsCount"
			}
		]}
		actions={[
			{
				icon: () => <OpenInNew />,
				tooltip: "Open course offering",
				onClick(e, data) {
					history.push(`/course-offering/${data.courseOfferingId}`);
				}
			}
		]}
	/>
}

function MyStudents() {
	const history = useHistory();
	const { data, isFetching } = useQuery({
		queryKey: ["faculty", "my-students"],
		async queryFn() {
			return (await getFacultyStudents()).data;
		}
	});

	return <CollectionComponent
		data={data}
		loading={isFetching}
		title="My Students"
		viewOnly
		columns={[
			{
				title: "Student Name",
				field: "studentName"
			},
			{
				title: "Course Name",
				field: "courseName"
			},
			{
				title: "Grade",
				field: "grade"
			}
		]}
		actions={[
			{
				icon: () => <OpenInNew />,
				tooltip: "Open course offering",
				onClick(e, data) {
					history.push(`/course-offering/${data.courseOfferingId}`);
				}
			}
		]}
	/>
}


export default Faculty;