import axios from "axios";

export const find = async () => {
  return await axios.get("/grading-schemas/");
};

export const findOne = async (id: number) => {
  return await axios.get(`/grading-schemas/${id}`);
};

export const create = async (data: any) => {
  return await axios.post("/grading-schemas/", data);
};

export const edit = async (id: number, data: any) => {
  return await axios.patch(`/grading-schemas/${id}`, data);
};

export const archive = async (id: number) => {
  return await axios.delete(`/grading-schemas/${id}`);
};

export const unarchive = async (id: number) => {
  return await axios.patch(`/grading-schemas/${id}/unarchive`);
};

export const addGradePoint = async (data: any) => {
  return await axios.post("/grading-schemas/grade-point", data);
};

export const editGradePoint = async (id: number, data: any) => {
  return await axios.patch(`/grading-schemas/grade-point/${id}`, data);
};

export const archiveGradePoint = async (id: number) => {
  return await axios.delete(`/grading-schemas/grade-point/${id}`);
};

export const unarchiveGradePoint = async (id: number) => {
  return await axios.patch(`/grading-schemas/grade-point/${id}/unarchive`);
};

export const getAllGradingSchemas = async () => axios.get('/grading-schemas/all');
