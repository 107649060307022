import React from 'react';
import Guard from './Guard';
import { connect } from 'react-redux';

const AuthGuard: React.FunctionComponent<React.PropsWithChildren<{
  isLoggedIn: boolean,
  fallback?: React.ReactNode
}>> = props => {
  return (
    <Guard condition={props.isLoggedIn} fallback={props.fallback}>
      {props.children}
    </Guard>
  )
}

const mapStateToProps = (store: any) => {
  return {
    isLoggedIn: store.UserReducer.isLoggedIn
  }
}

export default connect(mapStateToProps)(AuthGuard);
