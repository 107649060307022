import * as types from '../types/user.types';

const initialState = {
  isLoggedIn: false,
  permissions: [],
  user: null,
  token: '',
  refreshToken: ''
};

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        permissions: action.payload.permissions,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        permissions: [],
        token: '',
        refreshToken: ''
      }
    default:
      return state;
  }
}
