import React from 'react';
import { useController } from 'react-hook-form';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputComponentProps } from './InputComponentProps';
import { useTranslation } from 'react-i18next';
import '../../../styles/Form.scss';

const SelectAutocompleteInput: React.FunctionComponent<InputComponentProps> = (props) => {
  const { field, meta } = useController({
    ...props,
    defaultValue: props?.options?.filter((option) => option?.value == props.defaultValue)[0] || null,
    rules: {
      ...props.rules
    },
    name: props.customControlName || props.name,
  });

  const { t } = useTranslation();

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className='input-label'>{props.name + (props.required ? "*" : "")}</InputLabel>
      <Autocomplete
        id={props.id}
        value={field.value}
        // value={field.value || props?.options?.filter((option) => option?.value == props.defaultValue)[0]}
        onChange={(event, value) => {
          if (props.onChange) props.onChange(value);
          return field.onChange(value)
        }}
        // error={meta.invalid}
        onBlur={field.onBlur}
        // inputRef={field.ref}
        groupBy={(option) => option?.group}
        ref={field.ref}
        // getOptionLabel={(option) => {
        //   return option?.label
        // }}
        options={props?.options || []}
        // isOptionEqualToValue={(option, value) => option.value == value}
        renderInput={(params) => <TextField {...params} variant="standard" />}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
      />
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      {thisError?.type === 'required'
        && <span className='error'>{t('FORM_INPUT_REQUIRED')}</span>}
      {thisError?.type === 'validate'
        && <span className='error'>{t("INVALID")} </span>}
    </React.Fragment>
  )
}

export default SelectAutocompleteInput;
