import React from 'react';
import { useController } from 'react-hook-form';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { InputComponentProps } from './InputComponentProps';
import { useTranslation } from 'react-i18next';
import '../../../styles/Form.scss';

const SelectInput: React.FunctionComponent<InputComponentProps> = (props) => {
  const { field, meta } = useController({
    ...props,
    rules: {
      ...props.rules,
    },
    name: props.customControlName || props.name,
  });

  const { t } = useTranslation();

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className='input-label'>{props.name + (props.required ? "*" : "")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={props.id}
        value={field.value}
        onChange={(value) => {
          if (props.onChange) props.onChange(value);
          return field.onChange(value);
        }}
        error={meta.invalid}
        onBlur={field.onBlur}
        inputRef={field.ref}
      >
        {props?.options?.map((option, i) => (
          <MenuItem value={option.value} key={i}>{option.label}</MenuItem>
        ))}
      </Select>
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      {thisError?.type === 'required' && <span className='error'>{t('FORM_INPUT_REQUIRED')}</span>}
      {thisError?.type === 'validate' && <span className='error'>{t("INVALID")}</span>}
    </React.Fragment>
  )
}

export default SelectInput;
