import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { find, create, archive, restore, edit } from "../api/referralForms.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import ComponentGuard from "../components/guards/ComponentGuard";
import Head from "../components/Head";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { PermissionsEnum } from "../util/enum/permissions.enum";

const ReferralForms: React.FunctionComponent<{
  children?: never;
}> = () => {
  const history = useHistory();
  const findRequest = useFindRequestQueryParams(find, "referralForms");
  const [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = findRequest;

  return (
    <>
      <Head title="Referral forms" path="/referral-form" />
      <div className="buttons-section">
        <ComponentGuard requiredPermissions={[PermissionsEnum.CreateReferralForm]}>
          <Button
            color="primary"
            label="New referral form"
            withForm
            form={{
              inputs: [
                {
                  name: "Name",
                  required: true,
                  type: "text",
                  customControlName: "name"
                },
                {
                  name: "Form title",
                  required: true,
                  type: "text",
                  customControlName: "formTitle"
                },
                {
                  name: "Form description",
                  required: true,
                  type: "text",
                  customControlName: "formDescription"
                }
              ],
              apiRequest: async (data: any) => {
                await create(data);
                refresh();
              },
              successMessage: () => "Referral form created successfully"
            }}
          />
        </ComponentGuard>
      </div>
      <CollectionComponent
        data={data}
        title="Referral forms"
        columns={[
          {
            title: "Form ID",
            field: "formId",
            editable: "never",
          },
          {
            title: "Name",
            field: "name",
          },
        ]}
        loading={loading}
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        searchText={search}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSearchChange={onSearchChange}
        onHideChange={onHideChange}
        viewDisabledInSeparateTab
        disableKey="archived"
        noDelete
        mainPermission={PermissionsEnum.EditReferralForm}
        onRowUpdate={async (newData: any, oldData: any) => {
          await edit(+oldData.id, newData);
          await refresh();
        }}
        actions={[
          {
            icon: () => <OpenInNew />,
            tooltip: "Open form",
            onClick(e, data) {
              history.push(`/referral-form/${data.id}`);
            },
          },
          {
            icon: 'delete',
            tooltip: "Archive form",
            async onClick(e, data) {
              const confirmed = window.confirm("Are you sure you want to archive this form?");
              if (!confirmed) return;
              await archive(+data.id);
              await refresh();
            },
            requiredPermission: PermissionsEnum.ArchiveReferralForm,
            condition: (data: { archived: boolean }) => !data.archived
          },
          {
            icon: 'undo',
            tooltip: 'Restore form',
            async onClick(e, data) {
              const confirmed = window.confirm('Are you sure you want to restore this form?');
              if (!confirmed) return;
              await restore(+data.id);
              await refresh();
            },
            condition: (data: { archived: boolean }) => data.archived,
            requiredPermission: PermissionsEnum.RestoreReferralForm
          }
        ]}
      />
    </>
  );
};

export default ReferralForms;
