import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

interface Option {
  value: string;
  label: string;
}

interface MyProps {
  id: string;
  defaultValue: any[];
  options: Option[];
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

let rendered = 0;

const MultiSelectInput: React.FunctionComponent<MyProps> = (props) => {
  let [value, setValue] = useState(props.defaultValue);

  const onChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    setValue(e.target.value);
    props.onChange(e);
  };

  return (
    <React.Fragment>
      <Select
        labelId="demo-simple-select-label"
        id={props.id}
        value={value || (rendered++ === 0 && props.defaultValue) || []}
        onChange={onChange}
        multiple
      >
        {props?.options?.map((option, i) => (
          <MenuItem value={option.value} key={i}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
};

export { MultiSelectInput };
