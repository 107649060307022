import React from 'react';
import Form from '../components/forms/Form';
import Head from '../components/Head';
import { resetPassword } from '../api/users.api';
import { StringParam, useQueryParams } from 'use-query-params';
import { useHistory } from "react-router-dom";

const ResetPassword = () => {

  const history = useHistory();

  const [query] = useQueryParams({
    eml: StringParam
  });

  return (
    <React.Fragment>
      <Head title='Forget Password' path='/resetPassword' />
      <Form
        title='Reset Password'
        description='Please enter and confirm your new password'
        inputs={[
          {
            name: 'password',
            type: 'confirmPassword',
            required: true
          }
        ]}
        apiRequest={resetPassword}
        extraArgs={{
          email: query.eml
        }}
        successMessage={() => 'Password updated successfully! Please sign in with your updated credentials'}
        callback={() => {
          history.push("/home");
        }}
      />
    </React.Fragment>
  )
}

export default ResetPassword;
