import React, { BaseSyntheticEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { InputFactory } from "./inputs/InputFactory";
import "../../styles/Form.scss";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "../modals/Modal";
import axios from "axios";
import extractErrorText, { Err } from "../../util/functions/extractErrorText";
import { typeToAnswerType } from "../../pages/ProgramForm";
import Guard from "../guards/Guard";
import InputLabel from "@material-ui/core/InputLabel";
import SectionTitle from "./labels/SectionTitle";
import SectionDescription from "./labels/SectionDescription";
import { PermissionsEnum } from "../../util/enum/permissions.enum";
import PermissionsGuard from "../guards/PermissionsGuard";
import { connect } from "react-redux";
import { CheckPermissions } from "../guards/ComponentGuard";

export type FormInputType =
  | "text"
  | "number"
  | "password"
  | "confirmPassword"
  | "email"
  | "select"
  | "selectAutocomplete"
  | "multipleSelectAutocomplete"
  | "multiSelect"
  | "file"
  | "dateTime"
  | "checkbox"
  | "multiText"
  | "time"
  | "decimal";

export type Section = {
  title: string;
  description?: string;
  inputs: FormInputProps[];
}
export interface FormProps {
  inputs: FormInputProps[];
  apiRequest?: Function;
  successMessage?: Function;
  errorMessage?: Function;
  callback?: Function;
  withModal?: boolean;
  title?: string;
  description?: string;
  modalOpen?: boolean;
  onModalClose?: Function;
  extraArgs?: Object;
  confirmation?: {
    apiRequest: Function;
    confirmationText: string;
    notConfirmedMessage: string;
  };
  explicitCloseButton?: boolean;
  additionalData?: any;
  dynamicForm?: boolean;
  saveButton?: boolean;
  sections?: Section[];
  customControlName?: (index: number, sectionIndex?: number) => string;
}

export type FileType = "image" | "excel" | 'word' | 'pdf' | 'video' | "directory";

//  for when the name contains full stops
const getEmbededValueDueToFullStopsInName: (object: any, name: string) => { currentName: string, body: any } = (object: any, name: string) => {
  let nameHops = name.split('.');
  for (let hop of nameHops) {
    object = object ? hop ? object[hop] : object : undefined;
    if (typeof object !== 'object') break;
  }
  let actualHops = nameHops.filter(hop => !!hop);
  let currentName = actualHops?.length > 0 ? actualHops[0] : '';
  return { currentName, body: object };
}

export interface FormInputProps {
  type: FormInputType;
  name: string;
  keyName?: string;
  required: boolean;
  options?: {
    value: string | number;
    label: string;
  }[];
  permissions?: PermissionsEnum[];
  upload?: boolean;
  defaultValue?: string | number | boolean;
  fileType?: FileType | FileType[];
  multiFile?: boolean;
  multiline?: boolean;
  questionId?: string;
  helperText?: string;
  helperTextCustomStyle?: { style?: { fontSize?: string }, compact?: boolean };
  customControlName?: string,
  answerId?: string;
  resetReset?: () => void;
  setReset?: () => void;
  onChange?: (event: BaseSyntheticEvent | any) => void;
}

const defaultFormProps: FormProps = {
  inputs: [],
};

const renameKey = (object: any, old_key: string, new_key: string) => {
  if (old_key !== new_key) {
    Object.defineProperty(
      object,
      new_key,
      Object.getOwnPropertyDescriptor(object, old_key) as PropertyDescriptor
    );
    delete object[old_key];
  }
};

const getInputName: (input: FormInputProps, customControlNameGenerator?: (index: number, sectionIndex?: number) => string, index?: number, sectionIndex?: number) => string = (input: FormInputProps, customControlNameGenerator, index?: number, sectionIndex?: number) => {
  if (input.customControlName) return input.customControlName;
  if (customControlNameGenerator)
    if (index || index === 0) return customControlNameGenerator(index, sectionIndex);
  return input.keyName ? input.keyName : input.name;
}

function mapStateToProps(store: any) {
  return {
    userPermissions: store.UserReducer.permissions
  }

}

export default connect(mapStateToProps)(Form);

function Form<InputInterface>(
  options: FormProps & { userPermissions?: PermissionsEnum[] } = defaultFormProps
) {
  const { control, handleSubmit, errors, reset, getValues } = useForm<InputInterface & { fieldValue: any }>();
  const [loading, setLoading] = React.useState(false);

  let total = options.inputs.length;
  options.sections?.forEach(section => total = total + (section?.inputs?.length || 0));
  const [uploadComplete, setUploadComplete] = useState<boolean[]>(Array.from({ length: total }, (v, i) => true));
  const setReset = (index: number) => () => {
    let uploadDone = [...uploadComplete];
    uploadDone.splice(index, 1, true);
    setUploadComplete(uploadDone);
  };
  const resetReset = (index: number) => () => {
    let uploadDone = [...uploadComplete];
    uploadDone.splice(index, 1, false);
    setUploadComplete(uploadDone);
  };

  const AggregateInputs: (FormInputProps & {
    index: number;
    sectionIndex?: number;
    customControlName: string;
  })[] = options.inputs.map((input, index) => {
    return {
      ...input, customControlName: getInputName(input, options.customControlName, index), index,
      // resetReset: resetReset(index),
      setReset: setReset(index)
    };
  });

  if (options.sections) {
    let i = options.inputs.length;
    for (let sectionIndex in options.sections) {
      for (let inputIndex in options.sections[sectionIndex]?.inputs) {
        AggregateInputs.push({
          ...(options.sections[sectionIndex].inputs[inputIndex]), customControlName: getInputName(options.sections[sectionIndex].inputs[inputIndex], options.customControlName, Number(inputIndex), Number(sectionIndex)), index: Number(inputIndex), sectionIndex: Number(sectionIndex),
          // resetReset: resetReset(i),
          setReset: setReset(i)
        });
        i += 1;
      }
    }
  }

  const onSubmit = async (formData: any, event?: BaseSyntheticEvent<object, any, any>, submit: boolean = true) => {
    if (options.apiRequest) {
      try {
        // renaming questions with . in them..
        if (!options.customControlName) {
          let needRenaming: FormInputProps[] = options.inputs.filter(input => (!input.customControlName && input.name.includes('.') && !input.keyName));
          let namesToBeDeleted: string[] = [];
          for (let input of needRenaming) {
            let { currentName, body } = getEmbededValueDueToFullStopsInName(formData, input.name);
            namesToBeDeleted.push(currentName);
            formData[input.name] = body;
          }
          if (options.sections) {
            for (let section of options.sections) {
              if (section.inputs)
                for (let input of section.inputs) {
                  if (!input.customControlName && input.name.includes('.') && !input.keyName) {
                    let { currentName, body } = getEmbededValueDueToFullStopsInName(formData, input.name);
                    namesToBeDeleted.push(currentName);
                    formData[input.name] = body;
                  }
                };
            };
          }

          for (let currentName of namesToBeDeleted)
            delete formData[currentName];
        }
        setLoading(true);
        if (options.confirmation) {
          let confirmationData: any = {};
          AggregateInputs.forEach((input) => {
            confirmationData[input.customControlName] = formData[input.customControlName];
          });
          let { data: doesNotNeedConfirmation } =
            await options.confirmation.apiRequest(confirmationData);
          if (!doesNotNeedConfirmation) {
            const confirmed = window.confirm(
              options.confirmation.confirmationText
            );
            if (!confirmed) {
              throw new Error(options.confirmation.notConfirmedMessage);
            }
          }
        }
        const fileUploadPromises: Promise<void>[] = [];

        // Checking if any files need to be uploaded to AWS S3 before submitting the form
        for (let input of AggregateInputs) {
          if (input.type === "file" && input.upload) {
            // upload to s3 and replace file in form with s3 url
            const index = Object.keys(formData).indexOf(input.customControlName);

            // Inputs[Object.values(AggregateInputs).indexOf(input)]?.props?.children?.props?.children?.ref?.current?.updateResetName?.('SOLACE')
            // continue

            if (index === -1) continue;
            if (Object.keys(formData[input.customControlName]).length === 1 && Object.keys(formData[input.customControlName])[0] === 'name') {
              formData[input.customControlName] = formData[input.customControlName].name;
            }
            else if (uploadComplete[Object.values(AggregateInputs).indexOf(input)]) {
              formData[input.customControlName] = input.defaultValue;
            }
            else {
              const file: File = Object.values(formData)[index] as File;
              if (!file) continue;
              fileUploadPromises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    const { data } = await axios.get(
                      `/uploads/signedUploadUrl?fileName=${file.name}&mimeType=${file.type}`
                    );
                    const uploadUrl = data.signedRequest;
                    var instance = axios.create();
                    instance.defaults.headers.common = {};
                    instance.defaults.headers = {};
                    await instance.put(uploadUrl, file, {
                      headers: {
                        "Content-Type": file.type,
                      },
                      onUploadProgress: (progressEvent) => {
                        let percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        //TODO: display progress but not sure how
                        console.log("percent completed", percentCompleted);
                      },
                    });
                    // Replacing file wit s3 url
                    if (options.dynamicForm) {
                      //   let fileUploadQuestion = formData._questions[input.name];
                      Object.assign(formData, { [input.customControlName]: { value: data.url, questionId: input.questionId, _id: input.answerId, questionText: input.name, type: typeToAnswerType(input.type), controlName: input.customControlName } });
                    } else {
                      Object.assign(formData, { [input.customControlName]: data.url });
                    }
                    input.setReset?.();
                    return resolve();
                  } catch (error) {
                    return reject();
                  }
                })
              );
            }
          }

          // submittig only the value of the option not the full JSON option
          if (input.type === "selectAutocomplete")
            formData[input.customControlName] = formData[input.customControlName]?.value;

          // submiiting only the values of the options not the full JSON options
          if (input.type === "multipleSelectAutocomplete")
            formData[input.customControlName] = formData[input.customControlName]?.map(
              (el: any) => el?.value
            );

          // if (input.keyName) {
          //   renameKey(formData, input.name, input.keyName);
          // }
          if (options.dynamicForm) {
            formData[input.customControlName] = {
              value: formData[input.customControlName],
              questionId: input.questionId,
              _id: input.answerId,
              questionText: input.name,
              type: typeToAnswerType(input.type),
              controlName: input.customControlName,
            };
          }
        }

        await Promise.all(fileUploadPromises);
        if (options.dynamicForm) {
          let questions = [];
          for (let index = 0; index < options.inputs.length; index++) {
            let data = formData[getInputName(options.inputs[index], options.customControlName, index)];
            if (typeof data.value === 'boolean')
              questions.push(data);
            else if (data.value || data.value === 0) {
              if (Array.isArray(data.value)) {
                if (data.value.length > 0) {
                  questions.push(data);
                }
              }
              else {
                questions.push(data);
              }
            }
          };
          let sections: any[] = [];
          if (options.sections) {
            for (let sectionIndex = 0; sectionIndex < options.sections.length; sectionIndex++) {
              let section: any[] = [];
              for (let index = 0; index < options.sections[sectionIndex].inputs.length; index++) {
                let data = formData[getInputName(options.sections[sectionIndex].inputs[index], options.customControlName, index, sectionIndex)];
                if (typeof data.value === 'boolean')
                  section.push(data);
                else if (data.value || data.value === 0) {
                  if (Array.isArray(data.value)) {
                    if (data.value.length > 0) {
                      section.push(data);
                    }
                  }
                  else {
                    section.push(data);
                  }
                }
              };
              if (section.length > 0) sections.push(section);
            };
          };
          formData = { ...(questions.length > 0 ? { _questions: questions } : {}), ...(sections.length > 0 ? { _sections: sections } : {}) };
        }
        if (options.additionalData) formData.additionalData = { ...options.additionalData };
        const body: InputInterface = formData;
        const response = await options.apiRequest({
          ...body,
          ...options.extraArgs,
          ...(submit ? {} : { _save: true })
        });
        setLoading(false);
        const { data } = response || { data: "Success" };

        if (options.successMessage)
          enqueueSnackbar(await options.successMessage(data), {
            variant: "success",
          });

        if (options.callback) options.callback(data);

        if (options.onModalClose) options.onModalClose();
      } catch (error) {
        setLoading(false);
        let errorText: string = await (
          options.errorMessage ?
            options.errorMessage(error)
            : extractErrorText(error as Err)
        );
        enqueueSnackbar(errorText, {
          variant: "error",
        });
        // throw error;
      }
    }
  };

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const Inputs = options.inputs.filter(i => CheckPermissions(options.userPermissions || [], i.permissions || [])).map((input, i) => {
    return (
      <div className="col-lg-6 col-md-6 col-sm-12 mb-3 p-sm-0" key={i}>
        <div className="form-input" key={i}>
          {InputFactory(input.type, {
            id: `${i}`,
            control,
            errors,
            name: input.name,
            required: input.required,
            multiline: input.multiline,
            ...(input.fileType ? { fileType: input.fileType } : {}),
            onChange: input.onChange || undefined,
            multiFile: input.multiFile,
            helperText: input.helperText,
            helperTextCustomStyle: input.helperTextCustomStyle,
            customControlName: getInputName(
              input,
              options.customControlName,
              i
            ),
            defaultValue:
              input.defaultValue || input.defaultValue === 0
                ? input.defaultValue
                : "",
            options: input.options,
            rules: {
              required: input.required,
            },
            resetReset: resetReset(i),
            // setReset: setReset(i),
          })}
        </div>
      </div>
    );
  });

  if (options.sections) {
    let i = options.inputs.length;
    for (let section in options.sections) {
      Inputs.push(<div key={`section-${section}-title`}><SectionTitle text={options.sections[section].title} /></div>);
      if (options.sections[section].description) {
        Inputs.push(<div key={`section-${section}-description`}><SectionDescription text={options.sections[section].description} /></div>);
      }
      for (let input in options.sections[section].inputs) {
        if (CheckPermissions(options.userPermissions || [], options.sections[section].inputs[input].permissions || []))
        Inputs.push(
          <div className="form-input" key={`section-${section}-question-${input}`}>
            {InputFactory(options.sections[section].inputs[input].type, {
              id: `section-${section}-question-${input}`,
              control,
              errors,
              name: options.sections[section].inputs[input].name,
              required: options.sections[section].inputs[input].required,
              defaultValue: (options.sections[section].inputs[input].defaultValue || options.sections[section].inputs[input].defaultValue === 0) ? options.sections[section].inputs[input].defaultValue : "",
              options: options.sections[section].inputs[input].options,
              rules: {
                required: options.sections[section].inputs[input].required,
              },
              multiline: options.sections[section].inputs[input].multiline,
              ...(options.sections[section].inputs[input].fileType ? { fileType: options.sections[section].inputs[input].fileType } : {}),
              ...(options.sections[section].inputs[input].onChange ? { onChange: options.sections[section].inputs[input].onChange } : {}),
              helperText: options.sections[section].inputs[input].helperText,
              helperTextCustomStyle: options.sections[section].inputs[input].helperTextCustomStyle,
              customControlName: getInputName(options.sections[section].inputs[input], options.customControlName, Number(input), Number(section)),
              resetReset: resetReset(i),
              // setReset: setReset(i),
            })}
          </div>
        );
        i += 1;
      };
    };
  }

  const form = (
    <React.Fragment>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='row m-0 w-100'>
          {Inputs}
        </div>
        <div className="form-buttons row m-0 w-100 d-flex justify-content-center mt-3">
          <div className="wrapper">
            {/* <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t("SUBMIT")}
              </Button> */}
            <button className='btn btn-success ml-1 mr-1' disabled={loading}>{t("SUBMIT")}</button>
            {loading && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </div>
          {/* TODO reset needs to take the default values as an input */}
          {/* <Button
              style={{ marginRight: "10px", marginLeft: "10px" }}
              variant="contained"
              color="secondary"
              onClick={() => reset()}
            >
              {t("CLEAR")}
            </Button> */}
          <button className='btn btn-danger ml-1 mr-1' type='button' onClick={() => reset()}>{t("CLEAR")}</button>

          <Guard condition={options.saveButton}>
            <div className="wrapper">
              {/* <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onSubmit(getValues(), undefined, false);
                  }}
                >
                  {t("SAVE")}
                </Button> */}
              <button className='btn btn-primary ml-1 mr-1' disabled={loading} onClick={() => {
                onSubmit(getValues(), undefined, false);
              }}>{t("SAVE")}</button>

              {loading && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </div>
          </Guard>
        </div>
      </form>
    </React.Fragment>
  );

  if (options.withModal)
    return (
      <React.Fragment>
        <Modal
          title={options.title || "Form"}
          description={options.description}
          open={options.modalOpen || false}
          handleSubmit={console.log}
          hideButtons
          handleClose={options.onModalClose}
          explicitCloseButton={options.explicitCloseButton}
        >
          <br />
          {form}
        </Modal>
      </React.Fragment>
    );
  else
    return (
      <React.Fragment>
        <div className='row m-0 w-100'>
          <div className='col-lg-12 mb-2 p-sm-0'>
            <p className="form-title m-0 p-0">{options.title}</p>
          </div>
          <div className='col-lg-12 mb-1 p-sm-0'>
            <InputLabel className='form-description'>{options.description}</InputLabel>
          </div>
          <div className='col-lg-12 mb-3 p-sm-0'>
            <div style={{ background: '#e6e6e6', width: '30%', height: '1px' }} className='mt-3 w-md-100 w-sm-100'></div>
          </div>
          <div className='col-lg-12 mb-2 p-0 mt-3'>
            {form}
          </div>
        </div>
      </React.Fragment>
    );
}
