import React from "react";
// import { useHistory } from "react-router-dom";
import SingleComponent from "../../components/SingleCompnent/SingleComponent";
// import CollectionComponent from '../../components/CollectionComponent/CollectionComponent';
import { findEmployee } from "../../api/users.api";

const User = () => {
  // const history = useHistory();
  const [employee, setEmployee] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  React.useEffect(() => {
    async function fetchData() {
      const { data } = await findEmployee(18);
      setEmployee(data.employee);
    }

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <SingleComponent
        title=""
        fields={[
          {
            name: "Name",
            value: employee?.firstName + " " + employee?.lastName,
          },
          {
            name: "Email",
            value: employee?.email,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default User;
