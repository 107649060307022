import * as types from '../types/settings.types';

const initialState = {
  language: 'en'
}

export default function settingsReducer(state = initialState, action: any) {
  switch (action.type) {
    case (types.CHANGE_LANGUAGE):
      return {
        ...state,
        language: action.payload.language
      }
    default:
      return state;
  }
}
