import { OpenInNew } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/applicationProcesses.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText, { Err } from "../util/functions/extractErrorText";

interface IApplication {
  id: number;
  reviewed: boolean;
  accepted: boolean;
  submissionDate: Date;
}

interface IApplicationProcess {
  id?: number;
  programOffering: any;
  applications?: IApplication[];
  name: string;
  additionalFormId: string;
  startDate: Date;
  endDate: Date;
  archived?: boolean;
}

const ApplicationProcess: React.FunctionComponent<{
  children?: never;
}> = () => {
  const id = useParams<{ id: string }>().id;
  const history = useHistory();

  const [data, setData] = useState<IApplicationProcess>();
  const [refreshWanted, setRefresh] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  function refresh() {
    setRefresh(refreshWanted + 1);
  }

  useEffect(() => {
    (async () => {
      let { data } = await api.findOne(+id);
      setData(data);
    })();
  }, [refreshWanted]);

  return (
    <>
      <SingleComponent
        title="Application Process"
        fields={[
          {
            name: "Name",
            value: data?.name,
          },
          {
            name: "Start Date",
            value: RenderDate(["startDate"])(data),
          },
          {
            name: "End Date",
            value: RenderDate(["endDate"])(data),
          },
        ]}
      />
      <br />
      <PermissionsGuard
        requiredPermissions={[PermissionsEnum.ViewApplications]}
      >
        <CollectionComponent
          title="Applications"
          data={data?.applications || []}
          loading={!data}
          columns={[
            {
              title: "Student first name",
              field: "studentProfile.user.firstName"
            },
            {
              title: "Student last name",
              field: "studentProfile.user.lastName"
            },
            {
              title: "Student email",
              field: "studentProfile.user.email"
            },
            {
              title: "Reviewed",
              render: RenderBoolean(["reviewed"]),
              field: 'reviewed',
            },
            {
              title: "Accepted",
              render: RenderBoolean(["accepted"]),
              field: 'accepted',
            },
            {
              title: "Submission Date",
              render: RenderDate(["submissionDate"]),
              field: 'submissionDate',
            },
          ]}
          viewOnly
          actions={[
            {
              name: "Accept",
              icon: "check",
              onClick: async (_e, data: IApplication) => {
                try {
                  let confirmed = window.confirm(`Are you sure you want to accept this student's application?`);
                  if (!confirmed) return;
                  await api.accept(data.id); refresh();
                } catch (e) {
                  enqueueSnackbar(await extractErrorText(e as Err), { variant: 'error' });
                  refresh();
                }
              },
              tooltip: "Accept application",
              condition: (data: IApplication) => (!data.reviewed && data.submissionDate),
              requiredPermission: PermissionsEnum.AcceptApplication
            },
            {
              name: "Reject",
              icon: "close",
              onClick: async (_e, data: IApplication) => {
                let confirmed = window.confirm(`Are you sure you want to reject this student's application?`);
                if (!confirmed) return;
                await api.reject(data.id); refresh()
              },
              tooltip: "Reject application",
              condition: (data: IApplication) => (!data.reviewed && data.submissionDate),
              requiredPermission: PermissionsEnum.RejectApplication
            },
            {
              name: "View",
              icon: OpenInNew,
              requiredPermission: PermissionsEnum.ViewStudentApplication,
              onClick: (_e, data: IApplication) => {
                history.push(`/application/${data.id}`);
              },
            },
          ]}
        />
      </PermissionsGuard>
    </>
  );
};

export default ApplicationProcess;
