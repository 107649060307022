import {useEffect, useRef} from "react";
import {useQuery} from "react-query";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import useFindRequest from "./useFindRequest.hook";

function useFindRequestQueryParams(
  findAction: Function, queryKey: string, customName?: string, ignoreErrors?: boolean
): [
    any,
    number,
    boolean,
    number,
    number,
    string,
    Function,
    Function,
    Function,
    Function,
    Function
  ] {
  let queryParams = ['page', 'page_size', 'search', 'view'];
  if (customName) queryParams = queryParams.map(p => customName + p);
  const [pageQuery, pageSizeQuery, searchQuery, viewQuery] = queryParams;

  const [query, setQuery] = useQueryParams({
    [pageQuery]: NumberParam,
    [pageSizeQuery]: NumberParam,
    [searchQuery]: StringParam,
    [viewQuery]: StringParam,
  });

  const onPageChange = (pageNumber: number) => {
    setQuery({
      ...query,
      [pageQuery]: pageNumber,
    });
  };

  const onPageSizeChange = (pageSize: number) => {
    setQuery({
      ...query,
      [pageSizeQuery]: pageSize,
      [pageQuery]: 1,
    });
    // window.location.reload();
  };

  const onSearchChange = (search: string) => {
    setQuery({
      ...query,
      [searchQuery]: search,
      [pageQuery]: 1,
    });
  };

  const onHideChange = (show: string | undefined) => {
    setQuery({
      ...query,
      [viewQuery]: show,
      [pageQuery]: 1,
    });
  };

  const { data, pagesCount, loading, refresh } = useFindRequest({
    findAction,
    queryKey,
    page: (query[pageQuery] as number) || 1,
    pageSize: (query[pageSizeQuery] as number) || 10,
    searchString: (query[searchQuery] as string) || "",
    viewKey: (query[viewQuery] as string | undefined) || undefined,
    ignoreErrors
  });

  return [
    data,
    pagesCount,
    loading,
    (query[pageQuery] as number) || 1,
    (query[pageSizeQuery] as number) || 10,
    (query[searchQuery] as string) || "",
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ];
}

export default useFindRequestQueryParams;
