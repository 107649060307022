import React, { useEffect, useState } from "react";
import Head from "../../components/Head";
import CollectionComponent from "../../components/CollectionComponent/CollectionComponent";
import {
  exportUsers,
  findEmployees,
  inviteEmployee,
  toggleBan,
  update,
} from "../../api/users.api";
import useFindRequestQueryParams from "../../hooks/useFindRequestQueryParams.hook";
import Button from "../../components/Button";
import RenderArray from "../../components/CollectionComponent/CustomRender/RenderArray";
import { MultiSelectInput } from "../SingleMultiSelect";
import axios from "axios";
import { handleResponseError } from "../recipesDatabase/utils";
import RenderBoolean from "../../components/CollectionComponent/CustomRender/RenderBoolean";
import ComponentGuard from "../../components/guards/ComponentGuard";
import { PermissionsEnum } from "../../util/enum/permissions.enum";
import extractErrorText from "../../util/functions/extractErrorText";

const Users = () => {
  const [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = useFindRequestQueryParams(findEmployees, "employees");

  let [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      let { data: allroles } = await axios.get("/roles/active");
      setRoles(allroles);
    })();
  }, []);

  return (
    <React.Fragment>
      <Head title="Employees" path="/users" />
      <div className="buttons-section">
        <ComponentGuard requiredPermissions={[PermissionsEnum.InviteEmployees]}>
          <Button
            color="primary"
            label="Invite Employee"
            withForm
            form={{
              title: "Invite Employee",
              description:
                "Please enter the details of the employee you wish to invite",
              apiRequest: inviteEmployee,
              inputs: [
                {
                  type: "email",
                  name: "email",
                  required: true,
                },
                {
                  type: "multiSelect",
                  name: "profiles",
                  required: true,
                  options: [{ value: 'adminProfile', label: 'Admin' }, { value: 'facultyProfile', label: 'Faculty' }]
                },
              ],
              successMessage: (body: any) => { refresh(); return body },
              errorMessage: extractErrorText,
            }}
          />
        </ComponentGuard>
      </div>
      <CollectionComponent
        title="Employees"
        loading={loading}
        data={data}
        columns={[
          { title: "ID", field: "id", editable: "never" },

          { title: "First Name", field: "firstName" },
          { title: "Middle Name", field: "middleName", render: (data) => data.middleName || '-' },
          { title: "Last Name", field: "lastName" },
          { title: "Arabic Name", field: "arabicName", render: (data) => data.arabicName || '-' },
          { title: "Email", field: "email", editable: "never" },
          {
            title: "Roles",
            field: "roles",
            render: RenderArray(["roles"], (role) => role.name),
            editComponent: (props: any) => {
              props.rowData.roles = props.rowData.roles?.map((role: any) => role.id ? role.id : role)
              return (
                <MultiSelectInput
                  id='employee-roles'
                  defaultValue={props.rowData.roles}
                  options={roles.map((role: any) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  onChange={(e: any) => {
                    props.onChange(e.target.value);
                  }}
                />
              );
            },
          },
          {
            title: "Employee Type",
            editable: "never",
            render: ((row) => {
              if (row.adminProfile && row.facultyProfile) return 'Faculty and Admin';
              if (row.adminProfile) return 'Admin';
              if (row.facultyProfile) return 'Faculty';
            }),
          },
          {
            title: "Banned",
            field: "isBanned",
            editable: "never",
            render: RenderBoolean(["isBanned"]),
          },
        ]}
        backendPagination
        viewDisabledInSeparateTab
        searchText={search}
        onSearchChange={onSearchChange}
        onHideChange={onHideChange}
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowUpdate={async (input: any) => {
          await update(input);
          refresh();
        }}
        actions={[
          {
            icon: "block",
            tooltip: "Toggle user ban",
            onClick: async (e: any, data: any) => {
              let confirmed = window.confirm(
                `Are you sure you want to ${(data.isBanned && "un") || ""
                }ban this user?`
              );
              if (confirmed) {
                await toggleBan(data.id);
                refresh();
              }
            },
            errorMessage: handleResponseError,
          },
        ]}
        disableKey="isBanned"
        noDelete={true}
        export={{
          type: "custom",
          fn: exportUsers,
        }}
      />
    </React.Fragment>
  );
};

export default Users;
