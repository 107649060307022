import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory, History } from "history";
import Store from "./store/Store";
import { HelmetProvider } from "react-helmet-async";
import SnackBar from "./components/SnackBar";
import "./localization/localization";
import { QueryParamProvider } from "use-query-params";
import Interceptor from "./components/Interceptor";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers["Accept-Language"] = "all";

const history: History = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <SnackBar>
      <Store>
        <Interceptor />
        <HelmetProvider>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <App />
            </QueryParamProvider>
          </Router>
        </HelmetProvider>
      </Store>
    </SnackBar>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
