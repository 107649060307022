import axios from "axios";
import { generateRoutes } from "./base.api";

let routes = generateRoutes("/courseSchedule");

const available = (id: number) => (data: any) => axios.post('/courseSchedule/available', { ...data, courseOfferingId: id });

export default {
  remove: routes.remove,
  available
};
