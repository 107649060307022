import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import React from "react";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import api from "../api/buildings.api";
import Button from "../components/Button";
import { handleResponseError } from "./recipesDatabase/utils";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import apiRoom from "../api/rooms.api";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { PermissionsEnum } from "../util/enum/permissions.enum";

const Building = () => {
    let [building, setBuilding] = useState<{
        campus_id: number,
        name: string,
        id: number,
        campus: any,
        rooms: any[],
    }>();

    const [loading, setLoading] = useState(false);

    async function loadBuilding() {
        try {
            setLoading(true);
            let { data: building } = await api.findOne(+id);
            setBuilding(building);
            setLoading(false);
        } catch {
            history.push("/");
        }
    }
    const refresh = loadBuilding;
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        loadBuilding();
    }, []);

    if (!building) {
        return (
            <React.Fragment />
        )
    }

    return (
        <React.Fragment>
            <Head title='Building' path={`/building/${id}`} />

            <SingleComponent
                title="Building info"
                fields={[
                    {
                        name: "Building name",
                        value: building.name,
                    },
                    {
                        name: "Campus",
                        value: building.campus.name
                    },
                ]}
            />
            <div className="buttons-section">
                <Button
                    color="primary"
                    label="Add Room"
                    withForm
                    form={{
                        title: "Add Room",
                        description: "Add a new room.",
                        apiRequest: async (data: any) => { await apiRoom.add({ name: data.name, building_id: id, capacity: data.capacity }) },
                        inputs: [
                            {
                                type: "text",
                                name: "Name",
                                keyName: "name",
                                required: true,
                            },
                            {
                                type: "text",
                                name: "Capacity",
                                keyName: "capacity",
                                required: true,
                            }
                        ],
                        successMessage: () => {
                            refresh();
                            return "Room added successfully.";
                        },
                        errorMessage: handleResponseError,
                    }}
                />
            </div>
            <CollectionComponent
                title="Building Rooms"
                loading={loading}
                data={building.rooms}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                    },
                    {
                        title: "Capacity",
                        field: "capacity",
                    }

                ]}
                backendPagination
                // pagesCount={pagesCount}
                // page={page}
                // pageSize={pageSize}
                // onPageChange={onPageChange}
                // onPageSizeChange={onPageSizeChange}
                onRowDelete={async (oldData: any) => {
                    if (oldData.archived) {
                        alert("Room already deleted");
                        return;
                    }
                    await apiRoom.remove(oldData.id);
                    refresh();
                }}
                onRowUpdate={async (input: any, oldData: any) => {
                    await apiRoom.update(oldData.id, input);
                    refresh();
                }}
                // searchText={search}
                // onSearchChange={(text: string) => {
                //     onSearchChange(text);
                // }}
                disableKey="archived"
                actions={[
                    {
                        icon: "undo",
                        tooltip: "Restore room",
                        onClick: async (_e: any, data: any) => {
                            if (!data.archived) {
                                alert("Room not deleted");
                                return;
                            }
                            const confirmed = window.confirm(
                                "Are you sure you want to restore this room?"
                            );
                            if (!confirmed) return;
                            await apiRoom.restore(data.id);
                            refresh();
                        },
                    },
                    {
                        icon: OpenInNewIcon,
                        tooltip: "View Room",
                        onClick: (_e: any, data: any) => history.push(`/room/${data.id}`),
                    }
                ]}
                mainPermission={PermissionsEnum.ModifyRooms}
            />

        </React.Fragment>
    );
};

export default Building;
