import React from 'react';
import Guard from './Guard';
import { useMediaQuery } from 'react-responsive';
import { forwardRef } from 'react';

const MobileGuard: React.FunctionComponent<{
  fallback?: React.ReactNode;
}> = forwardRef((props, ref) => {

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <Guard condition={isMobile} fallback={props.fallback}>
      {props.children}
    </Guard>
  )
})

export default MobileGuard;
