import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import api from "../api/applicationProcesses.api";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router";
import React from "react";
import Head from "../components/Head";
import {DateTimePicker} from "@material-ui/pickers";
import {PermissionsEnum} from "../util/enum/permissions.enum";

const ApplicationProcesses: React.FunctionComponent<{ children?: never }> = () => {
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange
  ] = useFindRequestQueryParams(api.find, "applicationProcesses");

  const history = useHistory();

  return (
    <React.Fragment>
      <Head title="Application Processes" path={`/application-processes`} />
      <CollectionComponent
        title="Application Processes"
        loading={loading}
        data={data}
        columns={[
          {
            title: "Name",
            field: "name",
            editable: "never",
            customFilterAndSearch: () => true,
          },
          {
            title: "Semester",
            field: "semester",
            editable: "never",
            render: (row) => {
              if (row?.programOffering?.semester) {
                return `${row.programOffering.semester.semester} ${row.programOffering.semester.year}`;
              } else return "-";
            },
            customFilterAndSearch: () => true,
          },
          {
            title: "Start Date",
            field: "startDate",
            editComponent: (props) => <DateTimePicker format="MMMM dd, yyyy hh:mm a" value={props.value} onChange={(val) => props.onChange(val)} />,
            render: RenderDate(["startDate"]),
          },
          {
            title: "End Date",
            field: "endDate",
            editComponent: (props) => <DateTimePicker format="MMMM dd, yyyy hh:mm a" value={props.value} onChange={(val) => props.onChange(val)} />,
            render: RenderDate(["endDate"]),
          },
        ]}
        onRowUpdate={async (newData: any, oldData: any) => {
          await api.update(oldData.id, newData);
          await refresh();
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: "Archive application process",
            onClick: async (_event, rowData: {id: number}) => {
							if (!window.confirm("Are you sure you want to archive this application process")) return;
              await api.archive(rowData.id);
              await refresh();
            },
            condition: (rowData: any) => !rowData.archived,
            requiredPermission: PermissionsEnum.ArchiveApplicationProcess
          },
          {
            icon: 'undo',
            tooltip: "Restore application process",
            onClick: async (_event, rowData: {id: number}) => {
							if (!window.confirm("Are you sure you want to restore this application process")) return;
              await api.restore(rowData.id);
              await refresh();
            },
            condition: (rowData: any) => rowData.archived,
            requiredPermission: PermissionsEnum.RestoreApplicationProcess
          },
          {
            icon: OpenInNew,
            tooltip: "View Application Process",
            onClick: (_event, rowData: { id: number }) => {
              history.push(`/application-process/${rowData.id}`);
            },
          },
        ]}
        noDelete
        mainPermission={PermissionsEnum.EditApplicationProcess}
        viewDisabledInSeparateTab
        onHideChange={onHideChange}
        backendPagination
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        itemName="application process"
        disableKey="archived"
      />
    </React.Fragment>
  );
};


export default ApplicationProcesses;
