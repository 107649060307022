import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import useDarkMode from "use-dark-mode";
import "./styles/App.scss";
import AppRouter from "./AppRouter";
import AppDrawer from "./components/AppDrawer";
import Footer from "./components/Footer";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { createMuiTheme } from "@material-ui/core";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/styles";
import {QueryClient, QueryClientProvider} from "react-query";

const App: React.FunctionComponent<{
  language: string;
}> = (props) => {
  const darkMode = useDarkMode(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  const theme = createMuiTheme({
    direction: props.language === "ar" ? "rtl" : "ltr",
    palette: {
      type: darkMode.value ? "dark" : "light",
    },
  });
  
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  return (
    <React.Fragment>
      <div
        className={props.language === "ar" ? "arabic" : "english"}
        dir={props.language === "ar" ? "rtl" : "ltr"}
      >
        <QueryClientProvider client={new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
              retry: false
            }
          }
        })}>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>
						<MuiThemeProvider theme={theme}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<AppDrawer>
									<AppRouter />
									<Footer />
								</AppDrawer>
							</MuiPickersUtilsProvider>
						</MuiThemeProvider>
          </ThemeProvider>
        </StylesProvider>
        </QueryClientProvider>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(App);
