import React, { useEffect, useState } from "react";
import Head from "../../components/Head";
import CollectionComponent from "../../components/CollectionComponent/CollectionComponent";
import {
  findStudents,
  closeRegistration,
  setStudentStatus,
  editStudent,
  // getStudentIdFromSeq,
  exportStudents,
  generateOfficialTranscripts,
  exportStudentEnrollments,
  exportStudentScholarships,
  exportCourseStaff,
  exportStudentGrades,
  exportStudentsAcademicData,
} from "../../api/users.api";
import useFindRequestQueryParams from "../../hooks/useFindRequestQueryParams.hook";
import RenderBoolean from "../../components/CollectionComponent/CustomRender/RenderBoolean";
import DateTimeInput from "../../components/forms/inputs/DateTimeInput";

import { useForm } from "react-hook-form";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useHistory } from "react-router";
import { PermissionsEnum } from "../../util/enum/permissions.enum";
import ComponentGuard from "../../components/guards/ComponentGuard";
import Button from "../../components/Button";
// import axios from "axios";
// import { addFileUpload } from "../../api/files.api";
import { handleResponseError } from "../recipesDatabase/utils";
import RenderDate from "../../components/CollectionComponent/CustomRender/RenderDate";
import Form from "../../components/forms/Form";
import extractErrorText from "../../util/functions/extractErrorText";
import { SemesterEnum } from "../../util/enum/semester.enum";
import programsApi from "../../api/programs.api";
import concentrationsApi from "../../api/concentrations.api";
import { useSnackbar } from "notistack";
import contentDisposition from "content-disposition";
import { ExportModal } from "../../components/ExportModal";

const ExportButton = (props: { label: string, apiCall: Function, requiredPermissions: PermissionsEnum[] }) => {
  return <ComponentGuard requiredPermissions={props.requiredPermissions}>
    <Button
      color="primary"
      label={props.label}
      withForm
      form={{
        inputs:
          [
            {
              name: "Export as",
              keyName: "format",
              required: true,
              type: "select",
              options: [
                {
                  label: "CSV",
                  value: "csv",
                },
                {
                  label: "PDF",
                  value: "pdf",
                },
                {
                  label: "DOCX",
                  value: "docx",
                },
              ],
            },
          ],
        apiRequest: async (data: any) => {
          try {
            const response = await props.apiCall(
              data.format
            );

            let blob: Blob = response.data;
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            // console.log(response.headers);
            link.download = contentDisposition.parse(
              response.headers["content-disposition"]
            ).parameters["filename"];
            link.click();
          } catch (error: any) {
            if (error.response) {
              if (error.response.data instanceof Blob) {
                throw new Error(await error.response.data.text());
              } else {
                throw new Error(error.response.data);
              }
            }
            throw error;
          }
        }
      }}
    />
  </ComponentGuard>
}

const Students = () => {
  const [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = useFindRequestQueryParams(findStudents, "students");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [programOptions, setProgramOptions] = useState<any[]>([]);
  const [concentrationOptions, setConcentrationOptions] = useState<any[]>([]);
  useEffect(() => {
    (async () => {
      try {
        let { data: { data } } = await programsApi.getAll();
        let programOptions: { value: any, label: string }[] = [];
        data?.forEach((program: any) => {
          programOptions.push({ label: program?.translations?.find((translation: any) => translation.language === 'en')?.name || 'NA', value: program?.id })
        });
        setProgramOptions(programOptions); 
      }
      catch (error) { }
    })();
    (async () => {
      try {
        let { data: { data } } = await concentrationsApi.getAll();
        let concentrationOptions: { value: any, label: string }[] = [];
        data?.forEach((concentration: any) => {
          concentrationOptions.push({ label: concentration?.translations?.find((translation: any) => translation.language === 'en')?.name || 'NA', value: concentration?.id })
        });
        setConcentrationOptions(concentrationOptions);
      }
      catch (error) { }
    })();

  }, []);

  const { control, handleSubmit, errors, reset } = useForm<{
    inputs: [];
  }>();
  const [statusModalInfo, setStatusModalInfo] = useState<Record<string, any> | null>(null);
  const [exportAcademicDataModalOpen, setExportAcademicDataModalOpen] = useState(false);
  const [extraInputs, setExtraInputs] = useState<any[]>([]);
  const dismissalDateInput = (data: any) => ({
    name: "Dismissal date",
    keyName: "dismissalDate",
    type: "dateTime" as const,
    permissions: [PermissionsEnum.DismissStudents],
    required: true,
    defaultValue: data?.academicDismissalDate || new Date().toISOString(),
  });

  return (
    <React.Fragment>
      <Head title="Students" path="/students" />
      <div className="buttons-section">
        <ComponentGuard
          requiredPermissions={[
            PermissionsEnum.ViewStudentsWithOutstandingPayments,
          ]}
        >
          <Button
            color="primary"
            label="View Students With Outstanding Payments"
            handleClick={() => history.push("/students/outstanding-payments")}
          />
        </ComponentGuard>
        <ComponentGuard requiredPermissions={[PermissionsEnum.GenerateEnrollmentTranscript]}>
          <Button
            color='primary'
            label={'Generate Transcript(s)'}
            withForm
            form={{
              title: 'Request Official Transcripts',
              inputs: [
                {
                  name: 'Graduation Semester(s)',
                  type: 'multiText',
                  required: true,
                  helperText: `please enter the semesters using the '"semester" "year"' format (do not forget the space inbetween)`,
                  helperTextCustomStyle: { style: { fontSize: '10px' }, compact: true },
                  keyName: 'semesters',
                },
                {
                  name: 'Programs',
                  type: 'multipleSelectAutocomplete',
                  required: false,
                  keyName: 'programs',
                  options: programOptions,
                },
                {
                  name: 'Concentrations',
                  type: 'multipleSelectAutocomplete',
                  required: false,
                  keyName: 'concentrations',
                  options: concentrationOptions,
                },
                {
                  name: 'Hide Failed Courses',
                  type: 'checkbox',
                  required: false,
                  keyName: 'hideFailedCourses',

                }
              ],
              apiRequest: async (data: any) => {
                for (let sem of data.semesters) {
                  if (typeof sem !== 'string') throw new Error('Invalid format for graduation semester!');
                  let parts = sem.split(' ');
                  if (parts.length !== 2 || isNaN(+parts[1]) || !Object.values(SemesterEnum).map(v => v.toLowerCase()).includes(parts[0].toLowerCase())) {
                    throw new Error('Invalid format for graduation semester(s)!');
                  }
                }
                let res = await generateOfficialTranscripts(data);
                return enqueueSnackbar(res.data, {
                  variant: "info",
                });
              },
            }}
          />
        </ComponentGuard>
        <ExportButton label='Export Student Enrollments' apiCall={exportStudentEnrollments} requiredPermissions={[PermissionsEnum.ViewEnrollmentDetails]} />
        <ExportButton label='Export Student Grades' apiCall={exportStudentGrades} requiredPermissions={[PermissionsEnum.ViewCourseGrades]} />
        <ExportButton label='Export Course Staff' apiCall={exportCourseStaff} requiredPermissions={[PermissionsEnum.ViewCourseOfferings]} />
        <ExportButton label='Export Student Scholarships' apiCall={exportStudentScholarships} requiredPermissions={[PermissionsEnum.ViewStudentScholarshipHistory]} />

        <Button
          color="primary"
          label="Export academic data"
          handleClick={() => setExportAcademicDataModalOpen(true)}
        />
        {/* <ComponentGuard requiredPermissions={[PermissionsEnum.UploadStudentFiles]}> */}
          {/* <Button
            color="primary"
            label="Upload Student Files"
            withForm
            form={{
              inputs: [
                {
                  name: "Student files folder",
                  type: "file",
                  required: true,
                  fileType: "directory",
                  keyName: "files"
                },
              ],
              apiRequest: async (data: any) => {
                let validUsers: Record<number, number> = {};
                let failedUploads: { fileName: string }[] = [];
                let i = 0;
                for (let file of data.files) {
                  try {
                    let [all, userSeq] = file.webkitRelativePath.match(/.+?\/(.+?)\/.+$/) || [];

                    if (!all) {
                      throw new Error("Invalid file path");
                    };

                    let userId = validUsers?.[userSeq];
                    if (userId === -1) {
                      throw new Error(`User with seq ${userSeq} not found`)
                    }

                    if (!userId) {
                      try {
                        let id = (await getStudentIdFromSeq(userSeq)).data;
                        userId = validUsers[userSeq] = id;
                      } catch (error: any) {
                        if (error.response.status === 404) {
                          validUsers[userSeq] = -1;
                          throw new Error(`User with seq "${userSeq}" not found`)
                        } else {
                          throw error;
                        }
                      }
                    }

                    const {
                      data: { signedRequest, url: uniqueName },
                    } = await axios.get(`/uploads/signedUploadUrl?fileName=${file.name}&mimeType=${file.type || 'application/octet-stream'}`);

                    // Upload to AWS
                    const uploadUrl = signedRequest;
                    var instance = axios.create();
                    instance.defaults.headers.common = {};
                    instance.defaults.headers = {};
                    await instance.put(uploadUrl, file, {
                      headers: {
                        "Content-Type": file.type,
                      },
                    });

                    // Record file with user in backend
                    await addFileUpload({ filename: uniqueName, userId, displayName: "Unnamed file" });
                    if (i % 100 === 0) console.log(i)
                    i += 1;
                  } catch (error) {
                    failedUploads.push({ fileName: file.webkitRelativePath });
                    i += 1;
                  }
                }
                // TODO: Handle failed uploads
                console.log("Failed uploads", failedUploads)
              },
              successMessage: () => "Files uploaded successfully",
            }}
          /> */}
        {/* </ComponentGuard> */}
      </div>
      <CollectionComponent
        title="Students"
        loading={loading}
        data={data}
        columns={[
          { title: "ID", field: "user.id", editable: "never" },

          { title: "First Name", field: "user.firstName" },
          { title: "Middle Name", field: "user.middleName", render: (data) => data.user.middleName || '-' },
          { title: "Last Name", field: "user.lastName" },
          { title: "Arabic Name", field: "user.arabicName", render: (data) => data.user.arabicName || '-' },
          { title: "Email", field: "user.email", editable: "never" },
          {
            title: "Registration Deadline",
            field: "courseRegistrationDeadline",
            render: RenderDate(["courseRegistrationDeadline"]),
            editComponent: (props: any) => {
              let currentDeadline = props.rowData.courseRegistrationDeadline;
              return (
                <DateTimeInput
                  id='student-course-registration-deadline'
                  name={"deadline"}
                  defaultValue={currentDeadline}
                  control={control}
                  errors={errors}
                  onChange={(newValue: any) => {
                    props.rowData.courseRegistrationDeadline = newValue;
                  }}
                />
              );
            },
          },
          {
            title: "Banned",
            field: "user.isBanned",
            editable: "never",
            render: RenderBoolean(["user", "isBanned"]),
          },
          {
            title: "Job",
            render: ({ user }) => user.job || "-",
          },
          {
            title: "Marital status",
            render: ({ user }) => user.maritalStatus || "-",
          },
          {
            title: "Church name",
            render: ({ user }) => user.churchName || "-",
          }
        ]}
        backendPagination
        viewDisabledInSeparateTab
        onHideChange={onHideChange}
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        onRowUpdate={async (input: any) => {
          let result = await editStudent(input);
          refresh();
          return result;
        }}
        export={{
          type: "custom",
          fn: exportStudents,
        }}
        actions={[
          {
            icon: "block",
            tooltip: "Set user status",
            requiredPermission: PermissionsEnum.BanEmployees,
            onClick: async (_e: any, data: any) => {
              if (data.academicDismissalDate) {
                setExtraInputs([dismissalDateInput(data)]);
              }
              setStatusModalInfo(data);
            },
            errorMessage: handleResponseError,
          },
          {
            icon: "lock",
            tooltip: "Close registration session for user",
            onClick: async (e: any, data: any) => {
              let confirmed = window.confirm(
                `Are you sure you want to close the registration session for this user?`
              );
              if (confirmed) {
                await closeRegistration(data.user.id);
                refresh();
              }
            },
            errorMessage: handleResponseError,
          },
          {
            icon: OpenInNewIcon,
            tooltip: "View Student",
            requiredPermission: PermissionsEnum.ViewStudentDetails,
            onClick: (e: any, data: any) => {
              let id: number = data.userId;
              return history.push(`/students/${id}`);
            },
            errorMessage: handleResponseError,
          }
        ]}
        disableKey="user.isBanned"
        noDelete={true}
      />
      <Form
        inputs={[
          {
            name: "Banned",
            keyName: "banned",
            type: "checkbox",
            required: false,
            defaultValue: statusModalInfo?.user.isBanned,
          },
          {
            name: "Dismissed",
            keyName: "dismissed",
            permissions: [PermissionsEnum.DismissStudents],
            type: "checkbox",
            required: false,
            defaultValue: statusModalInfo?.academicDismissalDate ? true : false,
            onChange(event) {
              let value = event as unknown as boolean;
              if (value !== !!extraInputs.length) {
                setExtraInputs(value ? [dismissalDateInput(statusModalInfo)] : []);
              }
            },
          },
          ...extraInputs,
        ]}
        apiRequest={async (data: any) => {
          if (!statusModalInfo) return;
          await setStudentStatus(statusModalInfo.userId, data);
          await refresh();
        }}
        successMessage={() => "Successfully set student status"}
        errorMessage={extractErrorText}
        withModal
        modalOpen={statusModalInfo !== null}
        onModalClose={() => {
          setStatusModalInfo(null);
          setExtraInputs([]);
        }}
      />
      <ExportModal
        modalOpen={exportAcademicDataModalOpen}
        onClose={() => {
          setExportAcademicDataModalOpen(false);
        }}
        formTitle="Export academic data"
        exportFn={async function (data) {
          return exportStudentsAcademicData(data.format);
        }}
      />
    </React.Fragment>
  );
};

export default Students;
