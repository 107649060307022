import { Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getApplicationReferrals } from "../api/programForms.api";
import { renderQuestions } from "./Application";

const ApplicationReferrals = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { data } = useQuery(["referrals", +applicationId], async () => {
    return (await getApplicationReferrals(+applicationId)).data;
  }, {
		initialData: []
	});

  return <>
	{data.map((submission: { submission: any; form: any }) => {
		return <>
			<Typography variant="h4">Referrer: {submission.submission.referrerName} {`<${submission.submission.referrerEmail}>`}</Typography>
			<br />
			{renderQuestions(submission.form.questions)}
			<br />
		</>
	})}</>;
};

export default ApplicationReferrals;
