import React from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import api from "../api/formSections.api";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import Head from "../components/Head";
import Button from "../components/Button";
import extractErrorText from "../util/functions/extractErrorText";
import { handleResponseError } from "./recipesDatabase/utils";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router";
import ComponentGuard from "../components/guards/ComponentGuard";
import DeleteIcon from "@material-ui/icons/Delete";

const FormSections = () => {
  const history = useHistory();

  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = useFindRequestQueryParams(api.getFormSections, "formSections");

  return (
    <React.Fragment>
      <Head title="Form Sections" path="/formSections" />
      <div className="buttons-section">
        <ComponentGuard requiredPermissions={[PermissionsEnum.ModifyFormSections]}>
          <Button
            color="primary"
            label="Create Form Section"
            withForm
            form={{
              title: "Create Form Section",
              description:
                "Enter the details of the section you would like to create.",
              apiRequest: api.createFormSection,
              inputs: [
                {
                  type: "text",
                  name: "Name",
                  keyName: "name",
                  required: true,
                },
              ],
              successMessage: () => {
                refresh();
                return "Form section created successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </ComponentGuard>
      </div>
      <CollectionComponent
        title="Form Sections"
        loading={loading}
        data={data}
        noDelete
        columns={[
          {
            title: "Name",
            field: "name",
          },
        ]}
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        onHideChange={onHideChange}
        viewDisabledInSeparateTab
        backendSearch
        itemName="formSection"
        disableKey="archived"
        backendPagination
        onRowUpdate={async (input: any, oldData: any) => {
          await api.editSectionName(oldData.id, input);
          refresh();
        }}
        actions={[
          {
            onClick: (_: any, row: any) => history.push(`/form-section/${row.id}`),
            icon: () => <OpenInNew />,
          },
          {
            icon: "undo",
            tooltip: "Restore form section",
            onClick: async (e: any, data: any) => {
              let confirmed = window.confirm(
                `Are you sure you want to restore this form section?`
              );
              if (confirmed) {
                await api.restore(data.id);
                refresh();
              }
            },
            condition: (formSection: any) => formSection.archived,
            errorMessage: handleResponseError,
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Archive form section",
            onClick: async (_e: any, data: any) => {
              if (data.archived) {
                alert("Form section already deleted");
                return;
              }
              const confirmed = window.confirm(
                "Are you sure you want to delete this form section?"
              );
              if (!confirmed) return;
              await api.archive(data.id);
              refresh();
            },
            condition: (formSection: any) => !formSection.archived,
            errorMessage: handleResponseError,
          },
        ]}
        mainPermission={PermissionsEnum.ModifyFormSections}
      />
    </React.Fragment>
  );
};

export default FormSections;
