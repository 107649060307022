import React from "react";
import { useController } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import { DateTimePicker } from "@material-ui/pickers";
import { InputComponentProps } from "./InputComponentProps";
import { useTranslation } from "react-i18next";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import '../../../styles/Form.scss';

const DateTimeInput: React.FunctionComponent<InputComponentProps> = (props) => {
  const { field, meta } = useController({
    ...props,
    rules: {
      ...props.rules,
    },
    name: props.customControlName || props.name,
  });

  const { t } = useTranslation();

  const onChange = (data: MaterialUiPickersDate) => {
    let formatted = data && data.format && data.format("YYYY-MM-DD HH:mm");
    field.onChange(formatted || data);
    if (props.onChange) props.onChange(formatted || data);
  };

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className='input-label'>{props.name + (props.required ? "*" : "")}</InputLabel>
      <DateTimePicker
        id={props.id}
        error={meta.invalid}
        onChange={onChange}
        onBlur={field.onBlur}
        clearable={true}
        value={field.value || null}
        inputRef={field.ref}
        InputLabelProps={{ shrink: true }}
        format="MMMM dd, yyyy hh:mm a"
      />
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      {thisError?.type === "required" && (
        <span className="error">{t("FORM_INPUT_REQUIRED")}</span>
      )}
    </React.Fragment>
  );
};

export default DateTimeInput;
