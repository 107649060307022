export const getName = (
  rowData: any,
  translationName: string,
  lang = "en",
  fallback = "Name not found"
) => {
  if (!rowData) {
    return "Name not found";
  }
  let name = rowData[translationName].find((obj: any) => obj.language === lang); // Find name with language
  return name ? name.name : fallback;
};

export function toOptions(
  data: any,
  translation?: string[],
  translationKeyLabel?: string,
  keys?: string[]
) {
  let options: any = [];
  for (let piece of data) {
    let output: any = {};
    if (!keys) output.value = piece.id;
    else {
      output.value = {};
      for (let key of keys) {
        output.value[key] = piece[key]
      }
    }
    output.label = piece.id;
    if (translation) {
      for (let step of translation) {
        piece = piece[step];
      }
      let englishName = piece.find(
        (trans: any) => trans.language === "en"
      );
      output.label = englishName?.[translationKeyLabel || "name"] || "N/A";
    }
    options.push(output);
  }
  return options;
}

export function handleResponseError(err: {
  response: { data: any };
  message: any;
}) {
  let dummyElement = document.createElement("div");
  dummyElement.innerHTML = err?.response?.data || err.message;
  return dummyElement.textContent?.trim().replace(/\n{2,}/g, ": ");
}

export function flattenObjectArray(arr: any[], key: string, val: string) {
  return arr.reduce((obj: any, item: any) => {
    obj[item[key]] = item[val];
    return obj;
  }, {});
}

interface IApi {
  find: Function;
  update: Function;
  add: Function;
  remove: Function;
  restore: Function;
  uploadExcel: Function;
}

export class DefaultTableCompletions {
  private name: string;
  private api: IApi;
  private refresh: Function;

  constructor(name: string, api: IApi, refresh: Function) {
    this.name = name;
    this.api = api;
    this.refresh = refresh;
  }

  public async onDelete(data: any) {
    if (data.archived) {
      alert(`${this.name} is already deleted`);
      return;
    }
    await this.api.remove(data.id);
    this.refresh();
  }

  public generateTableActions() {
    return [this.generateRestoreAction()];
  }

  public generateRestoreAction() {
    return {
      icon: "undo",
      tooltip: `Restore ${this.name}`,
      onClick: async (_e: any, data: any) => {
        if (!data.archived) {
          alert(`${this.name} is not deleted`);
          return;
        }
        let confirmed = window.confirm(
          `Are you sure you want to restore ${this.name}`
        );
        if (!confirmed) return;
        await this.api.restore(data.id);
        this.refresh();
      },
    };
  }

  public async onUpdate(input: any, oldData: any) {
    try {
      await this.api.update(oldData.id, input);
      this.refresh();
    } catch (error) {
      window.alert(handleResponseError(error));
    }
  }
}
