import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/courses.api";
import Head from "../components/Head";
import React from "react";
import Button from "../components/Button";
import { handleResponseError, toOptions } from "./recipesDatabase/utils";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import apiPre from "../api/prerequisites.api";
import programsApi from "../api/programs.api";
import ComponentGuard from "../components/guards/ComponentGuard";
import { PricingStrategyEnum } from "../util/enum/pricingStrategy.enum";
import { SemesterEnum } from "../util/enum/semester.enum";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import extractErrorText from "../util/functions/extractErrorText";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import findOptions from "../api/interfaces/findOptions.interface";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import { useTranslation } from "react-i18next";

const Course = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  let [course, setCourse] = useState({} as any);
  let [programs, setPrograms] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let [programOptions, setProgramOptions] = useState([] as any);
  let [courseOfferings, setCourseOfferings] = useState([] as any);
  let [courses, setCourses] = useState([] as any[]);
  let [
    prerequisites,
    pagesCount,
    prerequisiteLoading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    prerequisiteRefresh,
    onPrerequisitesHideChange,
  ] = useFindRequestQueryParams((options: findOptions) =>
    api.getPrerequisites(+id, options), 'coursePrerequisites', undefined, true
  );

  async function loadCourse() {
    try {
      setLoading(true);
      let { data: course } = await api.findOne(+id);
      parseCourse(course);
      try {
        let { data: programs } = await api.getPrograms(+id);
        setPrograms(programs);
      } catch {
        // could not get course programs
      }
      try {
        let {
          data: { data },
        } = await programsApi.getAll();
        setProgramOptions(toOptions(data, ["translations"]));
      } catch {
        // could not get all programs
      }

      try {
        let {
          data: { data: courses },
        } = await api.find();
        setCourses(courses);
      } catch {
        // could not get all courses
      }

      setLoading(false);
    } catch {
      history.push("/");
    }
  }

  function parseCourse(course: any) {
    for (let translation of course.translations) {
      course[`${translation.language}_name`] = translation.name;
      course[`${translation.language}_desc`] = translation.description;
    }
    setCourse(course);
    setCourseOfferings(course.courseOfferings);
  }

  useEffect(() => {
    (async () => {
      await loadCourse();
    })();
  }, []);

  const refresh = loadCourse;
  return (
    <React.Fragment>
      <Head title="Course" path={`/course/${id}`} />
      <SingleComponent
        title="Course Info"
        fields={[
          {
            name: "Code",
            value: course.code,
          },
          {
            name: "Name in English",
            value: course.en_name,
          },
          {
            name: "Description in English",
            value: course.en_desc,
            displayLineBreaks: true,
            edit: {
              form: {
                title: "Edit english description",
                inputs: [
                  {
                    type: "text",
                    name: "English Description",
                    keyName: "en_desc",
                    required: true,
                    multiline: true,
                    defaultValue: course.en_desc,
                  },
                ],
                apiRequest: async (data: any) => {
                  return await api.update(+id, {
                    id: +id,
                    department_id: course.department_id,
                    code: course.code,
                    en_name: course.en_name,
                    ...data,
                  });
                },
                callback: () => refresh(),
                successMessage: (response: any) => response,
                errorMessage: extractErrorText,
              },
              editPermissions: [PermissionsEnum.ModifyCourses],
            },
          },
          {
            name: "Name in Arabic",
            value: course.ar_name,
          },
          {
            name: "Description in Arabic",
            value: course.ar_desc,
            displayLineBreaks: true,
            edit: {
              form: {
                title: "Edit arabic description",
                inputs: [
                  {
                    type: "text",
                    name: "Arabic Description",
                    keyName: "ar_desc",
                    required: true,
                    multiline: true,
                    defaultValue: course.ar_desc,
                  },
                ],
                apiRequest: async (data: any) => {
                  return await api.update(+id, {
                    id: +id,
                    department_id: course.department_id,
                    code: course.code,
                    ar_name: course.ar_name,
                    ...data,
                  });
                },
                callback: () => refresh(),
                successMessage: (response: any) => response,
                errorMessage: extractErrorText,
              },
              editPermissions: [PermissionsEnum.ModifyCourses],
            },
          },
          {
            name: "Default Price",
            value: course.default_price,
          },
          {
            name: "Credit Hours",
            value: course.creditHours,
          },
        ]}
      />

      <div className="buttons-section">
        <ComponentGuard
          requiredPermissions={[PermissionsEnum.AddCoursePreRequisites]}
        >
          <Button
            color="primary"
            label="Create Prerequisite"
            withForm
            form={{
              title: "Create Prerequisite",
              description:
                "Enter the details of the prerequisite you would like to create.",
              apiRequest: async (data: any) => {
                data.id = +id;
                return await apiPre.add(data);
              },
              inputs: [
                {
                  type: "select",
                  name: "Course",
                  keyName: "courseId",
                  required: true,
                  options: ((courses) => {
                    let courseOptions = [];
                    for (let course of courses) {
                      courseOptions.push({
                        label: course.translations.find(
                          (translation: any) => translation.language === "en"
                        )?.name,
                        value: course.id,
                      });
                    }
                    return courseOptions;
                  })(courses),
                },
              ],
              successMessage: () => {
                prerequisiteRefresh();
                return "Prerequisite added successfully.";
              },
              errorMessage: handleResponseError,
            }}
          />
        </ComponentGuard>
        <ComponentGuard
          requiredPermissions={[PermissionsEnum.OpenCourseForProgram]}
        >
          <Button
            color="primary"
            label="Open for a program"
            withForm
            form={{
              title: "Open course for a program",
              description:
                "Choose the program you would like to open this course for",
              apiRequest: async (data: any) =>
                await api.openForProgram(+id, data),
              inputs: [
                {
                  type: "multiSelect",
                  name: "Programs",
                  keyName: "ids",
                  required: true,
                  options: programOptions,
                },
                {
                  type: 'checkbox',
                  required: false,
                  name: 'Is Under Graduate',
                  keyName: 'isUnderGraduate',
                }
              ],
              successMessage: () => {
                refresh();
                return "Course opened for program(s) successfully.";
              },
              errorMessage: handleResponseError,
            }}
          />
        </ComponentGuard>
        <ComponentGuard
          requiredPermissions={[PermissionsEnum.EditDefaultCoursePrice]}
        >
          <Button
            color="primary"
            label="Change Default Price"
            withForm
            form={{
              title: "Set default price",
              description: "Set the new default price for the course",
              apiRequest: async (data: any) =>
                await api.setDefaultPrice(+id, data),
              inputs: [
                {
                  type: "number",
                  name: "Price",
                  keyName: "price",
                  required: true,
                },
              ],
              successMessage: () => {
                refresh();
                return "Default course price changed successfully.";
              },
              errorMessage: handleResponseError,
            }}
          />
        </ComponentGuard>
        <ComponentGuard
          requiredPermissions={[PermissionsEnum.StartNewCourseOffering]}
        >
          <Button
            color="primary"
            label="Start New Course Offering"
            withForm
            form={{
              title: "Start a new course offering",
              description: "Enter the details for the new course offering",
              apiRequest: async (data: any) => await api.newOffering(+id, data),
              inputs: [
                {
                  type: "select",
                  name: "Semester",
                  keyName: "semester",
                  required: true,
                  options: Object.values(SemesterEnum).map((semester) => {
                    return {
                      label: semester,
                      value: semester,
                    };
                  }),
                },
                {
                  type: "number",
                  name: "Year",
                  keyName: "year",
                  required: true,
                },
                {
                  type: "select",
                  name: "Pricing Staregy",
                  keyName: "strategy",
                  required: true,
                  options: Object.values(PricingStrategyEnum).map(
                    (strategy) => {
                      return {
                        label: strategy,
                        value: strategy,
                      };
                    }
                  ),
                },
                {
                  type: "number",
                  name: "Capacity",
                  keyName: "capacity",
                  required: true,
                },
                {
                  type: "checkbox",
                  name: "Is Pass or Fail",
                  keyName: "isPassOrFail",
                  required: false,
                },
                {
                  type: "dateTime",
                  name: "Start Date",
                  keyName: "startDate",
                  required: false,
                },
                {
                  type: "dateTime",
                  name: "End Date",
                  keyName: "endDate",
                  required: false,
                },
              ],
              successMessage: () => {
                refresh();
                return "New Course offering added successfully.";
              },
              errorMessage: handleResponseError,
            }}
          />
        </ComponentGuard>
      </div>
      <ComponentGuard requiredPermissions={[PermissionsEnum.ViewPrerequisites]}>
        <CollectionComponent
          title="Prerequisites"
          loading={prerequisiteLoading}
          data={prerequisites}
          columns={[
            {
              title: "Name",
              field: "name",
            },
            {
              title: "Prerequisites",
              field: "prerequisiteCourses",
              editable: "never",
              render: (data) => {
                let displayString: string = "";
                for (let prerequisiteCourse of data.prerequisiteCourses) {
                  displayString =
                    displayString + prerequisiteCourse.code + " or ";
                }
                return displayString.substring(0, displayString.length - 4);
              },
            },
          ]}
          backendPagination
          noDelete
          onRowUpdate={async (input: any, oldData: any) => {
            await apiPre.update(oldData.id, input);
            refresh();
          }}
          viewDisabledInSeparateTab
          onHideChange={onPrerequisitesHideChange}
          // viewDisabledPermission={PermissionsEnum.ViewPrerequisites}
          disableKey="archived"
          pagesCount={pagesCount}
          page={page}
          pageSize={pageSize}
          searchText={search}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSearchChange={onSearchChange}
          actions={[
            {
              icon: "undo",
              tooltip: "Restore prerequisite",
              onClick: async (_e: any, data: any) => {
                if (!data.archived) {
                  alert("Prerequisite not deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to restore this prerequisite?"
                );
                if (!confirmed) return;
                await apiPre.restore(data.id);
                prerequisiteRefresh();
              },
              requiredPermission: PermissionsEnum.RestoreCoursePreRequisites,
            },
            {
              icon: () => <DeleteIcon />,
              tooltip: "Delete prerequisite",
              onClick: async (_e: any, data: any) => {
                if (data.archived) {
                  alert("Prerequisite already deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to delete this prerequisite?"
                );
                if (!confirmed) return;
                await apiPre.remove(data.id);
                prerequisiteRefresh();
              },
              requiredPermission: PermissionsEnum.DeleteCoursePreRequisites,
            },
            {
              icon: OpenInNewIcon,
              tooltip: "View Prerequisite",
              requiredPermission: PermissionsEnum.ViewPrerequisiteDetails,
              onClick: (_e: any, data: any) => {
                history.push(`/prerequisite/${data.id}`);
              },
            },
          ]}
          mainPermission={PermissionsEnum.ModifyPrerequisites}
        />
      </ComponentGuard>
      <ComponentGuard
        requiredPermissions={[PermissionsEnum.ViewCourseOfferings]}
      >
        <CollectionComponent
          title="Course Offerings"
          loading={loading}
          data={courseOfferings}
          columns={[
            {
              title: "Semester",
              field: "semester.semester",
            },
            {
              title: "Year",
              field: "semester.year",
            },
            {
              title: t("REGISTRATION_OPEN_UNTIL"),
              render: RenderDate(["registrationOpenUntil"])
            }
          ]}
          // backendPagination
          noEdit
          noDelete
          actions={[
            {
              icon: OpenInNewIcon,
              onClick: (_: any, data: any) => {
                history.push(`/course-offering/${data.id}`);
              },
            },
          ]}
        />
      </ComponentGuard>
      <ComponentGuard
        requiredPermissions={[PermissionsEnum.ViewCoursePrograms]}
      >
        <CollectionComponent
          title="Programs"
          loading={loading}
          data={programs}
          columns={[
            {
              title: "Name",
              field: "programName",
            },
            {
              title: "Concentration",
              field: "concentrationName",
            },
            {
              title: "Core",
              field: "optional",
              render: (row) => (row.optional === "Yes" ? "No" : "Yes"),
            },
          ]}
          backendPagination
          noEdit
          noDelete
        />
      </ComponentGuard>
    </React.Fragment>
  );
};

export default Course;
