import { Dispatch } from 'react';
import * as types from '../types/settings.types';

export const changeLanguage = (language: string) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: types.CHANGE_LANGUAGE,
    payload: {
      language
    }
  })
}
