import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { decodeReferralForm, submitReferralForm } from "../api/referralSubmissions.api";
import Form from "../components/forms/Form";
import extractErrorText from "../util/functions/extractErrorText";
import { toInput } from "./ApplicationForm";
import { QuestionType } from "./ProgramForm";

interface Question {
  customControlName: string;
  deleted: boolean;
  minimumNumber?: number;
  maximumNumber?: number;
  question: string;
  required: boolean;
  type: QuestionType;
}

interface Form {
  questions: Question[];
  title: string;
  description: string;
}

interface ReferrerForm {
  form: Form;
  referrerName: string;
  referrerEmail: string;
}

const FillReferralForm: React.FunctionComponent<{ children?: never }> = () => {
  const { token } = useParams<{ token: string }>();
  const [referrerForm, setReferrerForm] = useState<ReferrerForm>();
  const [questionInputs, setQuestionInputs] = useState<any[]>([]);
  const history = useHistory();
  const { error, isFetching }: { error: AxiosError | null, isFetching: boolean } = useQuery(
    ["fillReferralForm", token],
    () => decodeReferralForm(token) as Promise<AxiosResponse<ReferrerForm>>,
    {
      retry: false,
      onSuccess(response) {
        setReferrerForm(response.data);
        setQuestionInputs(toInput(response.data.form.questions))
      }
    }
  );

  if (error) return <h1 style={{ color: 'red' }}>{error.response?.data || error.message || "Something went wrong.."}</h1>;
  if (isFetching) return <></>;

  return (
    <>
      <Form
        inputs={questionInputs}
        title={referrerForm?.form.title}
        description={referrerForm?.form.description}
        dynamicForm
        apiRequest={(data: any) => submitReferralForm(token, data)}
        successMessage={() => "Referral submission submitted successfully. Feel free to close this tab."}
        errorMessage={extractErrorText}
        callback={() => history.push('/success')}
      />
    </>
  );
}

export default FillReferralForm;