import axios from "axios";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import { generateRoutes } from "./base.api";
import findOptions from "./interfaces/findOptions.interface";

const routes = generateRoutes("/departments");

export default {
  find: routes.find,
  add: routes.add,
  findOne: (id: number) => axios.get(`/departments/${id}`),
  addCourse: (id: number, data: any) =>
    axios.patch(`/departments/add-course/${id}`, data),
  restore: routes.restore,
  update: routes.update,
  remove: routes.remove,
  getCourses: (id: number, options: findOptions) => axios.get(`/departments/${id}/courses?${JSONToQueryParams(options)}`)
};
