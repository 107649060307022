/**
 * A functional component that holds the app router
 */
import { Redirect, Route, Switch } from "react-router-dom";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
import AuthRoute from "./components/guards/AuthRoute";
import Users from "./pages/users/Users";
import User from "./pages/users/User";
import Registration from "./pages/Registration";
import TestUpload from "./pages/TestUpload";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import PermissionsRoute from "./components/guards/PermissionsRoute";
import { PermissionsEnum } from "./util/enum/permissions.enum";
import Signup from "./components/Signup";
import Programs from "./pages/Programs";
import AvailablePrograms from "./pages/AvailablePrograms";
import Campuses from "./pages/Campuses";
import SingleCampus from "./pages/SingleCampus";
import Departments from "./pages/Departments";
import Department from "./pages/Department";
import Building from "./pages/Building";
import Room from "./pages/Room";
import Course from "./pages/Course";
import Program from "./pages/Program";
import Concentration from "./pages/Concentration";
import CourseRegistration from "./pages/CourseRegistration";
import Students from "./pages/users/Students";
import CourseOffering from "./pages/CourseOffering";
import TimeSlots from "./pages/TimeSlots";
import Prerequisite from "./pages/Prerequisite";
import Student from "./pages/Student";
import Enrollment from "./pages/Enrollment";
import PendingConcentrationChoices from "./pages/PendingConcentrationChoices";
import ProgramOffering from "./pages/ProgramOffering";
import ConcentrationOffering from "./pages/ConcentrationOffering";
import PendingProgramChoices from "./pages/PendingProgramChoices";
import GradingSchemas from "./pages/GradingSchemas";
import GradingSchema from "./pages/GradingSchema";
import ProgramForm from "./pages/ProgramForm";
import StudentPayments from "./pages/StudentPayments";
import ApplicationForm from "./pages/ApplicationForm";
import StudentsWithOutstandingPayments from "./pages/users/StudentsWithOutstandingPayments";
import RoleDatabase from "./pages/RoleDatabase";
import FormSections from "./pages/FormSections";
import FormSection from "./pages/FormSection";
import Semesters from "./pages/semesters";
import AdmissionRequirements from "./pages/admissionRequirements";
import Videos from "./pages/videos";
import AdmissionRequirement from "./pages/admissionRequirement";
import FAQDatabase from "./pages/FaqDatabase";
import FAQGroup from './pages/faqGroup';
import ApplicationProcesses from "./pages/ApplicationProcesses";
import ApplicationProcess from "./pages/ApplicationProcess";
import Application from "./pages/Application";
import FinancialAidData from "./pages/FinancialAidData";
import ReferralForms from "./pages/ReferralForms";
import ReferralForm from "./pages/ReferralForm";
import FillReferralForm from "./pages/FillReferralForm";
import ThankYou from "./pages/ThankYou";
import ProgramChoice from "./pages/ProgramChoice";
import ApplicationReferrals from "./pages/ApplicationReferrals";
import UnofficialTranscript from "./pages/Transcript";
import Home from "./pages/Home";
import GuardedRoute from "./components/guards/GuardedRoute";
import OfficialTranscript from "./pages/OfficialTranscript";
import SchoolInfo from "./pages/schoolInfo";
import { connect } from "react-redux";
import Faculty from "./pages/Faculty";
import StudentFinancialData from "./pages/StudentFinancialData";
// import Home from "./pages/Home";

const AppRouter = (props: any) => {
  return (
    <Switch>
      <AuthRoute
        path="/course/:id"
        component={<Course />}
      // requiredPermissions={[PermissionsEnum.ViewCourses]}
      />
      <PermissionsRoute
        path="/campuses"
        component={<Campuses />}
        requiredPermissions={[PermissionsEnum.ViewCampuses]}
      />
      <PermissionsRoute
        path="/campus/:id"
        component={<SingleCampus />}
        requiredPermissions={[PermissionsEnum.ViewCampuses]}
      />
      <PermissionsRoute
        path="/building/:id"
        component={<Building />}
        requiredPermissions={[PermissionsEnum.ViewBuildings]}
      />
      <PermissionsRoute
        path="/room/:id"
        component={<Room />}
        requiredPermissions={[PermissionsEnum.ViewRooms]}
      />

      <PermissionsRoute
        path="/program-choices/:id"
        component={<ProgramChoice />}
        requiredPermissions={[PermissionsEnum.ViewEnrollmentProgramChoiceDetails]}
      />

      <Route path="/settings" component={Settings} />
      <Route path="/fill-referral-form/:token" component={FillReferralForm} />
      <Route path="/success" component={ThankYou} />
      <Route path="/invitation" component={Registration} />
      <Route path="/testupload" component={TestUpload} />
      <Route path="/forgetpassword" component={ForgetPassword} />
      <Route path="/signup" component={Signup} />
      <Route path="/resetpassword" component={ResetPassword} />
      <PermissionsRoute requiredPermissions={[PermissionsEnum.GenerateEnrollmentTranscript]} path="/home/:transcripts" component={<Home />} />
			<AuthRoute path="/home" component={<Home />} />
      <AuthRoute path="/available-programs" component={<AvailablePrograms />} />
      <AuthRoute path="/unofficial-transcript/:id" component={<UnofficialTranscript />} />
      <PermissionsRoute requiredPermissions={[PermissionsEnum.GenerateEnrollmentTranscript]} path="/official-transcript/:id" component={<OfficialTranscript />} />
      {/* <AuthRoute path="/registration" component={<Home />} /> */}
      <AuthRoute path='/applications/applicationForm/:id' component={<ApplicationForm />} />
      <PermissionsRoute requiredPermissions={[PermissionsEnum.ViewReferralForms]} path="/referral-forms" component={<ReferralForms />} />
      <PermissionsRoute requiredPermissions={[PermissionsEnum.ViewReferralForms]} path="/referral-form/:id" component={<ReferralForm />} />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewDepartments]}
        path="/departments"
        component={<Departments />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewApplicationProcesses]}
        path="/application-processes"
        component={<ApplicationProcesses />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewApplicationReferralSubmissions]}
        path="/application/:applicationId/referrals"
        component={<ApplicationReferrals />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewStudentApplication]}
        path="/application/:applicationId"
        component={<Application />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewApplicationProcesses]}
        path="/application-process/:id"
        component={<ApplicationProcess />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewDepartments]}
        path="/department/:id"
        component={<Department />}
      />
      <AuthRoute
        path="/course-registration"
        component={<CourseRegistration />}
      />
      <AuthRoute path="/course-offering/:id" component={<CourseOffering />} />
      <PermissionsRoute
        path="/users"
        component={<Users />}
        requiredPermissions={[PermissionsEnum.ViewEmployees]}
      />
      <PermissionsRoute
        path="/programs/offerings/:id"
        component={<ProgramOffering />}
        requiredPermissions={[PermissionsEnum.ViewProgramOffering]}
      />
      <PermissionsRoute
        path="/programs"
        component={<Programs />}
        requiredPermissions={[PermissionsEnum.ViewPrograms]}
      />
      <PermissionsRoute
        path="/program/:id/form"
        component={<ProgramForm />}
        requiredPermissions={[PermissionsEnum.ViewProgramAdditonalForm]}
      />
      <AuthRoute
        path="/program/:id"
        component={<Program />}
      // requiredPermissions={[PermissionsEnum.ViewPrograms]}
      />
      <PermissionsRoute
        path="/grading-schemas"
        component={<GradingSchemas />}
        requiredPermissions={[PermissionsEnum.ViewGradingSchemas]}
      />
      <PermissionsRoute
        path="/grading-schema/:id"
        component={<GradingSchema />}
        requiredPermissions={[PermissionsEnum.ViewGradingSchemas]}
      />
      <PermissionsRoute
        path="/concentration/:id"
        component={<Concentration />}
        requiredPermissions={[PermissionsEnum.ViewConcentration]}
      />
      <PermissionsRoute
        path="/concentrations/offerings/:id"
        component={<ConcentrationOffering />}
        requiredPermissions={[PermissionsEnum.ViewConcentrationOffering]}
      />
      {/* <AuthRoute
        path="/programs-current"
        component={<CurrentPrograms />}
      /> */}
      {/* <PermissionsRoute
        path="/instructors"
        component={<Instructors />}
        requiredPermissions={[PermissionsEnum.ViewInstructors]}
      /> */}
      <PermissionsRoute
        path="/students/outstanding-payments"
        component={<StudentsWithOutstandingPayments />}
        requiredPermissions={[
          PermissionsEnum.ViewStudentsWithOutstandingPayments,
        ]}
      />
      <PermissionsRoute
        path='/student/:id/financialAid'
        component={<FinancialAidData />}
        requiredPermissions={[PermissionsEnum.ViewStudentApplicationArabicFinanceSection, PermissionsEnum.ViewStudentApplicationEnglishFinanceSection]}
      />
	  <PermissionsRoute
	  	path="/student/:id/financial-data"
		component={<StudentFinancialData />}
        requiredPermissions={[PermissionsEnum.ViewStudentFinancialData]}
	  />
      <PermissionsRoute
        path="/students/:id"
        component={<Student />}
        requiredPermissions={[PermissionsEnum.ViewStudentDetails]}
      />

      <PermissionsRoute
        path="/students"
        component={<Students />}
        requiredPermissions={[PermissionsEnum.ViewStudents]}
      />

      <PermissionsRoute
        path="/user/:id"
        component={<User />}
        requiredPermissions={[PermissionsEnum.ViewEmployees]}
      />
      <PermissionsRoute
        path="/roles"
        component={<RoleDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRoles]}
      />
      <PermissionsRoute
        path="/courseOfferingBasedRoles"
        component={<RoleDatabase />}
        requiredPermissions={[PermissionsEnum.ViewCourseOfferingBasedRoles]}
      />
      <PermissionsRoute
        path="/pending-concentration-choices"
        component={<PendingConcentrationChoices />}
        requiredPermissions={[PermissionsEnum.ViewPendingConcentrationChoices]}
      />
      <PermissionsRoute
        path="/pending-program-choices"
        component={<PendingProgramChoices />}
        requiredPermissions={[PermissionsEnum.ViewPendingProgramChoices]}
      />
      <PermissionsRoute
        path="/pending-program-choices"
        component={<PendingProgramChoices />}
        requiredPermissions={[PermissionsEnum.ViewPendingProgramChoices]}
      />
      <AuthRoute path="/profile" component={<Profile />} />

      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewTimeSlots]}
        path="/time-slots"
        component={<TimeSlots />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewPrerequisiteDetails]}
        path="/prerequisite/:id"
        component={<Prerequisite />}
      />
      <AuthRoute
        path="/enrollments/:id"
        component={<Enrollment />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewTimeSlots]}
        path="/time-slots"
        component={<TimeSlots />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewPrerequisiteDetails]}
        path="/prerequisite/:id"
        component={<Prerequisite />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewStudentPayments]}
        path="/student-payments"
        component={<StudentPayments />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewFormSections]}
        path='/form-sections'
        component={<FormSections />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewFormSections]}
        path='/form-section/:id'
        component={<FormSection />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewSemesters]}
        path='/semesters'
        component={<Semesters />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewFAQs]}
        path='/faqs'
        component={<FAQDatabase />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewFAQGroups]}
        path='/faqGroups'
        component={<FAQDatabase />}
      />
      <AuthRoute
        // requiredPermissions={[PermissionsEnum.ViewFAQGroup]}
        path='/faqGroup/:id'
        component={<FAQGroup />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewAdmissionRequirements]}
        path='/admissionRequirements'
        component={<AdmissionRequirements />}
      />
      <AuthRoute
        // requiredPermissions={[PermissionsEnum.ViewAdmissionRequirements]}
        path='/admissionRequirement/:id'
        component={<AdmissionRequirement />}
      />
      <PermissionsRoute
        requiredPermissions={[PermissionsEnum.ViewVideos]}
        path='/videos'
        component={<Videos />}
      />
      <PermissionsRoute requiredPermissions={[PermissionsEnum.ViewSchoolInfo]} path="/schoolInfo" component={<SchoolInfo />} />
      <GuardedRoute
        condition={() => props.user?.facultyProfile}
        path='/faculty'
        component={<Faculty />}
      />
      {/* <PermissionsRoute
        path="/recipes/:id"
        component={<Recipe />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      /> */}
      {/* <PermissionsRoute
        path="/recipes"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      /> */}
      {/* <PermissionsRoute
        path="/ingredients"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      />
      <PermissionsRoute
        path="/allergies"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      />
      <PermissionsRoute
        path="/medical-conditions"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      />
      <PermissionsRoute
        path="/diets"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      />
      <PermissionsRoute
        path="/ingredient-categories"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      />
      <PermissionsRoute
        path="/courses"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      />
      <PermissionsRoute
        path="/measuring-units"
        component={<RecipesDatabase />}
        requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
      />
      <PermissionsRoute
        path="/new/recipe"
        component={<NewRecipe />}
        requiredPermissions={[PermissionsEnum.ModifyRecipeDatabase]}
      /> */}
      <Redirect to="/home" />
    </Switch>
  );
};

const mapStateToProps = (store: any) => {
  return {
    user: store.UserReducer.user,
  };
};

export default connect(mapStateToProps)(AppRouter);
