import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import api from "../api/applicationProcesses.api";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router";
import programsApi from "../api/programs.api";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import Head from '../components/Head';

const AvailablePrograms = (props: any) => {
  let [
    data,
    ,
    loading,
  ] = useFindRequestQueryParams(api.canApply, "availablePrograms");

  let [
    programsData,
    programsPagesCount,
    programsLoading,
    programsPage,
    programsPageSize,
    programsSearch,
    programsOnPageChange,
    programsOnPageSizeChange,
    programsOnSearchChange,
  ] = useFindRequestQueryParams(programsApi.getActive, 'activePrograms', 'programs');
  useEffect(() => {
    programsData.map((program: any) => {
      let isApplicationWindowOpen = data?.find((applicationProcess: any) => applicationProcess.programOffering?.program_id === program?.id);
      if (isApplicationWindowOpen) program.applicationProcess = isApplicationWindowOpen;
      return program
    })
  }, [data, programsData]);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Head title={t("AVAILABLE_PROGRAMS")} path='/home'></Head>
      <CollectionComponent
        title={t("AVAILABLE_PROGRAMS")}
        loading={programsLoading && loading}
        data={programsData}
        columns={[
          {
            title: t("PROGRAM"),
            field: "name",
            render: (row) => row?.translations?.find((t: any) => t.language === props.language)?.name || row?.code || '-',
            customFilterAndSearch: () => true,
          },
          // {
          //   title: t("SEMESTER"),
          //   field: "semester",
          //   render: (row) => {
          //     if (row?.semester)
          //       return `${row.semester.semester} ${row.semester.year}`
          //     else return '-';
          //   },
          //   customFilterAndSearch: () => true,
          // },
          {
            title: t("APPLICATION_START_DATE"),
            field: "startDate",
            render: RenderDate(["applicationProcess", "startDate"]),
          },
          {
            title: t("APPLICATION_END_DATE"),
            field: "endDate",
            render: RenderDate(["applicationProcess", "endDate"]),
          },
        ]}
        actions={[
          {
            onClick: (_: any, row: any) => history.push(`/program/${row.id}`),
            icon: () => <OpenInNew />,
            tooltip: t("OPEN"),
          },
          {
            condition: (data: any) => data?.applicationProcess,
            onClick: (_: any, row: any) => history.push(`/applications/applicationForm/${row.applicationProcess.id}`),
            icon: () => <RequestPageIcon />,
            tooltip: t("APPLY"),
          },
        ]}
        viewOnly
        backendPagination
        backendSearch
        pagesCount={programsPagesCount}
        page={programsPage}
        pageSize={programsPageSize}
        onPageChange={programsOnPageChange}
        onPageSizeChange={programsOnPageSizeChange}
        searchText={programsSearch}
        onSearchChange={programsOnSearchChange}
        itemName="application process"
        disableKey="archived"
      />
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(AvailablePrograms);
