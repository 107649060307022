import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import "../../../styles/Form.scss";

const SectionDescription: React.FunctionComponent<{ text?: string }> = (props) => {
  if (props.text) return (
    <React.Fragment>
      <InputLabel className="section-description" id="simple-section-description-label">{props.text}</InputLabel>
      <br />
    </React.Fragment>
  );
  else return <></>;
};

export default SectionDescription;
