import axios from "axios";
import { generateRoutes } from "./base.api";

const generatedRoutes = generateRoutes("/expenses");
export default {
    archive: generatedRoutes.remove,
    restore: generatedRoutes.restore,
    update: generatedRoutes.update,
    applyScholarshipToExpense: (id: number) => axios.patch(`/expenses/${id}/applyScholarship`),
    removeScholarshipForExpense: (id: number) => axios.patch(`/expenses/${id}/disregardScholarship`),
}
