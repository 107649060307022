import { AxiosResponse } from "axios";
import { ExportFormat, exportFormats } from "../api/base.api";
import Form from "./forms/Form";
import contentDisposition from "content-disposition";

type ExportFormatOptions = {
	[key in ExportFormat]: string;
}

const exportFormatOptions: ExportFormatOptions = {
	pdf: "PDF",
	csv: "CSV",
	docx: "DOCX"
};

function formatsToSelectOptions(formats: Readonly<ExportFormat[]>) {
	return formats.map(function (format) {
		return {
			label: exportFormatOptions[format],
			value: format
		}
	})
}

interface ExportModalProps {
	formats?: ExportFormat[];
	exportFn: (data: any) => AxiosResponse | Promise<AxiosResponse>,
	formTitle?: string;
	modalOpen: boolean;
	onClose: () => void;
}

export function ExportModal(props: ExportModalProps) {
	return <Form
		inputs={[
			{
				name: "Export as",
				keyName: "format",
				required: true,
				type: "select",
				options: formatsToSelectOptions(props.formats || exportFormats),
			},
		]}
		apiRequest={async (data: any) => {
			try {
				const response = await props.exportFn(data);

				let blob: Blob = response.data;
				let url = window.URL.createObjectURL(blob);
				let link = document.createElement("a");
				link.href = url;
				link.target = "_blank";

				link.download = contentDisposition.parse(
					response.headers["content-disposition"]
				).parameters["filename"];
				link.click();
				window.URL.revokeObjectURL(url);
			} catch (error: any) {
				if (error.response) {
					if (error.response.data instanceof Blob) {
						throw new Error(await error.response.data.text());
					} else {
						throw new Error(error.response.data);
					}
				}
				throw error;
			}
		}}
		title={props.formTitle || "Export table"}
		withModal
		modalOpen={props.modalOpen}
		onModalClose={props.onClose}
	/>
}