import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../../styles/SingleComponent.scss';
import Button from '../Button';
import { FormProps } from '../forms/Form';
import EditIcon from '@mui/icons-material/Edit';
import ComponentGuard from '../guards/ComponentGuard';
import { PermissionsEnum } from '../../util/enum/permissions.enum';
import Guard from '../guards/Guard';

const SingleComponent: React.FunctionComponent<{
  title: string;
  fields: {
    name: string;
    value: any;
    permissions?: PermissionsEnum[];
    displayLineBreaks?: boolean;
    fontFamily?: string;
    fontSize?: string | number;
    edit?: {
      form: FormProps;
      editPermissions?: PermissionsEnum[];
    };
    actions?: {
      onClick: () => void;
      icon: JSX.Element;
      permissions?: PermissionsEnum[];
      condition?: boolean | (() => boolean);
      tooltip?: string;
    }[];
  }[];
}> = (props) => {
  return (
    <React.Fragment>
      <div className="single-component">
        <TableContainer className="information-table" component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className="table-heading" align="center" colSpan={3}>
                  {props.title || 'Information'}
                </TableCell>
              </TableRow>
              {props.fields.map((field, i) => (
                <ComponentGuard requiredPermissions={field.permissions || []} key={i}>
                  <TableRow key={i}>
                    <TableCell>
                      <b>{field.name}</b>
                    </TableCell>
                    <TableCell className="information-cell" style={{
                      ...(field.fontSize ? { fontSize: field.fontSize } : {}),
                      ...(field.fontFamily ? { fontFamily: field.fontFamily } : {}),
                      ...(field.displayLineBreaks ? { whiteSpace: 'pre-line' } : {})
                    }}>{
                        field.value || ((field.value === 0) ? 0 : '-')
                      }</TableCell>
                    <TableCell size="small" align="right">
                      {field.edit ? (<ComponentGuard requiredPermissions={field.edit.editPermissions || []}><Button label={<EditIcon></EditIcon>} size='small' variant='outlined' color={'primary'} withForm form={field.edit.form}
                      /></ComponentGuard>) : null}
                      {(field.actions || []).map((action, actionIndex) => (
                        <ComponentGuard
                          requiredPermissions={action.permissions || []}
                          key={actionIndex}
                        >
                          <Guard
                            condition={
                              typeof action.condition === "function"
                                ? action.condition()
                                : action.condition
                            }
                          >
                            <Button
                              label={action.icon}
                              size="small"
                              variant="outlined"
                              color="primary"
                              handleClick={action.onClick}
                              tooltip={action.tooltip}
                            />
                          </Guard>
                        </ComponentGuard>
                      ))}
                    </TableCell>
                  </TableRow>
                </ComponentGuard>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export default SingleComponent;