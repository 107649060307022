import React from "react";
import Guard from "./Guard";
import { connect } from "react-redux";
import { PermissionsEnum } from "../../util/enum/permissions.enum";

export const CheckPermissions: (
  userPermissions: PermissionsEnum[],
  requiredPermissions: PermissionsEnum[]
) => boolean = (
  userPermissions: PermissionsEnum[],
  requiredPermissions: PermissionsEnum[]
) => {
    if (requiredPermissions.length === 0) return true;
    let found: boolean = false;
    userPermissions?.forEach((userPermission) => {
      if (requiredPermissions.includes(userPermission)) {
        found = true;
      }
    });
    return found;
  };

const ComponentGuard: React.FunctionComponent<
  React.PropsWithChildren<{
    isLoggedIn: boolean;
    permissions: PermissionsEnum[];
    requiredPermissions: PermissionsEnum[];
  }>
> = (props) => {
  return (
    <Guard
      condition={
        props.isLoggedIn &&
        CheckPermissions(props.permissions, props.requiredPermissions)
      }
      fallback={null}
    >
      {props.children}
    </Guard>
  );
};
const mapStateToProps = (store: any) => {
  return {
    isLoggedIn: store.UserReducer.isLoggedIn,
    permissions: store.UserReducer.permissions,
  };
};

export default connect(mapStateToProps)(ComponentGuard);
