import { generateRoutes } from "./base.api";
import axios from "axios";
import findOptions from "./interfaces/findOptions.interface";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";

const generatedRoutes = generateRoutes("/faqs");
export default {
    archive: generatedRoutes.remove,
    restore: generatedRoutes.restore,
    update: generatedRoutes.update,
    create: generatedRoutes.add,
    find: generatedRoutes.find,
    getAll: () => axios.get('/faqs/all'),
    getAllGroups: () => axios.get('/faqs/groups/all'),
    findGroups: (options: findOptions) => axios.get(`/faqs/groups?${JSONToQueryParams(options)}`),
    createGroup: (data: any) => axios.post(`/faqs/groups`, data),
    updateGroup: (id: number, data: { name: string }) => axios.patch(`/faqs/groups/${id}`, data),
    archiveGroup: (id: number) => axios.delete(`/faqs/groups/${id}`),
    restoreGroup: (id: number) => axios.post(`/faqs/groups/restore/${id}`),
    findGroup: (id: number) => (options: findOptions) => axios.get(`/faqs/group/${id}?${JSONToQueryParams(options)}`),
    findGroupBase: (id: number) => axios.get(`/faqs/group/${id}/base`),
    addFaqToGroup: (id: number, data: any) => axios.post(`/faqs/groups/${id}/faqs`, data),
    removeFaqFromGroup: (id: number, faqId: number) => axios.delete(`/faqs/groups/${id}/faq/${faqId}`),
}
