import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { CheckPermissions } from "./guards/ComponentGuard";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import { connect } from "react-redux";
import useDarkMode from "use-dark-mode";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    key: index,
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NavTab = (props: {
  tabs: { name: string; content: any, permissions?: PermissionsEnum[] }[];
  urls: string[];
  permissions: PermissionsEnum[];
  ignoreUrls?: boolean;
  defaultIndex?: number;
}) => {
  const history = useHistory();
  const darkMode = useDarkMode(false);

  const path = history.location.pathname.split("/").slice(1).join("/");
  const [value, setValue] = React.useState(props.urls.indexOf(path) > -1 ? props.urls.indexOf(path) : (props.defaultIndex || props.defaultIndex === 0) ? props.defaultIndex : 0);
  let { tabs } = props;
  let tabLabels: any = [],
    tabPanels: any = [];
  tabs.forEach((tab, idx) => {
    let hasAccess = CheckPermissions(props.permissions, tab.permissions || [])
    tabLabels.push(<Tab label={tab.name} {...(hasAccess ? {} : { disabled: true })} {...a11yProps(idx)} />);
    tabPanels.push(
      <TabPanel value={value} index={idx} key={idx}>
        {tab.content}
      </TabPanel>
    );
  });

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    if (!props.ignoreUrls) history.push("/" + props.urls[newValue]);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="on"
          style={{ ...(darkMode.value ? { background: '#333333', color: 'white' } : { background: 'white', color: 'black' }), boxShadow: 'none' }}
        >
          {tabLabels}
        </Tabs>
      </AppBar>
      {tabPanels}
    </div>
  );
}
const mapStateToProps = (store: any) => {
  return {
    permissions: store.UserReducer.permissions,
  };
};

export default connect(mapStateToProps)(NavTab)