import { generateRoutes } from "./base.api";
import axios from "axios";

export const getAllRooms = async () => await axios.get('/rooms');


export const routes = {
    ...generateRoutes("/rooms"),
    getAllRooms

}

export default routes;