/**
 * A functional component that is used by pages to modify the html head tags
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';

const Head: React.FunctionComponent<{
  title: string,
  path: string
}> = props => {
  return (
    <Helmet>
      <title>{`${props.title} | ${process.env.REACT_APP_TITLE}`}</title>
      <link rel="canonical" href={`${process.env.REACT_APP_URL}${props.path}`} />
    </Helmet>
  )
}

export default Head;