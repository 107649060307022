import { generateRoutes } from "./base.api";
import axios from "axios";


const getAll = async() => {
    return axios.get('/campuses/');
}


export const routes = {
    ...generateRoutes("/campuses"),

    getAll,
}

export default routes;