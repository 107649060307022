import { combineReducers } from 'redux';

import SettingsReducer from './settings.reducer';
import UserReducer from './user.reducer';
import DrawerReducer from './drawer.reducer';

export default combineReducers({
  SettingsReducer,
  UserReducer,
  DrawerReducer,
});
