import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { LoginAction, LogoutAction } from "../store/actions/user.actions";

const Interceptor: React.FunctionComponent<{ dispatch: Dispatch; user: any }> =
  (props) => {
    //response interceptor to refresh token on receiving token expired error
    const { enqueueSnackbar } = useSnackbar();
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        const originalRequest = error.config;
        const { refreshToken } = props.user;
        if (!error.response) return Promise.reject(error);
        if (
          refreshToken &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          return axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT}/users/auth/refreshToken/`,
              {
                refreshToken: refreshToken,
                id: props.user.user.id,
              }
            )
			.catch(async (error) => {
				await LogoutAction()(props.dispatch);
        enqueueSnackbar('An error occured! Please login again', { variant: 'error' });
        throw error;
			})
            .then(async (res) => {
              if (res?.status === 200) {
                axios.defaults.headers.Authorization = `${res.data.token}`;
                // LoginAction(res.data);
								await LoginAction(res.data)(props.dispatch);
                // console.log("Access token refreshed!");
                originalRequest.headers["Authorization"] = res.data.token;
                return await axios(originalRequest);
              }
            });
        }
        return Promise.reject(error);
      }
    );

    return <React.Fragment></React.Fragment>;
  };

const mapStateToProps = (store: any) => {
  return {
    user: store.UserReducer,
  };
};

export default connect(mapStateToProps)(Interceptor);
