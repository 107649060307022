import axios from "axios";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import findOptions from "./interfaces/findOptions.interface";

export const exportFormats = ["pdf", "csv", "docx"] as const;
export type ExportFormat = typeof exportFormats[number];

export const generateRoutes = (mainPath: string) => {
  const uploadExcel = async (data: any) => {
    let formData: FormData = new FormData();
    for (let [k, v] of Object.entries(data)) {
      formData.append(k, v as any);
    }

    return axios({
      method: "post",
      url: `${mainPath}/create/excel`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const findOne = (id: number) => {
    return axios.get(`${mainPath}/${id}`);
  };

  const find = (options?: findOptions) => {
    return axios.get(`${mainPath}?${JSONToQueryParams(options)}`);
  };

  const update = (id: number, newData: any) => {
    return axios.patch(`${mainPath}/${id}`, newData);
  };

  const add = (details: any) => {
    return axios.post(`${mainPath}/`, details);
  };

  const remove = (id: number) => {
    return axios.delete(`${mainPath}/${id}`);
  };

  const restore = (id: number) => {
    return axios.post(`${mainPath}/restore/${id}`);
  };

  return {
    uploadExcel,
    findOne,
    find,
    update,
    add,
    remove,
    restore,
  };
};
