import React, { useState } from "react";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { useHistory, useParams } from "react-router-dom";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import api from "../api/enrollment.api";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import Guard from "../components/guards/Guard";
import Button from "../components/Button";
import programApi from "../api/programs.api";
import { changeCurrentConcentrationChoice, changeCurrentProgramChoice } from "../api/users.api";
import { useSnackbar } from "notistack";
import Head from "../components/Head";
import { OpenInNew } from "@mui/icons-material";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { EnrollmentEndTypeEnum } from "../util/enum/enrollmentEndType.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import { LanguageEnum } from "../util/enum/language.enum";
import contentDisposition from "content-disposition";


const Enrollment = (props: { userId: number; language: string; }) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [programOfferingOptions, setProgramOfferingOptions] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: loading, data: enrollment, refetch: refresh } = useQuery(["enrollment", +id], async () => (await api.findOne(+id)).data, {
    retry: false,
    onError: (error: any) => {
      enqueueSnackbar(error?.response?.data || 'could not get enrollment details', { variant: 'error' });
      return history.push("/");
    }
  });

  const userOwnsEnrollment = props.userId === enrollment?.studentProfile.userId;

  useQuery('currentProgramOfferings', programApi.getCurrentOfferings, {
    retry: false,
    enabled: userOwnsEnrollment,
    onSuccess: (response) => {
      const offerings = response.data.data.filter((offering: any) => offering.program_id !== enrollment?.currentProgramChoice?.programOffering.program.id);
      setProgramOfferingOptions(
        toOptions(
          offerings,
          (offering: any) => {
            return (
              offering.program.translations.find(
                (t: any) => t.language === props.language
              )?.name || offering.id
            );
          },
          (o: any) => o.id
        )
      );
    }
  });


  function toOptions<T>(
    data: T[],
    label: (element: T) => string,
    value: (element: T) => string
  ) {
    type Option = { label: string; value: string };
    let options: Option[] = [];
    for (let piece of data) {
      let option: Option = {
        label: label(piece),
        value: value(piece),
      };
      options.push(option);
    }
    return options;
  }

  return (
    <React.Fragment>
      <Head path={`/enrollments/${id}`} title="Enrollment" />
      <SingleComponent
        title=""
        fields={[
          {
            name: "Start Semester",
            value: enrollment?.start.semester + " " + enrollment?.start.year,
          },
          {
            name: "End Semester",
            value: enrollment?.end?.endSemester
              ? enrollment.end.endSemester.semester + " " + enrollment.end.endSemester.year
              : "-",
          },
					{
						name: "End date",
						value: enrollment?.end ? enrollment.end.endDate : "-"
					},
					{
						name: "Status",
						value: (() => {
							if (enrollment?.end) {
								if (enrollment.end.endType === EnrollmentEndTypeEnum.G) return "Graduate";
								else if (enrollment.end.endType === EnrollmentEndTypeEnum.D) return "Dropped";
							}
							else return "Enrolled";
						})(),
					},
          {
            name: "Current program choice",
            value: enrollment?.currentProgramChoice
              ? enrollment.currentProgramChoice.programOffering.program.translations.find(
                (translation: any) => {
                  return translation.language === props.language;
                }
              )?.name || (
                <span style={{ color: "red" }}>No translation found</span>
              )
              : "-",
          },
          {
            name: "Current concentration choice",
            value: enrollment?.currentConcentrationChoice
              ? enrollment.currentConcentrationChoice.concentrationOffering.concentration.translations.find(
                (translation: any) => {
                  return translation.language === props.language;
                }
              )?.name || (
                <span style={{ color: "red" }}>No translation found</span>
              )
              : "-",
          },
          {
            name: "Comment",
            value: enrollment?.comment || "-",
            displayLineBreaks: true,
            edit: {
              form: {
                title: "Edit Enrollment Comment",
                inputs: [
                  {
                    type: "text",
                    multiline: true,
                    name: "Comment",
                    keyName: "comment",
                    required: false,
                    defaultValue: enrollment?.comment,
                  },
                ],
                apiRequest: async (dataa: any) => {
                  return await api.addCommentToEnrollment(+id, dataa.comment);
                },
                callback: () => refresh(),
                successMessage: () => 'Enrollment comment added successfully!',
                errorMessage: extractErrorText,
              },
              editPermissions: [PermissionsEnum.AddCommentToEnrollment],
            },
          },
        ]}
      />
      <div className="buttons-section">
        <Guard condition={userOwnsEnrollment}>
          <Button
            label="Change current program"
            color="primary"
            withForm
            form={{
              title: 'Change Program',
              inputs: [
                {
                  name: "Program",
                  keyName: "programOfferingID",
                  required: true,
                  type: "select",
                  options: programOfferingOptions,
                },
              ],
              apiRequest: async ({
                programOfferingID,
              }: {
                programOfferingID: number;
              }) => {
                await changeCurrentProgramChoice(+id, {
                  programOfferingID,
                });
                await refresh();
                enqueueSnackbar("Your program choice is awaiting approval.", { variant: 'success' });
              },
            }}
          />
          <Button
            label="Change current concentration"
            color="primary"
            withForm
            form={{
              title: 'Change Concentration',
              inputs: [
                {
                  name: "Concentration",
                  keyName: "concentrationOfferingID",
                  required: true,
                  type: "select",
                  options:
                    enrollment?.currentProgramChoice?.programOffering.concentrationOfferings
                      .filter((offering: any) => {
                        return (
                          !offering.archived &&
                          offering.concentration.id !==
                            enrollment?.currentConcentrationChoice
                              ?.concentrationOffering.concentration.id
                        );
                      })
                      .map((offering: any) => {
                        return {
                          label:
                            offering.concentration.translations.find(
                              (translation: any) => {
                                return translation.language === props.language;
                              }
                            )?.name || offering.id,
                          value: offering.id,
                        };
                      }) || [],
                },
              ],
              apiRequest: async ({
                concentrationOfferingID,
              }: {
                concentrationOfferingID: number;
              }) => {
                await changeCurrentConcentrationChoice(+id, {
                  concentrationOfferingID,
                });
                await refresh();
                enqueueSnackbar(
                  "Your concentration choice is awaiting approval.", { variant: 'success' }
                );
              },
            }}
          />
        </Guard>
        <ComponentGuard requiredPermissions={[PermissionsEnum.GenerateEnrollmentTranscript]}>
          <Button
            color="primary"
            label="Generate Transcript"
            withForm
            form={{
              inputs: [
                {
                  type: 'select',
                  options: [
                    { label: 'Generate Transcript', value: 'generate' },
                    { label: 'Preview Official Transcript', value: 'previewOfficial' },
                    { label: 'Preview Unofficial Transcript', value: 'unofficial' }
                  ],
                  required: true,
                  name: 'Transcript',
                  keyName: 'transcript'
                }
              ],
              apiRequest: async (data: { transcript: string }) => {
                if (data.transcript === 'generate') {
                  try {
                    const response = await api.generateOfficialTranscript(
                      {
                        enrollmentId: +id,
                        language: LanguageEnum.EN
                      }
                    );
                    let blob: Blob = response.data;
                    let url = window.URL.createObjectURL(blob);
                    let link = document.createElement("a");
                    link.href = url;
                    link.target = "_blank";
                    // console.log(response.headers);
                    link.download = contentDisposition.parse(
                      response.headers["content-disposition"]
                    ).parameters["filename"];
                    link.click();
                  } catch (error: any) {
                    if (error.response) {
                      if (error.response.data instanceof Blob) {
                        throw new Error(await error.response.data.text());
                      } else {
                        throw new Error(error.response.data);
                      }
                    }
                    throw error;
                  }
                }
                else if (data.transcript === 'unofficial') {
                  try {
                    history.push(`/unofficial-transcript/${id}`)
                  } catch (error: any) {
                    enqueueSnackbar(await extractErrorText(error as Err), {
                      variant: "error",
                    });
                  }
                }
                else if (data.transcript === 'previewOfficial') {
                  try {
                    history.push(`/official-transcript/${id}`)
                  } catch (error: any) {
                    enqueueSnackbar(await extractErrorText(error as Err), {
                      variant: "error",
                    });
                  }
                }
              }
            }}
          />
        </ComponentGuard>
        {/* <ComponentGuard
          requiredPermissions={
            userOwnsEnrollment
              ? []
              : [PermissionsEnum.GenerateEnrollmentTranscript]
          }
        >
          <Button
            color="primary"
            label="Preview transcript"
            handleClick={() => {
              history.push(`/unofficial-transcript/${id}`);
            }}
          />
        </ComponentGuard> */}
      </div>
      <PermissionsGuard
        requiredPermissions={[PermissionsEnum.ViewEnrollmentDetails]}
      >
        <CollectionComponent
          title="Program Choices"
          loading={loading}
          data={enrollment?.programChoices}
          pagesCount={enrollment?.programChoices?.length}
          columns={[
            { title: "Program Code", field: "programCode" },
            {
              title: "Choice Date",
              render: (choice) =>
                (choice.start && (choice.start.semester + " " + choice.start.year)) || 'Not yet reviewed',
            },
            {
              title: "Status",
              render: (choice) => {
                return /**choice.reviewed */choice.approved !== null ? choice.approved ? "Approved" : "Rejected" : "Not yet reviewed";
              },
            },
          ]}
          actions={[
            {
              icon: () => <OpenInNew />,
              onClick(_event, data) {
                history.push(`/program-choices/${data.id}`)
              },
              requiredPermission: PermissionsEnum.ViewEnrollmentProgramChoiceDetails
            },
          ]}
          backendPagination
          viewOnly
        />
      </PermissionsGuard>
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    userId: store.UserReducer.user.id,
    language: store.SettingsReducer.language
  }
}

export default connect(mapStateToProps)(Enrollment);
