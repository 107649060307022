import { CSSProperties, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/programForms.api";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import Guard from "../components/guards/Guard";

const types: string[] = ['text', 'multiText', 'number', 'email', 'decimal', 'select', 'selectAutoComplete', 'multipleSelectAutoComplete', 'multipleSelect', 'file', 'dateTime', 'time', 'checkbox'];

interface Section {
  title: string;
  description?: string;
  questions: Question[];
}
interface Question {
  question: string;
  defaultValue: any;
  type: string;
  deleted: boolean;
}

interface Application {
  application: {
    submissionDate?: Date
  },
  form: {
    _id: string;
    title: string;
    description: string;
    questions: Question[];
    sections: Section[];
  }
}

const styles: { [className: string]: CSSProperties } = {
  error: {
    color: "red",
  },
  questionTitle: {
    fontSize: 20,
  },
  questionAnswer: {
    fontSize: 15,
    color: "grey",
  },
  section: { marginTop: 20, marginBottom: 20 },
  listItem: {
    padding: 0,
  },
  question: {
    borderBottom: "1px solid #80808038",
    paddingBottom: 10,
    marginBottom: 10
  }
};

function isNullish(v: any) {
  return v === undefined || v === null;
}

function renderValue(value: { defaultValue: any, type: string }) {
  try {
    let type = types.find((t) => `${t}Question` === value.type);
    if (isNullish(value.defaultValue) || !type) throw new Error();
    if (type.startsWith("multi")) {
      if (!Array.isArray(value.defaultValue)) throw new Error();
      return (
        <List>
          {value.defaultValue.map((item: string) => {
            return (
              <ListItem style={styles.listItem}>
                <ListItemText disableTypography primary={`- ${item}`} />
              </ListItem>
            );
          })}
        </List>
      );
    } else if (type === "checkbox") {
      return RenderBoolean(["bool"])({ bool: value.defaultValue });
    } else if (type.toLowerCase().endsWith("time")) {
      return RenderDate(["date"])({ date: value.defaultValue });
    }
    else if (type === 'file') {
      return <a href={value.defaultValue} target='_blank'>{value.defaultValue}</a>
    }
    return value.defaultValue;
  } catch (err) {
    return <Typography variant="body1" style={styles.error}>No default value or could not display value</Typography>;
  }
}
function renderQuestion(question: Question) {
  return (<div style={styles.question}>
    <Typography variant="body1" style={styles.questionTitle}>{question.question}</Typography>
    <Typography variant="body1" style={styles.questionAnswer}>{renderValue(question)}</Typography>
  </div>)
}

export function renderQuestions(questions: Question[]) {
  let renderedQuestions = questions.filter(question => !question.deleted).map(question => {
    return renderQuestion(question);
  })
  return renderedQuestions;
}

const Application: React.FunctionComponent<{ children?: never }> = () => {
  let { applicationId } = useParams<{
    applicationId: string;
  }>();
  let [application, setApplication] = useState<Application>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  let [update, setUpdate] = useState(0);


  useEffect(() => {
    (async () => {
      try {
        let submission = await api.adminGetStudentApplication(+applicationId);
        setApplication(submission.data);
      } catch {
        enqueueSnackbar(await extractErrorText({} as Err, 'Could not load application form'), { variant: 'error' });
      }

    })();
  }, [update]);


  return (
    <>
      <div style={{ marginBottom: 50 }}>
        < Typography variant="h3">{application?.form.title}</Typography>
        <Typography variant="subtitle1">{application?.form.description}</Typography>
        <br />
        <Guard condition={!!application}>
          <PermissionsGuard requiredPermissions={[PermissionsEnum.ViewApplicationReferralSubmissions]}>
            <Button
              label="View referral submissions"
              color="primary"
              handleClick={() => {
                history.push(`/application/${applicationId}/referrals`)
              }}
            />
          </PermissionsGuard>
        </Guard>
      </div>
      {renderQuestions(application?.form.questions || [])}

      {/* Sections */}

      {
        (application?.form.sections || []).map((s: Section) => {
          return (
            <>
              <div style={styles.section}>
                <Typography variant="h4">{s.title}</Typography>
                {s.description && <Typography variant="subtitle1">{s.description}</Typography>}
              </div>
              {renderQuestions(s.questions)}
            </>
          )
        })
      }
      <Guard condition={!!application}>
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.SubmitApplicationOnBehalfOfStudent]}
        >
          <br />
          <div style={{ display: 'inline-block' }}>
            <Button
              color="primary"
              label={t("SUBMIT_APPLICATION_ON_BEHALF_OF_STUDENT")}
              disabled={!!application?.application?.submissionDate}
              handleClick={async () => {
                try {
                  await api.forceSubmitApplication(+applicationId);
                  enqueueSnackbar("Application submitted successfully", {
                    variant: "success"
                  });
                  setUpdate(update + 1);
                } catch (error) {
                  enqueueSnackbar(await extractErrorText(error as Err), {
                    variant: "error"
                  })
                }
              }}
            />
          </div>
        </PermissionsGuard>
      </Guard>
    </>
  );
}

export default Application;
