import React from "react";
import { useHistory } from "react-router-dom";
import api from "../api/timeSlots.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import Head from "../components/Head";
import extractErrorText from "../util/functions/extractErrorText";
import { connect } from "react-redux";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { useForm } from "react-hook-form";
import TimeInput from "../components/forms/inputs/TimeInput";


const TimeSlot = (props: any) => {
  const history = useHistory();
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
  ] = useFindRequestQueryParams(api.find, "timeSlots");

  const { control, handleSubmit, errors, reset } = useForm<{
    inputs: [],
  }>();

  return (
    <React.Fragment>
      <Head title="Time Slots" path={`/timeSlots`} />

      <div className="buttons-section">

        <Button
          color="primary"
          label="Add Time Slot"
          withForm
          form={{
            title: "Create a new time slot",
            description:
              "Enter the details of the new time slot you would like to create.",
            apiRequest: async (data: any) => {
              data.start = data.start.getHours() + ":" + data.start.getMinutes();
              data.end = data.end.getHours() + ":" + data.end.getMinutes();
              return await api.add(data);
            },
            inputs: [
              {
                type: "text",
                name: "Name",
                keyName: "name",
                required: true,
              },
              {
                type: "time",
                name: "Start Time",
                keyName: "start",
                required: true,
              },
              {
                type: "time",
                name: "End Time",
                keyName: "end",
                required: true,
              },
            ],
            successMessage: () => {
              refresh();
              return "Time slot created successfully.";
            },
            errorMessage: extractErrorText,
          }}
        />

      </div>
      <br />
      <br />
      <CollectionComponent
        data={data}
        columns={[
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Start Time",
            field: "start",
            render: (data) => data.start.substring(0, 5),
            editComponent: (props: any) => {
              let startDate = new Date();
              startDate.setHours(props.rowData.start.substring(0, 2));
              startDate.setMinutes(props.rowData.start.substring(3, 5));
              return (
                <TimeInput
                  id='tile-slot-start-time'
                  name={'start time'}
                  defaultValue={startDate.toString()}
                  control={control}
                  errors={errors}
                  onChange={(newValue: any) => {
                    props.rowData.start = newValue.getHours() + ":" + newValue.getMinutes();
                  }}
                />
              );
            },
          },
          {
            title: "End Time",
            field: "end",
            render: (data) => data.end.substring(0, 5),
            editComponent: (props: any) => {
              let endDate = new Date();
              endDate.setHours(props.rowData.end.substring(0, 2));
              endDate.setMinutes(props.rowData.end.substring(3, 5));
              return (
                <TimeInput
                  id='time-slot-end-time'
                  name={'end time'}
                  defaultValue={endDate.toString()}
                  control={control}
                  errors={errors}
                  onChange={(newValue: any) => {
                    props.rowData.end = newValue.getHours() + ":" + newValue.getMinutes();
                  }}
                />
              );
            },
          },
        ]}
        loading={loading}
        title="Time Slots"
        onRowUpdate={async (input: any) => {
          await api.update(input.id, input);
          refresh();
        }}
        onRowDelete={async (input: any) => {
          await api.remove(input.id);
          refresh();
        }}
        actions={[
          {
            icon: "undo",
            tooltip: "Restore time slot",
            onClick: async (_e: any, data: any) => {
              if (!data.archived) {
                alert("Time slot not deleted");
                return;
              }
              const confirmed = window.confirm(
                "Are you sure you want to restore this time slot?"
              );
              if (!confirmed) return;
              await api.restore(data.id);
              refresh();
            }
          }
        ]}
        disableKey={'archived'}
        pageSize={pageSize}
        pagesCount={pagesCount}
        page={page}
        onSearchChange={onSearchChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(TimeSlot);
