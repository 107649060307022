export interface Err {
  response: { data: any, statusText?: any };
  message: any;
}
export default async function extractErrorText(err: Err, defaultMessage?: string) {
  let data: string =
    err?.response && typeof err.response === 'object' ?
      ('data' in err.response) ?
        typeof err.response.data === 'string' ?
          err.response.data
          : (typeof err.response.data === 'object' && 'text' in err.response.data && err.response.data.text && typeof err.response.data.text === 'function') ?
            await err.response.data.text()
            : (err?.message && typeof err.message === 'string') ?
              err.message
              : ('statusText' in err.response && typeof err.response.statusText === 'string') ?
                err.response.statusText
                : ''
        : (err?.message && typeof err.message === 'string') ? err.message : ''
      : (err?.message && typeof err.message === 'string') ? err.message : '';
  data = (data || defaultMessage || "An unexpected error occured")
    .replaceAll(/<[^>]*>/g, "") // Strip html tags out of `data`
    .trim();
  return data;
}
