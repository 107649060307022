import Tab from "../components/Tab";
import Head from "../components/Head";

// Tabs
import React from "react";
import Roles from "./Roles";
import CourseOfferingBasedRoles from "./CourseOfferingBasedRoles";
import { PermissionsEnum } from "../util/enum/permissions.enum";

const RoleDatabase = () => (
    <React.Fragment>
        <Head title="Roles" path="/roles" />
        <Tab
            urls={[
                "roles",
                "courseOfferingBasedRoles",
                // "departmentBasedRoles",
            ]}
            tabs={[
                {
                    name: "Roles",
                    content: <Roles />,
                    permissions: [PermissionsEnum.ViewRoles],
                },
                {
                    name: "Course Roles",
                    content: <CourseOfferingBasedRoles />,
                    permissions: [PermissionsEnum.ViewCourseOfferingBasedRoles],
                },
                // {
                //     name: "Department Roles",
                //     content: <DepartmentBasedRoles />,
                // },
            ]}
        />
    </React.Fragment>
);

export default RoleDatabase;
