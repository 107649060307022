import { generateRoutes } from "./base.api";

const generatedRoutes = generateRoutes("/semesters");
export default {
    // archive: generatedRoutes.remove,
    // restore: generatedRoutes.restore,
    update: generatedRoutes.update,
    create: generatedRoutes.add,
    find: generatedRoutes.find,
}
