import React, { useEffect, useState } from "react";
import Head from "../components/Head";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import Guard from "../components/guards/Guard";
import { canRegister, downloadGeneratedTranscripts } from '../api/users.api'
import contentDisposition from "content-disposition";

const Home = () => {
  const history = useHistory();
  const [canRegisterCourse, setCanRegisterCourse] = useState(false);
  const { transcripts } = useParams<{ transcripts: string }>();

  useEffect(() => {
    (async () => {
      try {
        if (transcripts) {
          let response = await downloadGeneratedTranscripts(transcripts);
          let blob: Blob = new Blob([response.data], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.download = contentDisposition.parse(
            response.headers["content-disposition"]
          ).parameters["filename"];
          link.click();
          return history.push('/home');
        }
        setCanRegisterCourse(false)
        await canRegister();
        setCanRegisterCourse(true);
      } catch (e) { }
    })();
  }, []);

  return (
    <React.Fragment>
      <Head title="Home" path="/home" />
      <h1>Home page</h1>
      <Guard condition={canRegisterCourse}>
				<div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            history.push("/course-registration");
          }}
        >
          Course registration
        </Button>
				</div>
      </Guard>
    </React.Fragment>
  );
};

export default Home;
