import axios from "axios";
import { generateRoutes } from "./base.api";

let routes = generateRoutes("/timeSlots");

export default {
  add: routes.add,
  find: routes.find,
  restore: routes.restore,
  update: routes.update,
  remove: routes.remove,
};
export const findAllTimeSlots = async () => await axios.get('/timeSlots');
