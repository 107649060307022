import { InputComponentProps } from "./InputComponentProps";
import TextInput from "./TextInput";
import PasswordInput from "./PasswordInput";
import SelectInput from "./SelectInput";
import MultileSelectInput from "./MultipleSelectInput";
import FileInput from "./FileInput";
import NumberInput from "./NumberInput";
import ConfirmPasswordInput from "./ConfirmPasswordInput";
import MultiTextInput from "./MultiTextInput";
import DateTimeInput from "./DateTimeInput";
import CheckBoxInput from "./CheckBoxInput";
import TimeInput from "./TimeInput";
import SelectAutocompleteInput from "./SelectAutocompleteInput";
import MultipleSelectAutocompleteInput from "./MultipleSelectAutoCompleteInput";
import DecimalInput from "./DecimalInput";
import EmailInput from "./EmailInput";
// import { useRef } from "react";

export const InputFactory = (type: string, options: InputComponentProps) => {
  // let ref = useRef(null);
  switch (type) {
    case "text":
      return (
        <TextInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          multiline={options.multiline}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "number":
      return (
        <NumberInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "decimal":
      return (
        <DecimalInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "password":
      return (
        <PasswordInput
          name={options.name}
          defaultValue=""
          errors={options.errors}
          rules={options.rules}
          control={options.control}
          required={options.required}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "confirmPassword":
      return (
        <ConfirmPasswordInput
          name={options.name}
          defaultValue=""
          errors={options.errors}
          rules={options.rules}
          control={options.control}
          required={options.required}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "select":
      return (
        <SelectInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          options={options.options}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "selectAutocomplete":
      return (
        <SelectAutocompleteInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          options={options.options}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "multipleSelectAutocomplete":
      return (
        <MultipleSelectAutocompleteInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          options={options.options}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "multiSelect":
      return (
        <MultileSelectInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          options={options.options}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "file":
      return (
        <FileInput
          name={options.name}
          defaultValue={options.defaultValue}
          errors={options.errors}
          rules={options.rules}
          control={options.control}
          fileType={options?.fileType}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
          multiFile={options.multiFile}
          setReset={options.setReset}
          resetReset={options.resetReset}
        // ref={ref}
        />
      );
    case "dateTime":
      return (
        <DateTimeInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "checkbox":
      return <CheckBoxInput {...options} />;
    case "multiText":
      return (
        <MultiTextInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "time":
      return (
        <TimeInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    case "email":
      return (
        <EmailInput
          name={options.name}
          defaultValue={options.defaultValue}
          rules={options.rules}
          errors={options.errors}
          control={options.control}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
    default:
      return (
        <TextInput
          name={options.name}
          defaultValue={options.defaultValue}
          errors={options.errors}
          rules={options.rules}
          control={options.control}
          required={options.required}
          onChange={options.onChange}
          helperText={options.helperText}
          helperTextCustomStyle={options.helperTextCustomStyle}
          id={options.id}
          customControlName={options.customControlName}
        />
      );
  }
};
