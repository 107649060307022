import { useParams } from "react-router-dom";
import { getStudentFinancialData } from "../api/users.api";
import Head from "../components/Head";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { MTableBody } from "material-table";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import findOptions from "../api/interfaces/findOptions.interface";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";

function StudentFinancialData() {
	const { id } = useParams<{
		id: string;
	}>();

	const [
		data,
		pagesCount,
		loading,
		page,
		pageSize,
		,
		onPageChange,
		onPageSizeChange,
	] = useFindRequestQueryParams(function (findOptions: findOptions) {
		return getStudentFinancialData(id, findOptions);
	}, "student-financial-data");

	return (
		<>
			<Head
				path={`/users/students/${id}/financial-data`}
				title="Student Financial Data"
			/>
			<CollectionComponent
				data={data.invoices}
				loading={loading}
				backendPagination
				pagesCount={pagesCount}
				page={page}
				pageSize={pageSize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				title="Student Financial Data"
				columns={[
					{
						title: "Invoice number",
						field: "invoiceNumber",
					},
					{
						title: "Date",
						render: RenderDate(["date"])
					},
					{
						title: "Debit",
						render: (rowData) => rowData.debit || '-',
					},
					{
						title: "Credit",
						render: (rowData) => rowData.credit || "-",
					},
					{
						title: "Balance",
						field: "totalBalance"
					},
					{
						title: "Description",
						field: "description"
					}
				]}
				viewOnly
				customComponents={{
					Body: (props: any) => {
						return (
							<>
								<MTableBody {...props} />
								<MTableBody
									{...props}
									renderData={[
										{
											invoiceNumber: "TOTAL",
											totalBalance: data.totalBalance,
											tableData: {
												id: 1,
											},
										},
									]}
								/>
							</>
						);
					},
				}}
			/>
		</>
	);
}

export default StudentFinancialData;
