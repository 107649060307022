import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form, { FormInputProps } from "../components/forms/Form";
import Head from "../components/Head";
import api from "../api/programForms.api";
import { AnswerType, IQuestion, ISection, questionToType, typeToAnswerType, ProgramFormData } from "./ProgramForm";
import { connect } from "react-redux";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import Tab from "../components/Tab";
import ApplicationFormPage from "./ApplicationFormPage";
import { useHistory } from "react-router";
import '../styles/AdmissionForm.scss';
import '../styles/bootstrap.css';
import useDarkMode, { DarkMode } from "use-dark-mode";
import { useTranslation } from "react-i18next";

export type Answer = {
    value: any;
    questionId: string;
    questionText: string;
    type: AnswerType;
    controlName: string;
    _id?: string;
};

export const toInput: (questions: IQuestion[] | undefined) => FormInputProps[] = (questions) => {
    let filteredQuestions = questions?.filter(question => question.deleted === false) || [];
    let renderedInputs = filteredQuestions.map(question => {
        let type = questionToType(question);

        let input = {
            required: question.required,
            fileType: question.fileType,
            name: question.question,
            type: type,
            upload: true,
            multiline: question.multipleLines,
            questionId: question._id,
            options: question.options?.map(option => ({ value: option, label: option })) || [],
            defaultValue: (question.defaultValue || question.defaultValue === 0) ? question.defaultValue : undefined,
            helperText: question.helperText,
            customControlName: question.customControlName,
            answerId: question.answerId,
        }
        return input;
    });
    return renderedInputs || [];
}

const ApplicationForm = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [form, setForm] = useState<ProgramFormData>({ code: "", form: { title: "", questions: [] } });
    const [questionAnswers, setQuestionAnswers] = useState<Answer[]>([]);
    const [sectionAnswers, setSectionAnswers] = useState<Answer[][]>([]);
    const { t } = useTranslation();
    const submit = async (save: boolean, answers: Answer[], section?: number) => {
        let sectionsAnswers = [...sectionAnswers];
        let questionsAnswers = [...questionAnswers];
        if (section || section === 0) {
            sectionsAnswers.splice(section, 1, answers ? answers : []);
            setSectionAnswers(sectionsAnswers);
        } else {
            questionsAnswers = answers || [];
            setQuestionAnswers(answers);
        }
        let submissionSections = sectionsAnswers.filter(section => {
            return section.length > 0
        });
        let resp = await api.submitProgramApplicationForm(+id, { ...(questionsAnswers.length > 0 ? { _questions: questionsAnswers } : {}), ...(submissionSections.length > 0 ? { _sections: submissionSections } : {}), additionalData: { formId: form.form._id }, ...(save ? { _save: true } : {}) });
        loadForm();
        return resp;
    };
    async function loadForm() {
        try {
            const { data } = await api.getStudentApplication(+id);
            setForm(data);
            //set answers
            let submissionQuestionAnswers: Answer[] = [];
            for (let questionIndex in data.form.questions) {
                let question = data.form.questions[questionIndex];
                if (question.defaultValue || question.defaultValue === false || question.defaultValue === 0) submissionQuestionAnswers.push({
                    value: question.defaultValue,
                    questionId: question._id,
                    questionText: question.question,
                    _id: question.answerId,
                    type: typeToAnswerType(questionToType(question)),
                    controlName: question.customControlName,
                })
            };
            setQuestionAnswers(submissionQuestionAnswers);
            if (data.form.sections) {
                let submissionSections: Answer[][] = [];
                for (let sectionIndex in data.form.sections) {
                    let section = data.form.sections[sectionIndex];
                    let submissionSectionAnswers: Answer[] = [];
                    for (let questionIndex in section.questions) {
                        let question = section.questions[questionIndex];
                        if (question.defaultValue || question.defaultValue === false || question.defaultValue === 0) {
                            submissionSectionAnswers.push({
                                value: question.defaultValue,
                                questionId: question._id,
                                _id: question.answerId,
                                questionText: question.question,
                                type: typeToAnswerType(questionToType(question)),
                                controlName: question.customControlName,
                            })
                        }
                    };
                    submissionSections.push(submissionSectionAnswers);
                }
                setSectionAnswers(submissionSections);
            }
        } catch (e) {
            enqueueSnackbar(await extractErrorText(e as Err, 'Could not load program form'), {
                variant: "error",
            });
            history.push('/');
        }
    }

    let tabs: { content: React.ReactFragment, name: string, url: string }[] = [];
    if (form.form.questions.length > 0) tabs.push({
        content: <ApplicationFormPage title={form.form.title} description={form.form.description} inputs={toInput(form.form.questions)} formId={form.form._id || ''} submit={submit} />,
        name: form.form.title,
        url: ``,
    })
    if (form.form.sections)
        for (let section in form.form.sections) {
            tabs.push({
                content: <ApplicationFormPage section={Number(section)} title={form.form.sections[section].title} description={form.form.sections[section].description} inputs={toInput(form.form.sections[section].questions)} formId={form.form._id || ''} submit={submit} />,
                name: form.form.sections[section].title,
                url: ``,
            })
        }
    tabs.push({
        content: <Form
            inputs={toInput(form?.form?.questions)}
            sections={form?.form?.sections?.map((section: ISection) => { return { title: section.title, description: section.description, inputs: toInput(section.questions) } })
            }
            title={form?.form?.title}
            description={form?.form?.description}
            apiRequest={async (data: any) => {
                let resp = await api.submitProgramApplicationForm(+id, {
                    ...data, ...(data._save ? {} : { _submit: true })
                });
                loadForm();
                return resp;
            }}
            successMessage={(response: any) => response.message || `${response._submit ? 'submitted' : 'saved'} successfully`}
            additionalData={{ formId: form?.form._id }}
            dynamicForm
            saveButton
            customControlName={(index: number, sectionIndex?: number) => {
                if (sectionIndex || sectionIndex === 0) return `section-${sectionIndex}-question-${index}`;
                return `${index}`;
            }}
        />,
        name: t("PREVIEW_FORM"),
        url: ``
    });

    useEffect(() => {
        loadForm();
    }, []);

    const dark = useDarkMode();
    let getImageTag = (v: DarkMode) => v.value ? <img src={'/etsc-dark.png'} className='form-logo' /> : <img src={'/etsc.png'} className='form-logo' />;
    const [imageTag, setImageTag] = useState<React.ReactElement>(getImageTag(dark));
    useEffect(() => {
        setImageTag(getImageTag(dark));
    }, [dark.value]);

    return (
        <div className='form-font p-2'>
            <React.Fragment>
                <Head title="Program Application Form" path={`/applications/applicationForm/${id}`} />
                <div className='row m-0 w-100 mb-3'>
                    <div className='col-lg-12 d-flex justify-content-center'>
                        {imageTag}
                    </div>
                    {/* <div className='col-lg-12 d-flex justify-content-center'>
                        <p className="form-header m-0 p-0">{form.form.title}</p>
                    </div> */}
                </div>
                <Tab
                    urls={tabs.map(tab => tab.url)}
                    tabs={tabs.map(tab => { return { name: tab.name, content: tab.content } })}
                    ignoreUrls
                    defaultIndex={0}
                />
            </React.Fragment >
        </div>
    );
};

export default ApplicationForm;
