import axios from "axios";
import { ExportFormat, generateRoutes } from "./base.api";
import findOptions from "./interfaces/findOptions.interface";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";

let routes = generateRoutes("/course-offerings");

async function assignStaff(id: number, data: any) {
  return await axios.post(`/course-offerings/${id}/assign-staff`, data);
}

export async function removeStaff(id: number, facultyProfileId: number) {
  return await axios.post(`/course-offerings/${id}/remove-staff`, { facultyProfileId });
}

const edit = async (id: number, data: any) => {
  return await axios.patch(`/course-offerings/${id}`, data);
}

const register = async (id: number) => {
  return await axios.post(`/course-offerings/${id}/register`);
};

async function find() {
  return await axios.get(`/course-offerings/find-valid`);
}

const assignSchedule = async (id: number, data: any) => {
  return await axios.post(`/course-offerings/${id}/assign-schedule`, data);
};

const grade = async (id: number, data: any) => {
  return await axios.post(`/course-offerings/${id}/grade`, data);
};

const lock = async (id: number, studentProfileId: number) => {
  return await axios.post(`/course-offerings/${id}/lock/${studentProfileId}`);
};

const unlock = async (id: number, studentProfileId: number) => {
  return await axios.post(`/course-offerings/${id}/unlock/${studentProfileId}`);
};

const hideFromTranscript = async (id: number, studentProfileId: number) => {
  return await axios.post(
    `/course-offerings/${id}/hide-from-transcript/${studentProfileId}`
  );
};

const showInTranscript = async (id: number, studentProfileId: number) => {
  return await axios.post(
    `/course-offerings/${id}/show-in-transcript/${studentProfileId}`
  );
};

const manageStudentDrop = async (id: number, studentProfileId: number, data: { dropDate: Date | null }) => {
  return await axios.post(
    `/course-offerings/${id}/manage-student-drop/${studentProfileId}`,
    data
  );
};

const validateMoodleGrades = async (id: number) => {
  return await axios.post(`/course-offerings/${id}/validate-moodle-grades`);
}
const confirmMoodleGrades = async (id: number, token: string) => {
  return await axios.post(`/course-offerings/${id}/confirm-moodle-grades`, { token });
}

const getCourseOfferingPermissions = async (id: number) => await axios.get(`/course-offerings/${id}/permissions`);

export function exportCourseOfferingGrades(id: number, options: findOptions, format: ExportFormat) {
  return axios.get(`/course-offerings/${+id}/export/grades?${JSONToQueryParams({
    ...options,
    format
  })}`, {
    responseType: "blob"
  });
}

export default {
  findOne: routes.findOne,
  assignStaff,
  removeStaff,
  find,
  register,
  assignSchedule,
  grade,
  lock,
  unlock,
  hideFromTranscript,
  showInTranscript,
  edit,
  manageStudentDrop,
  validateMoodleGrades,
  confirmMoodleGrades,
  getCourseOfferingPermissions,
};
