import React from "react";
import { useController } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { InputComponentProps } from "./InputComponentProps";
import { useTranslation } from "react-i18next";
import '../../../styles/Form.scss';

let rendered = 0;

const MultileSelectInput: React.FunctionComponent<InputComponentProps> = (
  props
) => {
  const { t } = useTranslation();

  const { field, meta } = useController({
    ...props,
    rules: {
      ...props.rules,
      validate: (value) =>
        props.required ?
          value.length >= 1 ? undefined : t("FORM_INPUT_REQUIRED")
          : true,
    },
    name: props.customControlName || props.name,
  });

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className='input-label'>{props.name + (props.required ? "*" : "")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={props.id}
        value={field.value || (rendered++ === 0 && props.defaultValue) || []}
        onChange={(value) => {
          if (props.onChange) props.onChange(value);
          return field.onChange(value);
        }}
        error={meta.invalid}
        onBlur={field.onBlur}
        inputRef={field.ref}
        multiple
      >
        {props?.options?.map((option, i) => (
          <MenuItem value={option.value} key={i}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      {thisError?.type === "required" && (
        <span className="error">{t("FORM_INPUT_REQUIRED")}</span>
      )}
      {thisError?.type === "validate" && (
        <span className="error">{thisError?.message || t("INVALID")}</span>
      )}
    </React.Fragment>
  );
};

export default MultileSelectInput;
