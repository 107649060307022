import axios from "axios";
import { FormSectionData } from "../pages/FormSection";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import findOptions from "./interfaces/findOptions.interface";


const getFormSections = (options: findOptions) => axios.get(`/formSections?${JSONToQueryParams(options)}`);

const getFullFormSections = () => axios.get('/formSections/get');

const getFormSection = (id: number) => {
  return axios.get(`/formSections/${id}`);
};

const editFormSection = (id: number, data: FormSectionData) => {
  return axios.patch(`/formSections/${id}`, data);
};

const editSectionName = (id: number, data: FormSectionData) => {
  return axios.post(`/formSections/${id}`, data);
};

const createFormSection = (data: { name: string }) => axios.post('/formSections', data);

const importQuestionsFromProgramForm = (id: number, data: { startIndex: number, endIndex: number }) => axios.post(`/formSections/${id}/importQuestions`, data);

const restore = (id: number) => axios.post(`/formSections/restore/${id}`);

const archive = (id: number) => axios.delete(`/formSections/${id}`);


export default {
  getFormSections,
  getFullFormSections,
  getFormSection,
  editFormSection,
  createFormSection,
  importQuestionsFromProgramForm,
  restore,
  archive,
  editSectionName,
};
