import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/admissionRequirements.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React, { useEffect } from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { MTableAction } from "material-table";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import { useHistory } from "react-router";
import { OpenInNew } from "@material-ui/icons";

const AdmissionRequirements = () => {
    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
        onHideChange,
    ] = useFindRequestQueryParams(api.find, "adminRequirements");

    const { control, errors } = useForm<{
        inputs: [],
    }>();
    useEffect(function () {
        data = data?.map((entity: any) => {
            for (let translation of entity.translations) {
                entity[`${translation.language}Text`] = translation.text;
            }
            return entity;
        });
    });
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <React.Fragment>
            <Head title="AdmissionRequirements" path="/admissionRequirements" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.CreateAdmissionRequirement]}>
                    <Button
                        color="primary"
                        label="Add Admission Requirement"
                        withForm
                        form={{
                            title: "Add Admission Requirement",
                            description:
                                "Add a new admission requirement.",
                            apiRequest: api.create,
                            inputs: [
                                {
                                    name: "Name",
                                    keyName: "name",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: "English Text",
                                    keyName: "enText",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: "Arabic Text",
                                    keyName: "arText",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: 'Attachments',
                                    keyName: 'attachments',
                                    required: false,
                                    type: 'multiText',
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Admission Requirement added successfully.";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <CollectionComponent
                title="Admission Requirements"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                    },
                    {
                        title: "English Text",
                        field: "enText",
                        cellStyle: { whiteSpace: 'pre-line' },
                        editComponent: (row) => {
                            return InputFactory('text', {
                                multiline: true,
                                required: true,
                                control,
                                errors,
                                name: 'English Text',
                                defaultValue: row?.value || '',
                                id: 'admission-requirement-enText',
                                onChange: (event: any) => {
                                    if (row?.rowData) return row.rowData.enText = event?.target?.value;
                                },
                            })
                        },
                    },
                    {
                        title: "Arabic Text",
                        field: "arText",
                        cellStyle: { whiteSpace: 'pre-line' },
                        editComponent: (row) => {
                            return InputFactory('text', {
                                multiline: true,
                                required: true,
                                control,
                                errors,
                                name: 'Arabic Text',
                                defaultValue: row?.value || '',
                                id: 'admission-requirement-arText',
                                onChange: (event: any) => {
                                    if (row?.rowData) return row.rowData.arText = event?.target?.value;
                                },
                            })
                        },
                    },
                    {
                        title: "Links",
                        field: "attachments",
                        render: (row) => row.attachments?.join(', ') || '-',
                        editComponent: (props) => (InputFactory('multiText', {
                            control,
                            errors,
                            name: 'Links',
                            defaultValue: props.rowData.attachments || [],
                            id: 'admissionRequirementAttachments',
                            required: true,
                            customControlName: 'attachments',
                            onChange: (event: any, a: any) => { props.rowData.attachments = event; }
                        }))
                    },
                ]}
                backendPagination
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                noDelete
                itemName="admissionRequirement"
                onRowUpdate={async (input: any, oldData: any) => {
                    let translations = [{
                        admissionRequirement_id: oldData?.id,
                        language: "ar",
                        text: input?.arText,
                    }, {
                        admissionRequirement_id: oldData?.id,
                        language: "en",
                        text: input?.enText,
                    }]
                    await api.update(oldData.id, { ...input, translations });
                    refresh();
                    enqueueSnackbar('Admission Requirement edited successfully!', { variant: 'success' });
                }}
                searchText={search}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
                disableKey="archived"
                mainPermission={PermissionsEnum.EditAdmissionRequirement}
                viewDisabledInSeparateTab
                onHideChange={onHideChange}
                actions={[
                    {
                        icon: "undo",
                        tooltip: "Restore Admission Requirement",
                        onClick: async (e: any, data: any) => {
                            let confirmed = window.confirm(
                                `Are you sure you want to restore this Admission Requirement?`
                            );
                            if (confirmed) {
                                await api.restore(data.id);
                                refresh();
                            }
                        },
                        errorMessage: handleResponseError,
                        requiredPermission: PermissionsEnum.RestoreAdmissionRequirement,
                        render: (p: any) => {
                            if (p.data.archived === false) return <></>;
                            return (
                                <PermissionsGuard
                                    requiredPermissions={[PermissionsEnum.RestoreAdmissionRequirement]}
                                >
                                    <MTableAction {...p} />
                                </PermissionsGuard>
                            );
                        },
                    },
                    {
                        icon: () => <DeleteIcon />,
                        tooltip: "Archive Admission Requirement",
                        requiredPermission: PermissionsEnum.ArchiveAdmissionRequirement,
                        onClick: async (_e: any, data: any) => {
                            if (data.archived) {
                                alert("Admission Requirement already deleted");
                                return;
                            }
                            const confirmed = window.confirm(
                                "Are you sure you want to delete this Admission Requirement?"
                            );
                            if (!confirmed) return;
                            await api.archive(data.id);
                            refresh();
                        },
                        render: (p: any) => {
                            if (p.data.archived === true) return <></>;
                            return (
                                <PermissionsGuard
                                    requiredPermissions={[PermissionsEnum.ArchiveAdmissionRequirement]}
                                >
                                    <MTableAction {...p} />
                                </PermissionsGuard>
                            );
                        },
                    },
                    {
                        onClick: (_: any, row: any) => history.push(`/admissionRequirement/${row.id}`),
                        icon: () => <OpenInNew />,
                    },
                ]}
                export={{
                    type: 'custom',
                    fn: api.export,
                }}
            />

        </React.Fragment>
    )
};

export default AdmissionRequirements;
