import Tab from "../components/Tab";
import Head from "../components/Head";

// Tabs
import React from "react";
import Faqs from "./faqs";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import FaqGroups from "./faqGroups";

const FAQDatabase = () => (
    <React.Fragment>
        <Head title="FAQs" path="/faqs" />
        <Tab
            urls={[
                "faqs",
                "faqGroups",
            ]}
            tabs={[
                {
                    name: "FAQs",
                    content: <Faqs />,
                    permissions: [PermissionsEnum.ViewFAQs],
                },
                {
                    name: "FAQ Groups",
                    content: <FaqGroups />,
                    permissions: [PermissionsEnum.ViewFAQGroups],
                },
            ]}
        />
    </React.Fragment>
);

export default FAQDatabase;
