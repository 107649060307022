import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import CombinedReducer from "./reducers/combined.reducer";
import { PersistGate } from "redux-persist/integration/react";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";

const Store: React.FunctionComponent<{}> = (props) => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["SettingsReducer", "UserReducer", "DrawerReducer"],
  };

  const persistedReducer = persistReducer(persistConfig, CombinedReducer);

  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(
        reduxThunk // for async actions
      )
    )
  );

  const persistor = persistStore(store, null, () => {
    const { token } = store.getState().UserReducer;
    if (token) axios.defaults.headers.Authorization = `${token}`;
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {props.children}
      </PersistGate>
    </Provider>
  );
};

export default Store;
