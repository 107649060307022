import { useEffect } from "react";
import { connect } from "react-redux";
import api from "../api/concentrations.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText from "../util/functions/extractErrorText";

const PendingConcentrationChoices = (props: any) => {
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
  ] = useFindRequestQueryParams(api.getPendingConcentrationChoices, "pendingConcentrationChoices");

  useEffect(function () {
    data = data?.map((entity: any) => {
      for (let translation of entity.concentrationOffering.concentration
        .translations) {
        if (translation.language === props.language) {
          entity[`concentration__name`] = translation.name;
          entity[`concentration__desc`] = translation.description;
        }
      }
      return entity;
    });
  });

  return (
    <CollectionComponent
      title="Pending Concentration Choices"
      data={data}
      columns={[
        {
          title: "Student email",
          field: "programChoice.enrollment.studentProfile.user.email",
        },
        {
          title: "Start semester",
          field: "startSemester.semester",
        },
        {
          title: "Start year",
          field: "startSemester.year",
        },
        { title: "Concentration name", field: "concentration__name" },
        { title: "Concentration description", field: "concentration__desc" },
      ]}
      loading={loading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSearchChange={onSearchChange}
      searchText={search}
      page={page}
      pageSize={pageSize}
      pagesCount={pagesCount}
      viewOnly
      actions={[
        {
          icon: "check",
          tooltip: "Approve",
          onClick: async (e: any, data: any) => {
            await api.approveChoice(data.id);
            refresh();
          },
          errorMessage: extractErrorText,
          requiredPermission: PermissionsEnum.ModifyPendingConcentrationChoices,
        },
        {
          icon: "block",
          tooltip: "Reject",
          onClick: async (e: any, data: any) => {
            await api.rejectChoice(data.id);
            refresh();
          },
          errorMessage: extractErrorText,
          requiredPermission: PermissionsEnum.ModifyPendingConcentrationChoices,
        },
      ]}
    />
  );
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(PendingConcentrationChoices);
