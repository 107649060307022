import * as types from '../types/drawer.types';

const initialState = {
  index: 0,
}

export default function drawerReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.CHANGE_DRAWER_INDEX:
      return {
        ...state,
        index: action.payload.index,
      };
    default:
      return state;
  }
}
