import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { find as findReferralForms, findOne as findReferralForm } from "../api/referralForms.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import ComponentGuard from "../components/guards/ComponentGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { FormInputProps } from '../components/forms/Form';
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { MTableAction } from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { MTableBodyRow } from "material-table";
import { makeStyles } from "@material-ui/core";
import { IQuestion, questionToType, typeToQuestionType } from "./ProgramForm";
import { useQuery } from "react-query";
import { findOne as getReferralForm, edit as editReferralForm } from "../api/referralForms.api";


export interface ReferralFormData {
  id?: string,
  form: {
    _id?: string,
    title: string,
    description?: string,
    questions: IQuestion[],
  }
}

const ReferralForm = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<ReferralFormData>({ id, form: { _id: '', title: '', description: '', questions: [], } });
  const { control, errors } = useForm<{}>();
  const [referralOptions, setReferralOptions] = useState<{ value: any, label: string }[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [viewDeleted, setViewDeleted] = useState<boolean>(false);
  const inputTypeOptions = [
    { label: "Text", value: 'text' },
    { label: "Multi text", value: 'multiText' },
    { label: "Number", value: 'number' },
    { label: "Decimal", value: 'decimal' },
    { label: "Email", value: 'email' },
    { label: "Select", value: 'select' },
    { label: "Select with auto complete", value: 'selectAutocomplete' },
    { label: "Multiple select with auto complete", value: 'multipleSelectAutocomplete' },
    { label: "Multi select", value: 'multiSelect' },
    { label: "Checkbox", value: 'checkbox' },
    { label: "Time", value: 'time' },
    { label: "Date time", value: 'dateTime' },
    { label: "File", value: 'file' },
  ];
  const fileTypeOptions = [
    { label: 'image', value: 'image' },
    { label: 'excel', value: 'excel' },
    { label: 'word', value: 'word' },
    { label: 'pdf', value: 'pdf' },
    { label: 'video', value: 'video' },
  ];
  const classes = makeStyles({
    row: {
      opacity: 0.5,
    },
  })();

  const toInput: (questions: IQuestion[] | undefined) => FormInputProps[] = (questions) => {
    let filteredQuestions = questions?.filter(question => question.deleted === false) || [];
    let renderedInputs = filteredQuestions.map(question => {
      let type = questionToType(question);
      let input = {
        required: question.required,
        fileType: question.fileType,
        name: question.question,
        type: type,
        upload: true,
        multiline: question.multipleLines,
        options: question.options?.map(option => ({ value: option, label: option })) || [],
        helperText: question.helperText,
        answerId: question.answerId,
        customControlName: question.customControlName,
      }
      return input;
    });
    return renderedInputs || [];
  }

  const { isLoading: loading, refetch } = useQuery(
    ["referralForm", +id],
    () => findReferralForm(+id),
    {
      onSuccess(response) {
        setData(response.data);
      },
      onError() {
        enqueueSnackbar("Could not load referral form", {
          variant: "error",
        });
      },
    }
  );

  useQuery("referralForms", () => {
    return findReferralForms();
  }, {
    onSuccess(response) {
      let { data } = response.data;
      let options = [];
      for (let referral of data) {
        if (referral.id === +id) {
          continue;
        }
        options.push({
          label: referral.name,
          value: referral.id,
        })
      }
      setReferralOptions(options);
    },
  });

  // const refresh = loadForm;

  return (
    <React.Fragment>
      <Head title="Referral Form" path="/referral-form/" />
      <div className="buttons-section">
        <Button label='Preview Form' color='primary' withForm form={{
          inputs: toInput(data?.form.questions),
          title: data?.form?.title,
          description: data?.form?.description,
        }}
        />
        <Button color="primary" label='Start new Form' handleClick={() => {
          let confirmed = window.confirm(`WARNING! Pressing ok will replace the existing form!`);
          if (confirmed)
            setData({ id: data?.id, form: { title: 'New Form', questions: [] } });
        }}
        />
        <Button color="primary" label="Duplicate another referral form" withForm form={{
          successMessage: () => 'Form duplicated! Do not forget to save for duplication to take effect',
          title: 'Duplicate referral form',
          description: "Select the Referral form you would like to duplicate",
          inputs: [
            {
              type: 'selectAutocomplete',
              required: true,
              name: 'Referral',
              keyName: 'referralId',
              options: referralOptions
            }
          ],
          apiRequest: async (dataaa: any) => {
            let confirmed = window.confirm(`WARNING! Pressing ok will replace the existing form with the chosen form!`);
            if (!confirmed) return;

            let { data } = await getReferralForm(dataaa.referralId);
            let questions = data?.form?.questions?.map((q: any) => {
              const { _id, ...rest } = q;
              return rest;
            }).filter((q: any) => !q.deleted) || [];
            setData({ form: { title: data?.form?.title || '', description: data?.form?.description, questions: questions }, id: data?.id });
          },
          errorMessage: extractErrorText,
        }} />
      </div>
      <SingleComponent
        title="Referral Form"
        fields={[
          {
            name: "Title",
            value: data?.form?.title || "",
            edit: {
              editPermissions: [PermissionsEnum.EditReferralForm],
              form: {
                title: 'Edit form title',
                inputs: [
                  {
                    type: 'text',
                    name: 'Title',
                    keyName: 'title',
                    required: true,
                    multiline: false,
                    defaultValue: data?.form.title || ''
                  }
                ],
                apiRequest: ({ title }: any) => {
                  setData({
                    form: {
                      ...data?.form,
                      questions: data?.form?.questions || [],
                      title,
                    }
                  })
                },
              }
            },

          },
          {
            name: "Description",
            value: data?.form?.description || "",
            displayLineBreaks: true,
            edit: {
              editPermissions: [PermissionsEnum.EditReferralForm],
              form: {
                title: 'Edit form description',
                inputs: [
                  {
                    type: 'text',
                    name: 'Description',
                    keyName: 'description',
                    required: true,
                    multiline: true,
                    defaultValue: data?.form.description || ''
                  }
                ],
                apiRequest: ({ description }: any) => {
                  setData({
                    form: {
                      ...data?.form,
                      title: data?.form?.title || '',
                      questions: data?.form?.questions || [],
                      description,
                    }
                  })
                },
              }
            },
          },
        ]}
      />
      <br />
      <br />
      <CollectionComponent
        title="Form Questions"
        loading={loading}
        mainPermission={PermissionsEnum.EditReferralForm}
        data={data.form.questions?.filter(question => viewDeleted ? question.deleted : !question.deleted) || []}
        noDelete
        onRowUpdate={(newData: any, oldData: any) => {
          let actualOldId = oldData.tableData.id;
          for (let i = 0; i < data.form.questions.length && i <= actualOldId; i++) {
            if (data.form.questions[i].deleted) actualOldId++;
          }
          delete newData.new;
          setData((prevData: any) => {
            let questions: IQuestion[] = [...prevData?.form?.questions || []];
            questions.splice(actualOldId, 1, newData);
            if (newData.number) {
              let number = newData.number;
              delete newData.number;
              if (number - 1 !== actualOldId)
                if (number < 1 || number > (data?.form?.questions?.filter(q => !q.deleted).length || 0))
                  enqueueSnackbar(`Could not reorder form questions, invalid question number: ${number}`, { variant: 'error' });
                else {
                  let questionToBeMoved = questions.splice(actualOldId, 1);
                  let actualNumber = number;
                  for (let i = 0; i < data.form.questions.length && i + 1 <= actualNumber; i++) {
                    if (data.form.questions[i].deleted) actualNumber++;
                  }
                  questions.splice(actualNumber - 1, 0, questionToBeMoved[0]);
                }
            }
            let title = prevData?.form?.title || "";
            return {
              ...prevData,
              form: {
                ...prevData.form,
                title,
                questions
              }
            }
          });
        }}
        onRowEditCancelled={(rowData: any) => {
          let actualOldId = rowData.tableData.id;
          for (let i = 0; i < data.form.questions.length && i <= actualOldId; i++) {
            if (data.form.questions[i].deleted) actualOldId++;
          }
          let f = data?.form?.questions || [];
          if (f.length > actualOldId && f[actualOldId]?.new) {
            f.splice(actualOldId, 1);
            setData({ ...data, form: { ...data?.form, title: data?.form?.title || "", questions: f } });
          }
        }}
        renderRow={(p: any) => {
          return viewDeleted ? (p?.data?.deleted ? <MTableBodyRow className={classes.row} {...p} /> : <React.Fragment />) : (p?.data?.deleted ? <React.Fragment /> : <MTableBodyRow {...p} />)
        }}
        columns={[
          {
            title: 'Number',
            field: 'number',
            render: (rowData) => { return rowData.tableData.id + 1 },
            editComponent: (row) => {
              return InputFactory('number', {
                id: 'referral-form-question-number',
                control,
                errors,
                name: ' ',
                defaultValue: row?.rowData?.tableData?.id + 1,
                onChange: (event: any) => {
                  row.rowData.number = event.target.value;
                }
              })
            }
          },
          {
            title: "Type",
            field: "type",
            render: (data: any) => {
              return questionToType(data);
            },
            editComponent: (row) => {
              return (InputFactory('selectAutocomplete', {
                id: 'referral-form-question-type',
                control,
                name: 'type',
                errors,
                options: inputTypeOptions,
                onChange: (newValue: any) => {
                  let type = newValue?.value;
                  return row.rowData.type = typeToQuestionType(type);
                },
                defaultValue: (() => {
                  return questionToType(row.rowData);
                })(),
              }))
            },
          },
          {
            title: "Question",
            field: "question"
          },
          {
            title: "Helper Text",
            field: "helperText",
            editComponent: (row: any) => {
              return InputFactory('text', {
                id: 'referral-form-question-helper-text',
                multiline: true,
                required: true,
                name: 'Helper Text',
                control,
                errors,
                defaultValue: row.rowData.helperText || '',
                onChange: (event: any) => {
                  row.rowData.helperText = event.target.value;
                }
              })
            },
          },
          // {
          //   title: "Description",
          //   field: "description",
          // },
          {
            title: "Selection Options",
            field: "options",
            render: (row) => {
              let renderedString = "";
              if (row?.options)
                for (let option of row.options) {
                  renderedString += (option + ', ');
                }
              renderedString = renderedString.substring(0, renderedString.length - 2)
              return renderedString;
            },
            editComponent: (row) => {
              let renderedString = "";
              if (row?.value)
                for (let option of row.value) {
                  renderedString += (option + '\n');
                }
              return (InputFactory('text', {
                id: 'referral-form-question-selection-options',
                control,
                name: 'options',
                errors,
                multiline: true,
                defaultValue: renderedString,
                onChange: (event: any) => {
                  let asArray = event.target.value.split('\n');
                  row.rowData.options = asArray;//.map((item: string) => { return { value: item, label: item } });
                }
              }))
            }
          },
          {
            title: "File Type",
            field: "fileType",
            render: (row) => {
              return row.fileType ? Array.isArray(row.fileType) ? row.fileType.join(', ') : row.fileType : '-';
            },
            editComponent: (row) => {
              return (InputFactory('multipleSelectAutocomplete', {
                id: 'referral-form-question-file-type',
                control,
                name: 'fileType',
                errors,
                options: fileTypeOptions,
                onChange: (newValues: any) => {
                  return row.rowData.fileType = newValues?.map((newValue: any) => newValue.value);
                },
                defaultValue: row.rowData.fileType,
              }))
            },
          },
          {
            title: "Required",
            field: "required",
            render: RenderBoolean(['required']),
            editComponent: (row) => {
              return InputFactory('checkbox', {
                id: 'referral-form-question-is-required',
                errors,
                control,
                name: 'required',
                onChange: (event: any) => { return row.rowData.required = event; },
                defaultValue: row.value,
              })
            }
          },
          {
            title: "Multiple Line",
            field: "multipleLines",
            render: RenderBoolean(['multipleLines']),
            editComponent: (row) => {
              return InputFactory('checkbox', {
                id: 'referral-form-question-is-multiple-lines',
                errors,
                control,
                name: 'multi line',
                onChange: (event: any) => { return row.rowData.multipleLines = event; },
                defaultValue: row.value,
              })
            }
          },
          {
            title: "Custom Control Name",
            field: "customControlName",
            editComponent: (row) => {
              return InputFactory('text', {
                id: 'referral-form-custom-control-name',
                errors,
                control,
                name: 'Custom Control Name',
                onChange: (event: any) => { return row.rowData.customControlName = event.target.value; },
                defaultValue: row.value || '',
                multiline: false,
              })
            }
          },
        ]}
        actions={[
          {
            icon: VisibilityIcon,
            tooltip: 'Toggle view disabled',
            onClick: () => {
              setViewDeleted(!viewDeleted);
            },
            iconCondition: () => viewDeleted,
            secondaryIcon: VisibilityOffIcon,
            isFreeAction: true,
          },
          {
            icon: "undo",
            tooltip: "Restore form question",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to restore this form question?"
              );
              if (!confirmed) return;
              setData((prevData: any) => {
                let questions: IQuestion[] = [...prevData?.form?.questions || []];
                let question = questions.find(question => question._id === data._id);
                if (question) question.deleted = false;
                let title = prevData?.form?.title || "";
                return {
                  ...prevData,
                  form: {
                    ...prevData.form,
                    title,
                    questions
                  }
                }
              });
            },
            render: (p: any) => {
              if (p.data.deleted === false) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.EditReferralForm]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Archive form question",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to delete this form question?"
              );
              if (!confirmed) return;
              setData((prevData: any) => {
                let questions: IQuestion[] = [...prevData?.form?.questions || []];
                let question = questions.find(question => question._id === data._id);
                if (question) question.deleted = true;
                let title = prevData?.form?.title || "";
                return {
                  ...prevData,
                  form: {
                    ...prevData.form,
                    title,
                    questions
                  }
                }
              });
            },
            render: (p: any) => {
              if (p.data.deleted === true) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.EditReferralForm]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          }, {
            onClick: (_: any, row: any) => {
              let f = data?.form?.questions || [];
              let newRow = { type: 'textQuestion', name: '', question: "", required: true, new: true, deleted: false };
              f.splice(f.length, 0, (newRow as IQuestion));
              setData({ ...data, form: { ...data?.form, title: data?.form?.title || "", questions: f } });
              (newRow as any).tableData = { editing: 'update' };
            },
            icon: () => <Add />,
            tooltip: "Add new question",
            isFreeAction: true,
          },
          {
            onClick: (_: any, row: any) => {
              let actualOldId = row.tableData.id;
              for (let i = 0; i < data.form.questions.length && i <= actualOldId; i++) {
                if (data.form.questions[i].deleted) actualOldId++;
              }
              let f = data?.form?.questions || [];
              let newRow = { type: 'textQuestion', name: '', question: "", required: true, new: true, deleted: false };
              f.splice(actualOldId + 1, 0, (newRow as IQuestion));
              setData({ ...data, form: { ...data?.form, title: data?.form?.title || "", questions: f } });
              (newRow as any).tableData = { editing: 'update' };
            },
            icon: () => <Add />,
            tooltip: "Add new question below",
          },
        ]}
      />
      <ComponentGuard requiredPermissions={[PermissionsEnum.EditReferralForm]}>
        <div><Button label='Save Form' color='primary' handleClick={async () => {
          try {
            let description = data?.form?.description ? ((data?.form?.description?.length > 0) ? data?.form.description : '') : '';
            let title = data?.form?.title ? ((data?.form?.title?.length > 0) ? data?.form.title : '') : '';
            const { data: updatedForm } = await editReferralForm(+id, { ...data?.form, title, description });
            setData(updatedForm);
            await refetch();
            enqueueSnackbar('Form edited successfuly!', {
              variant: "success",
            });
          } catch (error: any) {
            enqueueSnackbar(await extractErrorText(error as Err), {
              variant: 'error',
            });
          }
        }} /></div>
      </ComponentGuard>
    </React.Fragment>
  );
};

export default ReferralForm;
