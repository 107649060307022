import { OpenInNew } from "@material-ui/icons";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/programOfferings.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import ComponentGuard from "../components/guards/ComponentGuard";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import DeleteIcon from "@material-ui/icons/Delete";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import { useSnackbar } from "notistack";

const ProgramOffering = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [rfrsh, setRefresh] = useState(0);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function loadProgramOffering() {
      try {
        setLoading(true);
        const programOffering = await api.getOfferingBase(+id);
        if (!programOffering?.data) throw new Error('Could not load program offering!');
        programOffering.data.program = programOffering.data.program || {};
        try {
          let programConcentrations = await api.getProgramConcentrations(programOffering?.data?.program?.id);
          programOffering.data.program.concentrations = programConcentrations?.data?.concentrations;
        } catch { }
        try {
          let programCourses = await api.getProgramCourses(programOffering?.data?.program?.id);
          programOffering.data.program.programCourses = programCourses?.data?.programCourses;
        } catch { }
        try {
          let concentrations = await api.getConcentrations(+id);
          programOffering.data.concentrationOfferings = concentrations?.data?.concentrationOfferings;
        } catch {
          throw new Error('Could not load program offering concentrations!');
        }
        try {
          let courses = await api.getCourses(+id);
          programOffering.data.programCourses = courses?.data?.programCourses;
        } catch {
          throw new Error('Could not load program offering courses!');
        }
        try {
          let gradingSchemas = await api.getGradingSchemas(+id);
          programOffering.data.gradingSchemas = gradingSchemas?.data?.gradingSchemas;
        } catch { }
        parseProgramOffering(programOffering);
        setLoading(false);
      } catch (error) {
        enqueueSnackbar(await extractErrorText(error as Err), { variant: 'error' });
        history.push("/");
      }
    }

    loadProgramOffering();
  }, [history, id, rfrsh]);

  async function parseProgramOffering(response: AxiosResponse) {
    const { data } = response;
    if (data?.programCourses) {
      for (let programCourse of data.programCourses) {
        if (programCourse.course?.translations)
          for (let translation of programCourse.course.translations) {
            programCourse[`${translation.language}Name`] = translation.name;
            programCourse[`${translation.language}Description`] =
              translation.description;
          }
      }
    }
    if (data?.concentrationOfferings)
      for (let concentrationOffering of data.concentrationOfferings) {
        for (let translation of concentrationOffering.concentration
          .translations) {
          concentrationOffering[`${translation.language}Name`] =
            translation.name;
          concentrationOffering[`${translation.language}Description`] =
            translation.description;
        }
      }

    setData(data);
  }

  const refresh = () => setRefresh(rfrsh + 1);

  return (
    <React.Fragment>
      <Head title="Program Offering" path="/programOffering" />
      <div className="buttons-section">
        <PermissionsGuard
          requiredPermissions={[
            PermissionsEnum.AddProgramCourseToProgramOffering,
          ]}
        >
          <Button
            color="primary"
            label="Add program course"
            withForm
            form={{
              title: "Add program course",
              inputs: [
                {
                  type: "select",
                  name: "Course",
                  keyName: "programCourseId",
                  options: data?.program?.programCourses
                    .filter((programCourse: any) =>
                      data.programCourses.every(
                        (course: any) => course.id !== programCourse.id
                      )
                    )
                    .map((programCourse: any) => {
                      return {
                        value: programCourse.id,
                        label: programCourse.course.code,
                      };
                    }),
                  required: true,
                },
              ],
              apiRequest: (data: any) => api.addCourseToOffering(+id, data),
              successMessage: () => {
                refresh();
                return "ProgramOffering course created successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </PermissionsGuard>
        <ComponentGuard
          requiredPermissions={[
            PermissionsEnum.AddConcentrationOfferingToProgramOffering,
          ]}
        >
          <Button
            color="primary"
            label="Add concentration"
            withForm
            form={{
              title: "Add Concentration",
              description: "Choose the concentration you would like to add",
              apiRequest: (data: any) =>
                api.addConcentrationOffering(+id, data),
              inputs: [
                {
                  type: "select",
                  name: "Concentration",
                  keyName: "concentrationId",
                  options: (() => {
                    return data?.program?.concentrations.map(
                      (concentration: any) => {
                        return {
                          value: concentration.id,
                          label: concentration.code,
                        };
                      }
                    );
                  })(),
                  required: true,
                },
              ],
              successMessage: () => {
                refresh();
                return "ProgramOffering concentration created successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </ComponentGuard>
        <ComponentGuard
          requiredPermissions={[PermissionsEnum.StartApplicationProcess]}
        >
          <Button
            color="primary"
            label="Start application process"
            withForm
            form={{
              title: "Start application process",
              description:
                "Start the application process for this program offering",
              successMessage: () => {
                return "Application process started successfully!";
              },
              errorMessage: extractErrorText,
              apiRequest: (data: any) => api.startApplicationProcess(+id, data),
              inputs: [
                {
                  type: "text",
                  name: "Application process name",
                  keyName: "name",
                  required: true,
                },
                {
                  type: "dateTime",
                  name: "Start date",
                  keyName: "startDate",
                  required: true,
                },
                {
                  type: "dateTime",
                  name: "End date",
                  keyName: "endDate",
                  required: true,
                },
              ],
            }}
          />
        </ComponentGuard>
        {/* <PermissionsGuard
            requiredPermissions={[PermissionsEnum.ModifyPrograms]}
          >
            <Button
              color="primary"
              label="Set price per credit hour"
              withForm
              form={{
                title: "Set price per credit hour",
                inputs: [
                  {
                    type: "number",
                    name: "Price per credit hour",
                    keyName: "pricePerCreditHour",
                    required: true,
                  },
                ],
                apiRequest: (data: any) => api.setPricePerCreditHour(+id, data),
                successMessage: () => {
                  refresh();
                  return "Price per credit hour set successfully!";
                },
                errorMessage: extractErrorText,
              }}
            />
          </PermissionsGuard> */}
      </div>
      <SingleComponent
        title="Program Offering"
        fields={[
          {
            name: "Code",
            value: data?.program?.code,
          },
          {
            name: "Semester",
            value: data?.semester?.semester,
          },
          {
            name: "Year",
            value: data?.semester?.year,
          },
        ]}
      />
      <br />
      <br />
      <CollectionComponent
        title="Offering Courses"
        loading={loading}
        viewOnly
        data={data?.programCourses || []}
        columns={[
          {
            title: "Code",
            field: "course.code",
          },
          {
            title: "Optional",
            field: "optional",
            render: RenderBoolean(["optional"]),
          },
          {
            title: "Credit Hours",
            field: "course.creditHours",
          },
          {
            title: "English name",
            field: "enName",
          },
          {
            title: "English description",
            field: "enDescription",
          },
          {
            title: "Arabic name",
            field: "arName",
          },
          {
            title: "Arabic description",
            field: "arDescription",
          },
        ]}
        actions={[
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete course",
            onClick: async (_e: any, row: any) => {
              let response = await api.removeCourseFromOffering(+id, row.id);
              refresh();
              return response;
            },
            requiredPermission:
              PermissionsEnum.RemoveProgramCourseFromProgramOffering,
          },
        ]}
        itemName="course"
      />
      <ComponentGuard
        requiredPermissions={[PermissionsEnum.ViewConcentrationOfferings]}
      >
        <CollectionComponent
          title="Offering Concentrations"
          loading={loading}
          viewOnly
          disableKey="archived"
          data={data?.concentrationOfferings || []}
          columns={[
            {
              title: "Code",
              field: "concentration.code",
            },
            {
              title: "English name",
              field: "enName",
            },
            {
              title: "English description",
              field: "enDescription",
            },
            {
              title: "Arabic name",
              field: "arName",
            },
            {
              title: "Arabic description",
              field: "arDescription",
            },
          ]}
          itemName="concentration"
          actions={[
            {
              onClick: (_: any, row: any) =>
                history.push(`/concentrations/offerings/${row.id}`),
              icon: () => <OpenInNew />,
            },
            {
              icon: () => <DeleteIcon />,
              tooltip: "Delete concentration",
              onClick: async (_e: any, row: any) => {
                if (row.archived) {
                  alert("Concentration already deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to delete thiss concentration?"
                );
                if (!confirmed) return;
                let response = await api.archiveConcentrationOffering(row.id);
                refresh();
                return response;
              },
              requiredPermission:
                PermissionsEnum.ArchiveConcentrationOfferingFromProgramOffering,
            },
            {
              icon: "undo",
              tooltip: "Restore concentration",
              onClick: async (_e: any, row: any) => {
                if (!row.archived) {
                  alert("Concentration not deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to restore this concentration?"
                );
                if (!confirmed) return;
                let respose = await api.restoreConcentrationOffering(row.id);
                refresh();
                return respose;
              },
              requiredPermission:
                PermissionsEnum.RestoreConcentrationOfferingToProgramOffering,
            },
          ]}
        />
      </ComponentGuard>
      <ComponentGuard
        requiredPermissions={[
          PermissionsEnum.ViewProgramOfferingGradingSchemas,
        ]}
      >
        <CollectionComponent
          title="Grading schemas"
          loading={loading}
          viewOnly
          data={data?.gradingSchemas || []}
          columns={[
            {
              title: "Name",
              field: "gradingSchema.name",
            },
            {
              title: "Starting from",
              render: RenderDate(["createdAt"]),
              field: "createdAt",
              editable: "never",
            },
          ]}
          itemName="grading schema"
          disableKey="archived"
          actions={[
            {
              onClick: (_: any, row: any) =>
                history.push(`/grading-schema/${row.gradingSchema?.id}`),
              icon: () => <OpenInNew />,
              tooltip: "View grading schema",
              requiredPermission: PermissionsEnum.ViewGradingSchemas,
            },
          ]}
        />
      </ComponentGuard>
    </React.Fragment>
  );
};

export default ProgramOffering;
