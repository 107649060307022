import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/departments.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import extractErrorText from "../util/functions/extractErrorText";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import apiCourse from "../api/courses.api";
import ComponentGuard from "../components/guards/ComponentGuard";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import findOptions from "../api/interfaces/findOptions.interface";

const Department = () => {
  const { id } = useParams<{ id: string }>();
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = useFindRequestQueryParams((options: findOptions) => api.getCourses(+id, options), "departmentCourses");
  const [department, setDepartment] = useState({} as any);
  // const [loading, setLoading] = useState(false);
  const history = useHistory();


  useEffect(() => {
    loadDepartment();
    data = data?.map((entity: any) => {
      for (let translation of entity.translations) {
        entity[`${translation.language}_name`] = translation.name;
        entity[`${translation.language}_desc`] = translation.description;
      }
      return entity;
    });
  }, [data]);

  async function loadDepartment() {
    try {
      // setLoading(true);
      const dep = await api.findOne(+id);
      await parseDepartment(dep);
      // setLoading(false);
    } catch {
      history.push("/");
    }
  }

  async function parseDepartment(response: AxiosResponse) {
    const { data } = response;

    for (let translation of data.translations) {
      data[`${translation.language}_name`] = translation.name;
      data[`${translation.language}_desc`] = translation.description;
    }

    // for (let course of data.courses) {
    //   for (let translation of course.translations) {
    //     course[`${translation.language}_name`] = translation.name;
    //     course[`${translation.language}_desc`] = translation.description;
    //   }
    // }
    setDepartment(data);
  }

  // const refresh = loadDepartment;


  return (
    <React.Fragment>
      <Head title="Courses" path="/department" />
      <div className="buttons-section">
        <ComponentGuard requiredPermissions={[PermissionsEnum.CreateCourses]}>
          <Button
            color="primary"
            label="Add course"
            withForm
            form={{
              title: "Add Course",
              description:
                "Enter the details of the course you would like to add to this department.",
              apiRequest: (data: any) => {
                return api.addCourse(+id, data);
              },
              inputs: [
                {
                  type: "text",
                  name: "Code",
                  keyName: "code",
                  required: true,
                },
                {
                  type: "decimal",
                  name: "Credit Hours",
                  keyName: "creditHours",
                  required: true,
                },
                {
                  type: "text",
                  name: "English name",
                  keyName: "en_name",
                  required: true,
                },
                {
                  type: "text",
                  name: "English description",
                  keyName: "en_desc",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic name",
                  keyName: "ar_name",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic description",
                  keyName: "ar_desc",
                  required: true,
                },
              ],
              successMessage: () => {
                refresh();
                return "Course created successfully!";
              },
              errorMessage: extractErrorText,
              explicitCloseButton: true,
            }}
          />
        </ComponentGuard>
      </div>
      <SingleComponent
        title="Department"
        fields={[
          {
            name: "Code",
            value: department.code,
          },
          {
            name: "English name",
            value: department.en_name,
          },
          {
            name: "English description",
            value: department.en_desc,
          },
          {
            name: "Arabic name",
            value: department.ar_name,
          },
          {
            name: "Arabic description",
            value: department.ar_desc,
          },
        ]}
      />
      <br />
      <br />
      <ComponentGuard requiredPermissions={[PermissionsEnum.ViewCourses]}>
        <CollectionComponent
          title="Courses"
          loading={loading}
          data={data || []}
          columns={[
            {
              title: "Code",
              field: "code",
              editable: "never",
            },
            {
              title: "Credit Hours",
              field: "creditHours",
              editable: "never",
            },
            {
              title: "English name",
              field: "en_name",
            },
            // {
            //   title: "English description",
            //   field: "en_desc",
            // },
            {
              title: "Arabic name",
              field: "ar_name",
            },
            /* // temp*/{
              title: 'Canopy Sequence Number',
              field: 'oldCode',
              editable: 'never'
            }, // end of // temp
            // {
            //   title: "Arabic description",
            //   field: "ar_desc",
            // },
          ]}
          backendPagination
          backendSearch
          pagesCount={pagesCount}
          page={page}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSearchChange={onSearchChange}
          searchText={search}
          pageSize={pageSize}
          viewDisabledInSeparateTab
          onHideChange={onHideChange}
          onRowDelete={async (oldData: any) => {
            if (oldData.archived) {
              alert("Course already deleted");
              return;
            }
            await apiCourse.remove(oldData.id);
            await refresh();
          }}
          onRowUpdate={async (input: any, oldData: any) => {
            await apiCourse.update(oldData.id, input);
            await refresh();
          }}
          actions={[
            {
              icon: "undo",
              tooltip: "Restore course",
              onClick: async (_e: any, data: any) => {
                if (!data.archived) {
                  alert("Course not deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to restore this course?"
                );
                if (!confirmed) return;
                await apiCourse.restore(data.id);
                refresh();
              },
            },
            {
              icon: OpenInNewIcon,
              tooltip: "View Course",
              onClick: (_e: any, data: any) => {
                let id = data.id;
                history.push(`/course/${id}`)
              },
            },
          ]}
          mainPermission={PermissionsEnum.ModifyCourses}
          itemName="course"
          disableKey="archived"
        />
      </ComponentGuard>
    </React.Fragment>
  );
};

export default Department;
