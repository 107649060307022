import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Head from '../components/Head';
import useDarkMode from 'use-dark-mode';
import '../styles/Settings.scss';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  changeLanguage
} from '../store/actions/settings.actions';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  affected: {
    textAlign: 'right',
  },
  unaffected: {
    flip: false,
    textAlign: 'right',
  },
}));

const Settings: React.FunctionComponent<{
  changeLanguage: Function,
  language: string
}> = (props) => {

  const classes = useStyles();

  const darkMode = useDarkMode(false);
  const { t } = useTranslation();

  const changeLanguage = async (language: string) => {
    await props.changeLanguage(language);
    window.location.reload();
  }

  return (
    <div className='settings-page'>
      <Head title='Settings' path='/settings' />
      <h1>{t('SETTINGS')}</h1>

      <div className='settings-list'>
        <div className='settings-list-item'>
          <h4 className='settings-item-label'>{t('DARK_MODE')}</h4>
          <div className='dark-mode-switch'>
            <Brightness7Icon />
            <Switch className={classes.unaffected} checked={darkMode.value} onChange={darkMode.toggle} />
            <Brightness3Icon />
          </div>
        </div>

        <div className='settings-list-item'>
          <h4 className='settings-item-label'>{t('LANGUAGE')}</h4>
          <Select
            value={props.language}
            defaultValue={props.language}
            onChange={(event) => changeLanguage('' + event.target.value)}>
            <MenuItem value='en'>{t('ENGLISH')}</MenuItem>
            <MenuItem value='ar'>{t('ARABIC')}</MenuItem>
          </Select>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language
  }
}

const mapDispatchToProps = {
  changeLanguage
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
