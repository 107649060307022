import axios from "axios";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import { generateRoutes } from "./base.api";
import findOptions from "./interfaces/findOptions.interface";

const getPrograms = async (id: number) => {
  return await axios.get(`/courses/${id}/programs`);
};

const openForProgram = async (id: number, data: any) => {
  return await axios.post(`/courses/${id}/programs`, data);
};

const getOfferings = async (id: number) => {
  return await axios.get(`/courses/${id}/offerings`);
};

const setDefaultPrice = async (id: number, data: any) => {
  return await axios.post(`/courses/${id}`, data);
};

const newOffering = async (id: number, data: any) => {
  return await axios.post(`/courses/${id}/offerings`, data);
};

const find = () => {
  return axios.get("/courses");
};

const getPrerequisites = (id: number, options: findOptions) =>
  axios.get(`/courses/${id}/prerequisites?${JSONToQueryParams(options)}`);

export const routes = {
  getPrograms,
  openForProgram,
  getOfferings,
  setDefaultPrice,
  newOffering,
  ...generateRoutes("/courses"),
  find,
  getPrerequisites,
};
export default routes;
