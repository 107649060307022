import axios from "axios";
import { generateRoutes } from "./base.api";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import findOptions from "./interfaces/findOptions.interface";

const routes = generateRoutes("/programs");

const addConcentration = (id: number, data: any) => {
  return axios.post(`/programs/${id}/concentration/`, data);
};

const removeConcentration = (id: number) => {
  return axios.delete(`/concentrations/${id}`);
};

const changeGradingSchema = (id: number, data: any) => {
  return axios.patch(`/programs/${id}/change-grading-schema`, data);
};

const restoreConcentration = (id: number) => {
  return axios.patch(`/concentrations/${id}/restore`);
};

const updateConcentration = (id: number, data: any) => {
  return axios.patch(`/concentrations/${id}`, data);
};

const setPricePerCreditHour = (id: number, data: any) => {
  return axios.patch(`/programs/${id}/setPricePerCreditHour`, data);
};

const createNewOffering = (id: number, data: any) => {
  return axios.post(`/programs/${id}/offering`, data);
};

const removeOffering = (id: number) => {
  return axios.delete(`/programs/offerings/${id}`);
};

const restoreOffering = (id: number) => {
  return axios.post(`/programs/offerings/${id}`);
};

const addCourse = (id: number, data: any) => {
  return axios.post(`/programs/${id}/course/`, data);
};

const removeProgramCourse = (id: number) => {
  return axios.delete(`/programCourses/${id}`);
};

const restoreProgramCourse = (id: number) => {
  return axios.patch(`/programCourses/${id}/restore`);
};

const getAll = () => axios.get("/programs/all");

const getAllOfferings = () => axios.get(`/programs/offerings/all`);

const getCurrentOfferings = () => axios.get(`/programs/offerings/current`);

const getAllCurrentOfferings = (options: findOptions) => axios.get(`/programs/offerings/current/all?${JSONToQueryParams(options)}`);

const closeRegistration = (id: number) =>
  axios.delete(`/programs/registration/${id}`);

const getPendingProgramChoices = () =>
  axios.get("/programs/program-choices/pending");

const approveChoice = (id: number) => {
  return axios.patch(`/programs/program-choices/approve/${id}`);
};

const rejectChoice = (id: number) => {
  return axios.patch(`/programs/program-choices/reject/${id}`);
};

const updateProgramCourse = (id: number, data: any) => axios.patch(`/programCourses/${id}`, data);

const addAdmissionRequirementsToProgram = (id: number, data: any) => axios.post(`/programs/${id}/admissionRequirements`, data);

const addFAQGroupsToProgram = (id: number, data: any) => axios.post(`/programs/${id}/faqGroups`, data);

const addVideosToProgram = (id: number, data: any) => axios.post(`/programs/${id}/videos`, data);

const removeAdmissionRequirementFromProgram = (id: number, admissionRequirementId: number) => axios.delete(`/programs/${id}/admissionRequirement/${admissionRequirementId}`);

const removeVideoFromProgram = (id: number, videoId: number) => axios.delete(`/programs/${id}/video/${videoId}`);

const removeFAQGroupFromProgram = (id: number, faqGroupId: number) => axios.delete(`/programs/${id}/faqGroup/${faqGroupId}`);

const getActive = async (options: findOptions) => {
  return axios.get(`/programs/active?${JSONToQueryParams(options)}`);
};

const getProgramCourses = (programId: number) => async (options: findOptions) => axios.get(`/programCourses/program/${programId}?${JSONToQueryParams(options)}`);

const getProgramConcentrations = (programId: number) => async (options: findOptions) => axios.get(`/concentrations/program/${programId}?${JSONToQueryParams(options)}`);

const getProgramOfferings = (programId: number) => async (options: findOptions) => axios.get(`/programs/${programId}/offerings?${JSONToQueryParams(options)}`);

const getProgramFaqGroups = (programId: number) => async (options: findOptions) => axios.get(`/faqs/groups/program/${programId}?${JSONToQueryParams(options)}`);

const getProgramAdmissionRequirements = (programId: number) => async (options: findOptions) => axios.get(`/admissionRequirements/program/${programId}?${JSONToQueryParams(options)}`);

const getProgramVideos = (programId: number) => async (options: findOptions) => axios.get(`/videos/program/${programId}?${JSONToQueryParams(options)}`);

const get = async (id: number) => {
  return axios.get(`/programs/get/${id}`);
};

const changeProgramReferralForm = (programId: number, data: unknown) => axios.patch(`/programs/${programId}/referralForm`, data);

const getProgramChoice = (id: number) => {
	return axios.get(`/programs/program-choices/${id}`);
}

const changeProgramInfo = (programId: number, data: any) => axios.post(`programs/info/${programId}`, data);

export default {
  changeProgramReferralForm,
  find: routes.find,
  update: routes.update,
  add: routes.add,
  findOne: routes.findOne,
  addConcentration,
  removeConcentration,
  updateConcentration,
  restoreConcentration,
  setPricePerCreditHour,
  removeOffering,
  restoreOffering,
  addCourse,
  removeProgramCourse,
  restoreProgramCourse,
  getAll,
  remove: routes.remove,
  restore: routes.restore,
  closeRegistration,
  createNewOffering,
  getAllOfferings,
  getPendingProgramChoices,
  approveChoice,
  rejectChoice,
  getCurrentOfferings,
  getAllCurrentOfferings,
  changeGradingSchema,
  updateProgramCourse,
  addAdmissionRequirementsToProgram,
  addFAQGroupsToProgram,
  addVideosToProgram,
  removeAdmissionRequirementFromProgram,
  removeVideoFromProgram,
  removeFAQGroupFromProgram,
  getActive,
  getProgramCourses,
  getProgramConcentrations,
  getProgramOfferings,
  getProgramFaqGroups,
  getProgramAdmissionRequirements,
  getProgramVideos,
  get,
  getProgramChoice,
  changeProgramInfo,
};
