import { Add } from "@material-ui/icons";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api/formSections.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import ComponentGuard from "../components/guards/ComponentGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { FormInputProps } from '../components/forms/Form';
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { MTableAction } from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import programsApi from "../api/programs.api";
import { IQuestion, questionToType, typeToQuestionType } from "./ProgramForm";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { MTableBodyRow } from "material-table";
import { makeStyles } from "@material-ui/core";


export type FormSectionData = {
  id: string,
  name: string,
  section: {
    _id?: string,
    title: string,
    description?: string,
    questions: IQuestion[],
  }
}

const FormSection = () => {
  const { id } = useParams<{ id: string }>();
  const [dataa, setData] = useState<FormSectionData>({ id, name: '', section: { title: '', questions: [] } });
  const [loading, setLoading] = useState(false);
  const { control, errors } = useForm<{}>();
  const [programOptions, setProgramOptions] = useState<{ value: any, label: string }[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [viewDeleted, setViewDeleted] = useState<boolean>(false);
  const inputTypeOptions = [
    { label: "Text", value: 'text' },
    { label: "Multi text", value: 'multiText' },
    { label: "Number", value: 'number' },
    { label: "Decimal", value: 'decimal' },
    { label: "Email", value: 'email' },
    { label: "Select", value: 'select' },
    { label: "Select with auto complete", value: 'selectAutocomplete' },
    { label: "Multiple select with auto complete", value: 'multipleSelectAutocomplete' },
    { label: "Multi select", value: 'multiSelect' },
    { label: "Checkbox", value: 'checkbox' },
    { label: "Time", value: 'time' },
    { label: "Date time", value: 'dateTime' },
    { label: "File", value: 'file' },
  ];
  const fileTypeOptions = [
    { label: 'image', value: 'image' },
    { label: 'excel', value: 'excel' },
    { label: 'word', value: 'word' },
    { label: 'pdf', value: 'pdf' },
    { label: 'video', value: 'video' },
  ];

  const toInput: (data: FormSectionData | undefined) => FormInputProps[] = (data) => {
    let filteredQuestions = data?.section?.questions?.filter(question => question.deleted === false) || [];
    let renderedInputs = filteredQuestions.map(question => {
      let type = questionToType(question);
      let input = {
        required: question.required,
        fileType: question.fileType,
        name: question.question,
        type: type,
        upload: true,
        multiline: question.multipleLines,
        options: question.options?.map(option => ({ value: option, label: option })) || [],
        helperText: question.helperText,
        customControlName: question.customControlName,
        answerId: question.answerId,
      }
      return input;
    });
    return renderedInputs || [];
  }

  const classes = makeStyles({
    row: {
      opacity: 0.5,
    },
  })();

  useEffect(() => {
    loadForm();
    (async () => {
      let { data: { data } } = await programsApi.getAll();
      let programOptions: { value: any, label: string }[] = [];
      data?.forEach((program: any) => {
        programOptions.push({ label: program?.translations?.find((translation: any) => translation.language === 'en')?.name || 'NA', value: program?.id })
      });
      setProgramOptions(programOptions);
    })();
  }, []);

  async function loadForm() {
    try {
      setLoading(true);
      const section = await api.getFormSection(+id);
      parseFormSection(section);
      setLoading(false);
    } catch (e) {
      enqueueSnackbar('Could not load form section', {
        variant: "error",
      });
    }
  }

  async function parseFormSection(response: AxiosResponse) {
    const { data } = response;
    // if (!data.section?.questions || data.section.questions.length === 0) {
    //   let newRow: IQuestion = { type: 'textQuestion', question: "", required: true, deleted: false }
    //   setData({ ...data, section: { title: dataa?.section?.title || '', ...data.section, questions: [newRow] } });
    // } else
    setData(data);
  }

  return (
    <React.Fragment>
      <Head title="Form Section" path={`/form-section/${id}`} />
      <div className="buttons-section">
        <Button label='Preview Section' color='primary' withForm form={{
          inputs: toInput(dataa),
          title: dataa?.section?.title,
          description: dataa?.section?.description
        }}
        />
        <ComponentGuard requiredPermissions={[PermissionsEnum.ModifyFormSections]}>
          <Button color="primary" label="Import questions from a program's form" withForm form={{
            successMessage: () => 'Questions imported! Do not forget to save for import to take effect',
            title: 'Import questions from program form',
            description: "Select the Questions you want to import and the form you want to import from",
            inputs: [
              {
                type: 'selectAutocomplete',
                required: true,
                name: 'Program',
                keyName: 'programId',
                options: programOptions
              },
              {
                type: 'number',
                required: true,
                name: 'Insert index',
                keyName: 'insertIndex',
              },
              {
                type: 'number',
                required: true,
                name: 'start question',
                keyName: 'startIndex',
              },
              {
                type: 'number',
                required: true,
                name: 'end question',
                keyName: 'endIndex',
              },
            ],
            apiRequest: async (dataaa: any) => {
              let requestData = { startIndex: dataaa.startIndex, endIndex: dataaa.endIndex };
              let actualInsertIndex = dataaa.insertIndex;
              for (let i = 0; i < dataa?.section?.questions?.length || 0 && i + 1 <= actualInsertIndex; i++) {
                if (dataa.section.questions[i].deleted) actualInsertIndex++;
              }
              if (actualInsertIndex < 1 || actualInsertIndex - 1 > (dataa?.section?.questions?.length || 0)) {
                throw Error(`Could not insert form questions, invalid insert index: ${dataaa.insertIndex}`);
              }
              else {
                let { data } = await api.importQuestionsFromProgramForm(dataaa.programId, requestData);
                data?.questions?.forEach((question: IQuestion) => {
                  delete question._id;
                });
                let questions = dataa?.section ? [...dataa.section.questions] : [];
                questions.splice(actualInsertIndex - 1, 0, ...data.questions);
                setData({ id: dataa.id, name: dataa.name, section: { ...dataa.section, questions } });
              }
            },
            errorMessage: extractErrorText,
          }} />
        </ComponentGuard>
      </div>
      <SingleComponent
        title="Form Section"
        fields={[
          {
            name: "Name",
            value: dataa?.name,
            edit: {
              editPermissions: [PermissionsEnum.ModifyFormSections],
              form: {
                title: 'Edit section name',
                inputs: [
                  {
                    type: 'text',
                    name: 'Name',
                    keyName: 'name',
                    required: true,
                    multiline: false,
                    defaultValue: dataa?.name,
                  }
                ],
                apiRequest: async (data: any) => {
                  await api.editSectionName(+id, data);
                  setData({ id: dataa.id, section: { ...dataa.section }, name: data.name });
                },
              }
            },

          },
          {
            name: "Title",
            value: dataa?.section?.title || "",
            edit: {
              editPermissions: [PermissionsEnum.ModifyFormSections],
              form: {
                title: 'Edit section title',
                inputs: [
                  {
                    type: 'text',
                    name: 'Title',
                    keyName: 'title',
                    required: true,
                    multiline: false,
                    defaultValue: dataa?.section?.title,
                  }
                ],
                apiRequest: (data: any) => {
                  setData({
                    id: dataa.id,
                    name: dataa.name,
                    section: {
                      ...dataa.section,
                      title: data.title,
                    }
                  })
                },
              }
            },

          },
          {
            name: "Description",
            value: dataa?.section?.description || "",
            displayLineBreaks: true,
            edit: {
              editPermissions: [PermissionsEnum.ModifyFormSections],
              form: {
                title: 'Edit section description',
                inputs: [
                  {
                    type: 'text',
                    name: 'Description',
                    keyName: 'description',
                    required: true,
                    multiline: true,
                    defaultValue: dataa?.section?.description || ''
                  }
                ],
                apiRequest: (data: any) => {
                  setData({
                    id: dataa.id,
                    name: dataa.name,
                    section: {
                      ...dataa.section,
                      description: data.description,
                    }
                  })
                },
              }
            },
          },
        ]}
      />
      <br />
      <br />
      <CollectionComponent
        title="Section Questions"
        loading={loading}
        mainPermission={PermissionsEnum.ModifyFormSections}
        data={dataa?.section?.questions?.filter(question => viewDeleted ? question.deleted : !question.deleted)}
        noDelete
        onRowUpdate={(newData: any, oldData: any) => {
          let actualOldId = oldData.tableData.id;
          for (let i = 0; i < dataa.section.questions.length && i <= actualOldId; i++) {
            if (dataa.section.questions[i].deleted) actualOldId++;
          }
          delete newData.new;
          setData((prevData: FormSectionData) => {
            let questions: IQuestion[] = [...prevData?.section?.questions || []];
            questions.splice(actualOldId, 1, newData);
            if (newData.number) {
              let number = newData.number;
              delete newData.number;
              if (number - 1 !== actualOldId)
                if (number < 1 || number > (dataa?.section?.questions?.filter(q => !q.deleted).length || 0))
                  enqueueSnackbar(`Could not reorder section questions, invalid question number: ${number}`, { variant: 'error' });
                else {
                  let questionToBeMoved = questions.splice(actualOldId, 1);
                  let actualNumber = number;
                  for (let i = 0; i < dataa.section.questions.length && i + 1 <= actualNumber; i++) {
                    if (dataa.section.questions[i].deleted) actualNumber++;
                  }
                  questions.splice(actualNumber - 1, 0, questionToBeMoved[0]);
                }
            }
            let title = prevData?.section?.title || "";
            return {
              ...prevData,
              section: {
                ...prevData.section,
                title,
                questions
              }
            }
          });
        }}
        onRowEditCancelled={(rowData: any) => {
          let actualOldId = rowData.tableData.id;
          for (let i = 0; i < dataa.section.questions.length && i <= actualOldId; i++) {
            if (dataa.section.questions[i].deleted) actualOldId++;
          }
          let f = dataa?.section?.questions || [];
          if (f.length > actualOldId && f[actualOldId]?.new) {
            f.splice(actualOldId, 1);
            setData({ ...dataa, section: { ...dataa?.section, questions: f } });
          }
        }}
        columns={[
          {
            title: 'Number',
            field: 'number',
            render: (rowData) => { return rowData.tableData.id + 1 },
            editComponent: (row) => {
              if (row.rowData.deleted) return <div />;
              return InputFactory('number', {
                id: 'form-section-question-number',
                control,
                errors,
                name: ' ',
                defaultValue: row?.rowData?.tableData?.id + 1,
                onChange: (event: any) => {
                  row.rowData.number = event.target.value;
                }
              })
            }
          },
          {
            title: "Type",
            field: "type",
            render: (data: any) => {
              return questionToType(data);
            },
            editComponent: (row) => {
              return (InputFactory('selectAutocomplete', {
                id: 'form-section-question-type',
                control,
                name: 'type',
                errors,
                options: inputTypeOptions,
                onChange: (newValue: any) => {
                  let type = newValue?.value;
                  return row.rowData.type = typeToQuestionType(type);
                },
                defaultValue: (() => {
                  return questionToType(row.rowData);
                })(),
              }))
            },
          },
          {
            title: "Question",
            field: "question"
          },
          {
            title: "Helper Text",
            field: "helperText",
            editComponent: (row: any) => {
              return InputFactory('text', {
                id: 'form-section-question-helper-text',
                multiline: true,
                required: true,
                name: 'Helper Text',
                control,
                errors,
                defaultValue: row.rowData.helperText || '',
                onChange: (event: any) => {
                  row.rowData.helperText = event.target.value;
                }
              })
            },
          },
          {
            title: "Selection Options",
            field: "options",
            render: (row) => {
              let renderedString = "";
              if (row?.options)
                for (let option of row.options) {
                  renderedString += (option + ', ');
                }
              renderedString = renderedString.substring(0, renderedString.length - 2)
              return renderedString;
            },
            editComponent: (row) => {
              let renderedString = "";
              if (row?.value)
                for (let option of row.value) {
                  renderedString += (option + '\n');
                }
              return (InputFactory('text', {
                id: 'form-section-question-selection-options',
                control,
                name: 'options',
                errors,
                multiline: true,
                defaultValue: renderedString,
                onChange: (event: any) => {
                  let asArray = event.target.value.split('\n');
                  row.rowData.options = asArray;
                }
              }))
            }
          },
          {
            title: "File Type",
            field: "fileType",
            render: (row) => {
              return row.fileType ? Array.isArray(row.fileType) ? row.fileType.join(', ') : row.fileType : '-';
            },
            editComponent: (row) => {
              return (InputFactory('multipleSelectAutocomplete', {
                id: 'form-section-question-file-type',
                control,
                name: 'fileType',
                errors,
                options: fileTypeOptions,
                onChange: (newValues: any) => {
                  return row.rowData.fileType = newValues?.map((newValue: any) => newValue.value);
                },
                defaultValue: row.rowData.fileType,
              }))
            },
          },
          {
            title: "Required",
            field: "required",
            render: RenderBoolean(['required']),
            editComponent: (row) => {
              return InputFactory('checkbox', {
                id: 'form-section-question-is-required',
                errors,
                control,
                name: 'required',
                onChange: (event: any) => { return row.rowData.required = event; },
                defaultValue: row.value,
              })
            }
          },
          {
            title: "Multiple Line",
            field: "multipleLines",
            render: RenderBoolean(['multipleLines']),
            editComponent: (row) => {
              return InputFactory('checkbox', {
                id: 'form-section-question-is-multiple-lines',
                errors,
                control,
                name: 'multi line',
                onChange: (event: any) => { return row.rowData.multipleLines = event; },
                defaultValue: row.value,
              })
            }
          },
          {
            title: "Custom Control Name",
            field: "customControlName",
            editComponent: (row) => {
              return InputFactory('text', {
                id: 'form-section-custom-control-name',
                errors,
                control,
                name: 'Custom Control Name',
                onChange: (event: any) => { return row.rowData.customControlName = event.target.value; },
                defaultValue: row.value || '',
                multiline: false,
              })
            }
          },
        ]}
        renderRow={(p: any) => {
          return viewDeleted ? (p?.data?.deleted ? <MTableBodyRow className={classes.row} {...p} /> : <React.Fragment />) : (p?.data?.deleted ? <React.Fragment /> : <MTableBodyRow {...p} />)
        }}
        actions={[
          {
            icon: VisibilityIcon,
            tooltip: 'Toggle view disabled',
            onClick: () => {
              setViewDeleted(!viewDeleted);
            },
            iconCondition: () => viewDeleted,
            secondaryIcon: VisibilityOffIcon,
            isFreeAction: true,
          },
          {
            icon: "undo",
            tooltip: "Restore section question",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to restore this section question?"
              );
              if (!confirmed) return;
              let actualOldId = data.tableData.id;
              for (let i = 0; i < dataa.section.questions.length && i <= actualOldId; i++) {
                if (!dataa.section.questions[i].deleted) actualOldId++;
              }
              setData((prevData: FormSectionData) => {
                let questions: IQuestion[] = [...prevData?.section?.questions || []];
                if (actualOldId < 0 || actualOldId >= questions.length) throw new Error();
                let question = questions[actualOldId];
                if (question) question.deleted = false;
                let title = prevData?.section?.title || "";
                return {
                  ...prevData,
                  form: {
                    ...prevData.section,
                    title,
                    questions
                  }
                }
              });
            },
            render: (p: any) => {
              if (p.data.deleted === false) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.ModifyFormSections]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Archive section question",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to delete this section question?"
              );
              if (!confirmed) return;
              let actualOldId = data.tableData.id;
              for (let i = 0; i < dataa.section.questions.length && i <= actualOldId; i++) {
                if (dataa.section.questions[i].deleted) actualOldId++;
              }
              setData((prevData: FormSectionData) => {
                let questions: IQuestion[] = [...prevData?.section?.questions || []];
                if (actualOldId < 0 || actualOldId >= questions.length) throw new Error();
                let question = questions[actualOldId];
                if (question) question.deleted = true;
                let title = prevData?.section?.title || "";
                return {
                  ...prevData,
                  form: {
                    ...prevData.section,
                    title,
                    questions
                  }
                }
              });
            },
            render: (p: any) => {
              if (p.data.deleted === true) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.ModifyFormSections]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
          {
            onClick: (_: any, row: any) => {
              let actualOldId = row.tableData.id;
              for (let i = 0; i < dataa.section.questions.length && i <= actualOldId; i++) {
                if (dataa.section.questions[i].deleted) actualOldId++;
              }
              let f = dataa?.section?.questions || [];
              let newRow = { type: 'textQuestion', name: '', question: "", required: true, new: true, deleted: false };
              f.splice(actualOldId + 1, 0, (newRow as IQuestion));
              setData({ ...dataa, section: { ...dataa?.section, questions: f } });
              (newRow as any).tableData = { editing: 'update' };
            },
            icon: () => <Add />,
            tooltip: "Add new question below",
            condition: (row: any) => !row.deleted
          },
          {
            onClick: (_: any, row: any) => {
              let f = dataa?.section?.questions || [];
              let newRow = { type: 'textQuestion', name: '', question: "", required: true, new: true, deleted: false };
              f.splice(f.length, 0, (newRow as IQuestion));
              setData({ ...dataa, name: dataa?.name || '', section: { ...dataa?.section, title: dataa?.section?.title || "", questions: f } });
              (newRow as any).tableData = { editing: 'update' };
            },
            icon: () => <Add />,
            tooltip: "Add new question",
            isFreeAction: true,
          },
        ]}
      />

      <ComponentGuard requiredPermissions={[PermissionsEnum.ModifyFormSections]}>
        <div><Button label='Save Section' color='primary' handleClick={async () => {
          try {
            const { data } = await api.editFormSection(+id, dataa);
            setData(data);
            enqueueSnackbar('Section edited successfuly!', {
              variant: "success",
            });
          } catch (error: any) {
            enqueueSnackbar(await extractErrorText(error as Err), {
              variant: 'error',
            });
          }
        }} /></div>
      </ComponentGuard>
    </React.Fragment>
  );
};

export default FormSection;
