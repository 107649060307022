import React from 'react';

const Guard: React.FunctionComponent<{
  condition: boolean | undefined,
  fallback?: React.ReactNode
}> = props => {
  return (
    <React.Fragment>
      {props.condition ? props.children : props.fallback}
    </React.Fragment>
  )
}

export default Guard;
