import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import api from "../api/courseOfferingBasedRoles.api";
import Head from "../components/Head";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderArray from "../components/CollectionComponent/CustomRender/RenderArray";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import axios from "axios";
import { handleResponseError } from "./recipesDatabase/utils";
import { CourseOfferingPermissionsEnum } from "../util/enum/courseOfferingPermissions.enum";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import { useForm } from "react-hook-form";

const CourseOfferingBasedRoles = () => {
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange
  ] = useFindRequestQueryParams(api.find, "courseOfferingBasedRoles");

  let { control, errors } = useForm<{}>();

  let [perms, setPerms] = useState([]);

  useEffect(() => {
    (async () => {
      let { data: allperms } = await axios.get("/courseOfferingBasedRoles/permissions");
      setPerms(allperms.permissions);
    })();
  }, []);

  return (
    <React.Fragment>
      <Head title="Course Based Roles" path="/courseOfferingBasedRoles" />
      <div className="buttons-section">
        <Button
          color="primary"
          label="Create Course Role"
          withForm
          form={{
            title: "Create Course Role",
            description:
              "Enter the details of the course role you would like to create. After completion, you will be able to assign this role to faculty members.",
            apiRequest: api.add,
            inputs: [
              {
                type: "text",
                name: "Name",
                keyName: "name",
                required: true,
              },
              {
                type: "multipleSelectAutocomplete",
                name: "Permissions",
                keyName: "permissions",
                required: true,
                options: Object.values(CourseOfferingPermissionsEnum).map((permission) => {
                  return {
                    label: permission,
                    value: permission,
                  };
                }),
              },
            ],
            successMessage: () => {
              refresh();
              return "Course Role created successfully!";
            },
            errorMessage: handleResponseError,
          }}
        />
      </div>
      <CollectionComponent
        title="Course Roles"
        loading={loading}
        data={data}
        columns={[
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Permissions",
            field: "permissions",
            render: RenderArray(["permissions"]),
            editComponent: (props: any) => {
              return InputFactory('multipleSelectAutocomplete', {
                id: 'course-role-permissions',
                control,
                errors,
                name: 'permissions',
                defaultValue: props.value,
                options: perms.map((perm) => ({ value: perm, label: perm })),
                onChange: (value: any) => {
                  props.rowData.permissions = value.map((singleValue: any) => singleValue.value)
                }
              });
              // return (
              //   <MultiSelectInput
              //     id={'course-role-permissions'}
              //     defaultValue={props.value}
              //     options={perms.map((perm) => ({
              //       value: perm,
              //       label: perm,
              //     }))}
              //     onChange={(e: any) => {
              //       props.onChange(e.target.value);
              //     }}
              //   />
              // );
            },
          },
        ]}
        backendPagination
        viewDisabledInSeparateTab
        onHideChange={onHideChange}
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowDelete={async (oldData: any) => {
          if (oldData.archived) {
            alert("Course Role already deleted");
            return;
          }
          await api.remove(oldData.id);
          refresh();
        }}
        onRowUpdate={async (input: any, oldData: any) => {
          await api.update(oldData.id, input);
          refresh();
        }}
        searchText={search}
        onSearchChange={(text: string) => {
          onSearchChange(text);
        }}
        disableKey="archived"
        actions={[
          {
            icon: "undo",
            tooltip: "Restore Course Role",
            onClick: async (_e: any, data: any) => {
              if (!data.archived) {
                alert("Course Role not deleted");
                return;
              }
              const confirmed = window.confirm(
                "Are you sure you want to restore this course role?"
              );
              if (!confirmed) return;
              await api.restore(data.id);
              refresh();
            },
          },
        ]}
        mainPermission={PermissionsEnum.ModifyCourseOfferingBasedRoles}
      />
    </React.Fragment>
  );
};

export default CourseOfferingBasedRoles;
