import { useSnackbar } from "notistack";
import React from "react";
import { Route } from "react-router-dom";
import Home from "../../pages/Home";
import { PermissionsEnum } from "../../util/enum/permissions.enum";
import PermissionsGuard from "./PermissionsGuard";

const PermissionsRoute: React.FunctionComponent<{
  path: string;
  requiredPermissions: PermissionsEnum[];
  component: React.ReactNode;
  verbose?: boolean;
}> = (props) => {
  let { enqueueSnackbar } = useSnackbar();
  return (
    <Route path={props.path}>
      <PermissionsGuard
        children={props.component}
        requiredPermissions={props.requiredPermissions}
        fallback={<Home />}
        fallbackFunc={() => {
          if (props.verbose !== false) {
            enqueueSnackbar("Unauthorized access to " + props.path, {
              variant: "error",
            });
          }
        }}
      />
    </Route>
  );
};

export default PermissionsRoute;
