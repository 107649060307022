import axios from "axios";
import { generateRoutes } from "./base.api";

const generatedRoutes = generateRoutes("/videos");
export default {
    delete: generatedRoutes.remove,
    // restore: generatedRoutes.restore,
    // update: generatedRoutes.update,
    create: generatedRoutes.add,
    find: generatedRoutes.find,
    getAll: () => axios.get('/videos/all'),
}
