import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import {
  archive,
  create,
  edit,
  find,
  unarchive,
} from "../api/gradingSchemas.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { useHistory } from "react-router-dom";
import { OpenInNew } from "@material-ui/icons";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import DeleteIcon from "@mui/icons-material/Delete";
import { MTableAction } from "material-table";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import React from "react";
import Head from "../components/Head";
import Button from "../components/Button";
import extractErrorText from "../util/functions/extractErrorText";

const GradingSchemas = () => {
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
  ] = useFindRequestQueryParams(find, "gradingSchemas");

  const history = useHistory();

  return (
    <React.Fragment>
      <Head title="Grading Schemas" path="/grading-schemas" />
      <div className="buttons-section">
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.CreateGradingSchemas]}
        >
          <Button
            color="primary"
            label="Create Grading Schema"
            withForm
            form={{
              title: "Create Grading Schema",
              description:
                "Enter the details of the grading schema you would like to create.",
              apiRequest: create,
              inputs: [
                {
                  type: "text",
                  name: "Name",
                  keyName: "name",
                  required: true,
                },
              ],
              successMessage: () => {
                refresh();
                return "Grading schema created successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </PermissionsGuard>
      </div>
      <CollectionComponent
        title="Grading Schemas"
        loading={loading}
        data={data}
        columns={[
          {
            title: "Name",
            field: "name",
          },
        ]}
        backendPagination
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        disableKey="archived"
        noDelete
        onRowUpdate={async (input: any, oldData: any) => {
          await edit(oldData.id, input);
          refresh();
        }}
        actions={[
          {
            onClick: (_: any, row: any) =>
              history.push(`/grading-schema/${row.id}`),
            icon: () => <OpenInNew />,
            requiredPermission: PermissionsEnum.ViewGradingSchemas,
          },
          {
            icon: "undo",
            tooltip: "Restore grading schema",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to restore this grading schema?"
              );
              if (!confirmed) return;
              await unarchive(data.id);
              refresh();
            },
            render: (p: any) => {
              if (p.data.archived === false) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.RestoreGradingSchema]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Archive grading schema",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to delete this grading schema?"
              );
              if (!confirmed) return;
              await archive(data.id);
              refresh();
            },
            render: (p: any) => {
              if (p.data.archived === true) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.ArchiveGradingSchema]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
        ]}
        mainPermission={PermissionsEnum.ModifyGradingSchemas}
      />
    </React.Fragment>
  );
};

export default GradingSchemas;
