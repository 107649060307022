import axios from "axios";

const remove = (userId: number, courseOfferingId: number, roleId: number) => axios.delete(`/courseStaff/${userId}/${courseOfferingId}/${roleId}`);

const edit = (userId: number, courseOfferingId: number, data: { roleIds: number[] }) => axios.patch(`courseStaff/${userId}/${courseOfferingId}`, data);

export default {
  delete: remove,
  edit
};
