import React, { useEffect, useState } from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import api from "../api/programs.api";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import Head from "../components/Head";
import Button from "../components/Button";
import extractErrorText from "../util/functions/extractErrorText";
import axios from "axios";
import { handleResponseError, toOptions } from "./recipesDatabase/utils";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router";
import ComponentGuard from "../components/guards/ComponentGuard";
import DateTimeInput from "../components/forms/inputs/DateTimeInput";
import { useForm } from "react-hook-form";
import DeleteIcon from "@material-ui/icons/Delete";
import { InputFactory } from "../components/forms/inputs/InputFactory";

const Programs = () => {
  let [admissionRequirements, setAdmissionRequirements] = useState([]);
  let [FAQs, setFAQs] = useState([]);
  const history = useHistory();

  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = useFindRequestQueryParams(api.find, "programs");

  useEffect(() => {
    (async () => {
      // Get admission requirements
      let {
        data: { data: requirements },
      } = await axios.get("/admissionRequirements/all");

      setAdmissionRequirements(toOptions(requirements));

      // Get FAQs
      let {
        data: { data: faqs },
      } = await axios.get("/faqs/all");
      setFAQs(toOptions(faqs, ["translations"], "question"));
    })();
  }, []);

  // Format languages in format of `${language}Name` so it can be editable
  useEffect(function () {
    data = data?.map((entity: any) => {
      for (let translation of entity.translations) {
        entity[`${translation.language}Name`] = translation.name;
      }
      return entity;
    });
  });

  const { control, handleSubmit, errors, reset } = useForm<{
    inputs: [];
  }>();

  return (
    <React.Fragment>
      <Head title="Programs" path="/programs" />
      <div className="buttons-section">
        <ComponentGuard requiredPermissions={[PermissionsEnum.CreatePrograms]}>
          <Button
            color="primary"
            label="Create Program"
            withForm
            form={{
              title: "Create Program",
              description:
                "Enter the details of the program you would like to create.",
              apiRequest: api.add,
              inputs: [
                {
                  type: "text",
                  name: "Code",
                  keyName: "code",
                  required: true,
                },
                {
                  type: 'decimal',
                  name: 'Credit Hours',
                  keyName: 'creditHours',
                  required: true,
                },
                {
                  type: "text",
                  name: "English name",
                  keyName: "enName",
                  required: true,
                },
                {
                  type: "text",
                  name: "English description",
                  keyName: "enDescription",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic name",
                  keyName: "arName",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic description",
                  keyName: "arDescription",
                  required: true,
                },
                {
                  type: "multiText",
                  name: "Video Links",
                  keyName: "vids",
                  required: false,
                },
                {
                  type: "multiSelect",
                  name: "Admission Requirements",
                  keyName: "admissionRequirementsIDs",
                  required: false,
                  options: admissionRequirements,
                },
                {
                  type: "multiSelect",
                  name: "FAQs",
                  keyName: "faqIDs",
                  required: false,
                  options: FAQs,
                },
                {
                  type: 'checkbox',
                  required: false,
                  name: 'Is Under Graduate',
                  keyName: 'isUnderGraduate',
                }
              ],
              successMessage: () => {
                refresh();
                return "Program created successfully!";
              },
              errorMessage: extractErrorText,
              explicitCloseButton: true,
            }}
          />
        </ComponentGuard>
      </div>
      <CollectionComponent
        title="Programs"
        loading={loading}
        data={data}
        noDelete
        columns={[
          {
            title: "Name",
            field: "enName",
            customFilterAndSearch: () => true,
          },
          {
            title: "Arabic Name",
            field: "arName",
            customFilterAndSearch: () => true,
          },
          {
            title: "Code",
            field: "code",
            customFilterAndSearch: () => true,
            editable: "never",
          },
          {
            title: "Credit Hours",
            field: "creditHours",
            editable: 'never',
          },
          {
            title: 'Graduate',
            field: 'isUnderGraduate',
            render: (row) => row.isUnderGraduate ? 'No' : 'Yes',
            editComponent: (props) => InputFactory('checkbox', {
              control,
              errors,
              id: 'program-is-undergraduate',
              name: 'Graduate',
              customControlName: 'isUnderGraduate',
              onChange: (e: any) => {
                props.rowData.isUnderGraduate = !e;
              },
              defaultValue: !(props?.value),
            })
          },
          {
            title: "Registration Deadline",
            field: "courseRegistrationDeadline",
            render: (data) =>
              data.courseRegistrationDeadline
                ? data.courseRegistrationDeadline.toString()
                : "-",
            editComponent: (props: any) => {
              let currentDeadline = props.rowData.courseRegistrationDeadline;
              return (
                <DateTimeInput
                  id={'program-wide-course-registration-deadline'}
                  name={"deadline"}
                  defaultValue={currentDeadline}
                  control={control}
                  errors={errors}
                  onChange={(newValue: any) => {
                    props.rowData.courseRegistrationDeadline = newValue;
                  }}
                />
              );
            },
          },
        ]}
        backendPagination
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        onHideChange={onHideChange}
        viewDisabledInSeparateTab
        itemName="program"
        disableKey="archived"
        onRowUpdate={async (input: any, oldData: any) => {
          let updateBody = {} as any;
          if (input.enName !== oldData.enName) updateBody.enName = input.enName;
          if (input.arName !== oldData.arName) updateBody.arName = input.arName;
          updateBody.courseRegistrationDeadline =
            input.courseRegistrationDeadline;
          updateBody.isUnderGraduate = input.isUnderGraduate;
          await api.update(oldData.id, updateBody);
          refresh();
        }}
        actions={[
          {
            onClick: (_: any, row: any) => history.push(`/program/${row.id}`),
            icon: () => <OpenInNew />,
          },
          {
            icon: "lock",
            tooltip: "Close registration session for program",
            requiredPermission: PermissionsEnum.ModifyPrograms,
            onClick: async (e: any, data: any) => {
              let confirmed = window.confirm(
                `Are you sure you want to close the registration session for this program?`
              );
              if (confirmed) {
                await api.closeRegistration(data.id);
                refresh();
              }
            },
            errorMessage: handleResponseError,
          },
          {
            icon: "undo",
            tooltip: "Restore program",
            onClick: async (e: any, data: any) => {
              let confirmed = window.confirm(
                `Are you sure you want to restore this program?`
              );
              if (confirmed) {
                await api.restore(data.id);
                refresh();
              }
            },
            errorMessage: handleResponseError,
            requiredPermission: PermissionsEnum.RestorePrograms,
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Archive program",
            requiredPermission: PermissionsEnum.ArchivePrograms,
            onClick: async (_e: any, data: any) => {
              if (data.archived) {
                alert("Program already deleted");
                return;
              }
              const confirmed = window.confirm(
                "Are you sure you want to delete this program?"
              );
              if (!confirmed) return;
              await api.remove(data.id);
              refresh();
            },
          },
        ]}
        mainPermission={PermissionsEnum.ModifyPrograms}
      />
    </React.Fragment>
  );
};

export default Programs;
