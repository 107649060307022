import { OpenInNew } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import api from "../api/departments.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import Head from "../components/Head";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import extractErrorText from "../util/functions/extractErrorText";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import DeleteIcon from '@material-ui/icons/Delete';


const Departments = () => {
  let [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = useFindRequestQueryParams(api.find, "departments");

  const history = useHistory();

  // Format languages in format of `${lang}_name` and `${lang}_desc` so it can be editable
  useEffect(function () {
    data = data?.map((entity: any) => {
      for (let translation of entity.translations) {
        entity[`${translation.language}_name`] = translation.name;
        entity[`${translation.language}_desc`] = translation.description;
      }
      return entity;
    });
  });

  return (
    <React.Fragment>
      <Head title="Departments" path="/departments" />
      <div className="buttons-section">
        <PermissionsGuard requiredPermissions={[PermissionsEnum.CreateDepartments]}>
          <Button
            color="primary"
            label="Create Department"
            withForm
            form={{
              title: "Create Department",
              description:
                "Enter the details of the department you would like to create.",
              apiRequest: api.add,
              inputs: [
                {
                  type: "text",
                  name: "Code",
                  keyName: "code",
                  required: true,
                },
                {
                  type: "text",
                  name: "English name",
                  keyName: "en_name",
                  required: true,
                },
                {
                  type: "text",
                  name: "English description",
                  keyName: "en_desc",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic name",
                  keyName: "ar_name",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic description",
                  keyName: "ar_desc",
                  required: true,
                },
              ],
              successMessage: () => {
                refresh();
                return "Department created successfully!";
              },
              errorMessage: extractErrorText,
              explicitCloseButton: true,
            }}
          />
        </PermissionsGuard>
      </div>
      <CollectionComponent
        title="Departments"
        loading={loading}
        data={data}
        columns={[
          {
            title: "Code",
            field: "code",
            editable: 'never',
          },
          {
            title: "English name",
            field: "en_name",
          },
          {
            title: "English description",
            field: "en_desc",
          },
          {
            title: "Arabic name",
            field: "ar_name",
          },
          {
            title: "Arabic description",
            field: "ar_desc",
          },
        ]}
        viewDisabledInSeparateTab
        onHideChange={onHideChange}
        backendPagination
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        disableKey="archived"
        noDelete
        onRowUpdate={async (input: any, oldData: any) => {
          await api.update(oldData.id, input);
          refresh();
        }}
        actions={[
          {
            onClick: (_: any, row: any) =>
              history.push(`/department/${row.id}`),
            icon: () => <OpenInNew />,
          },
          {
            icon: "undo",
            tooltip: "Restore department",
            requiredPermission: PermissionsEnum.RestoreDepartments,
            onClick: async (_e: any, data: any) => {
              if (!data.archived) {
                alert("Department not deleted");
                return;
              }
              const confirmed = window.confirm(
                "Are you sure you want to restore this department?"
              );
              if (!confirmed) return;
              await api.restore(data.id);
              refresh();
            }
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Archive department",
            requiredPermission: PermissionsEnum.DeleteDepartments,
            onClick: async (_e: any, data: any) => {
              if (data.archived) {
                alert("Department already deleted");
                return;
              }
              const confirmed = window.confirm(
                "Are you sure you want to delete this department? Deleting a department will also delete all the courses provided by this department!"
              );
              if (!confirmed) return;
              await api.remove(data.id);
              refresh();
            }
          }
        ]}
        mainPermission={PermissionsEnum.EditDepartmentTranslations}
      />
    </React.Fragment>
  );
};

export default Departments;
