import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/videos.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import { useForm } from "react-hook-form";

const Videos = () => {
    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
    ] = useFindRequestQueryParams(api.find, "videos");

    return (
        <React.Fragment>
            <Head title="Videos" path="/videos" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.CreateVideos]}>
                    <Button
                        color="primary"
                        label="Add Video"
                        withForm
                        form={{
                            title: "Add Video",
                            description:
                                "Add a new video.",
                            apiRequest: api.create,
                            inputs: [
                                {
                                    name: "Name",
                                    keyName: "name",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: "File Name",
                                    keyName: "uniqueFileName",
                                    type: 'file',
                                    fileType: 'video',
                                    required: true,
                                    upload: true,
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Video added successfully!";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <CollectionComponent
                title="Videos"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                    },
                    {
                        title: "File Name",
                        field: "uniqueFileName",
                    },
                    {
                        title: "Link",
                        field: "url",
                    }
                ]}
                backendPagination
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                itemName="video"
                noEdit
                searchText={search}
                onRowDelete={async (data: any) => {
                    let resp = await api.delete(data.id);
                    refresh();
                    return resp;
                }}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
                mainPermission={PermissionsEnum.DeleteVideos}
            />

        </React.Fragment>
    )
};

export default Videos;
