import { ClassNameMap, CSSProperties, makeStyles } from "@material-ui/styles";
import { Chip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import enrollmentApi from "../api/enrollment.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import Head from "../components/Head";
import extractErrorText, { Err } from "../util/functions/extractErrorText";

interface Transcript {
  semesters: TranscriptSemester[];
  totalCreditHours: number;
  totalCompletedCreditHours: number;
  gpa?: number;
  letterGrade?: string;
}

type SemesterCourse = {
  code: string;
  creditHours: number;
  gpa?: number;
  letterGrade: string;
  name: string;
  percentage?: number;
};

type Semester = {
  semester: string;
  year: number;
};

type TranscriptSemester = {
  courses: SemesterCourse[];
  creditHours: number;
  completedCreditHours: number;
  gpa?: number;
  letterGrade?: string;
  semester: Semester;
};

const styles = {
  semesterTotal: {
    justifyContent: "end",
    marginTop: '-1%',
    paddingRight: '1%',
    marginBottom: '1%',
    display: "flex",
    gap: 5,
  },
  bold: {
    fontWeight: 600
  },
  subtitle: {
    fontSize: "1.15rem !important",
    lineHeight: "1.2 !important"
  },
  separate: {
    margin: "0 0 10px !important"
  }
}

const SemesterGrades = (props: {
  classes: ClassNameMap<keyof typeof styles>,
  transcriptSemester: TranscriptSemester;
}) => {
  return (<>
    <CollectionComponent
      title={`${props.transcriptSemester.semester.semester} ${props.transcriptSemester.semester.year}`}
      data={props.transcriptSemester.courses}
      columns={[
        {
          title: "Course code",
          field: "code"
        },
        {
          title: "Course name",
          field: "name"
        },
        {
          title: "Credit hours",
          field: "creditHours"
        },
        {
          title: "Percentage",
          render: (rowData) => rowData.percentage !== undefined ? `${rowData.percentage}%` : '-'
        },
        {
          title: "Letter grade",
          field: "letterGrade"
        }
      ]}
      viewOnly
      loading={false}
    />
    <div className={props.classes.semesterTotal}>
      <Chip color="primary" className={props.classes.bold} label={`Attempted Credit Hours: ${props.transcriptSemester.courses.length > 0 ? props.transcriptSemester.creditHours : '-'}`} />
      <Chip color="primary" className={props.classes.bold} label={`Completed Credit Hours: ${props.transcriptSemester.courses.length > 0 ? props.transcriptSemester.completedCreditHours : '-'}`} />
      <Chip color="primary" className={props.classes.bold} label={`Letter grade: ${props.transcriptSemester.letterGrade || '-'}`} />
    </div>
  </>
  );
};

const UnofficialTranscript: React.FunctionComponent<{ language?: string }> = (props) => {
  const { id: enrollmentId } = useParams<{ id: string }>();
  const classes = makeStyles(styles)();
  const { enqueueSnackbar } = useSnackbar();
  let { data, isLoading, isError } = useQuery(
    ["transcript", +enrollmentId],
    async () => {
      let transcript: Transcript = (
        await enrollmentApi.makeTranscript({
          enrollmentId: +enrollmentId,
          language: props.language,
        })
      ).data;

      return transcript;
    },
    { onError: async (e) => enqueueSnackbar(await extractErrorText(e as Err), { variant: 'error' }) },
  );
  if (isLoading || isError || !data) return <></>;

  return (<>
    <Head path={`/unofficial-transcript/${enrollmentId}`} title="Unofficial Transcript" />
    {data.semesters/*.filter(semester => semester.courses.length > 0)*/.map((semester) => {
      return <SemesterGrades classes={classes} transcriptSemester={semester} />;
    })}
    <Typography variant="h4" className={classes.separate}>Total</Typography>
    <Typography variant="body1" className={classes.subtitle}>Total Attempted Credit Hours: {data?.semesters?.length ? data.totalCreditHours : '-'}</Typography>
    <Typography variant="body1" className={classes.subtitle}>Total Completed Credit Hours: {data?.semesters?.length ? data.totalCompletedCreditHours : '-'}</Typography>
    <Typography variant="body1" className={classes.subtitle}>Gpa: {data.gpa !== undefined ? data.gpa : '-'}</Typography>
    <Typography variant="body1" className={classes.subtitle}>Letter grade: {data.letterGrade !== undefined ? data.letterGrade : '-'}</Typography>
  </>)
}

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(UnofficialTranscript);
