import { CSSProperties } from "react";

const ThankYou: React.FunctionComponent<{ children?: never }> = () => {
  const style: CSSProperties = { fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans- serif', direction: 'rtl' };
  return (
    <>
      <p style={style}>المزكّي(ة) الفاضل(ة)،</p><p style={style}>

        نشكر ونقدّر لكم تعاونكم بإرسال رأيكم الخاص بتزكية المتقدمين للدراسة ببرامج كلية اللاهوت الإنجيلية بالقاهرة.</p><p style={style}>

        نحن نؤكد استلامنا خطابات التزكية/ التعليقات من قبلكم، وجاري فحصها بعناية. كما نطلب من حضراتكم مشاركتنا الصلاة للاسترشاد بمعونة الروح القدس وبآرائكم الحكيمة لاتخاذ القرار الذي يساعد خدام الرب على النمو في رحلة إيمانهم اللاهوتية، وفي معرفة أبعاد محبته الفائقة المعرفة، سواء من خلال الدراسة بكلية اللاهوت أو من أي باب آخر يفتحه الله أمام المتقدمين الذين لم/لن يُوفّقوا هذا العام.</p><p style={style}>

        نصلي أن يستخدم الرب أوانيكم المُعدّة لخدمته  لأجل استمرارية في التأصل إلى أسفل تنعكس في تأثير يمتد بإثمار واجتذاب من تخدمونهم لمجد الله وحده!</p>

    </>
  );
}

export default ThankYou;
