import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/semesters.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard, { CheckPermissions } from "../components/guards/ComponentGuard";
import { SemesterEnum } from "../util/enum/semester.enum";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";

const Semesters = (props: { userPermissions: PermissionsEnum[] }) => {
    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
    ] = useFindRequestQueryParams(api.find, "semesters");

    const { control, errors } = useForm<{
        inputs: [],
    }>();

    const { enqueueSnackbar } = useSnackbar();

    return (
        <React.Fragment>
            <Head title="Semesters" path="/semesters" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.CreateSemester]}>
                    <Button
                        color="primary"
                        label="Add Semester"
                        withForm
                        form={{
                            title: "Add Semester",
                            description:
                                "Add a new semester.",
                            apiRequest: api.create,
                            inputs: [
                                {
                                    name: "Semester",
                                    keyName: "semester",
                                    type: 'selectAutocomplete',
                                    required: true,
                                    options: Object.values(SemesterEnum).map(value => ({ value, label: value }))
                                },
                                {
                                    name: "Year",
                                    keyName: "year",
                                    type: 'number',
                                    required: true,
                                },
                                {
                                    name: "Start Date",
                                    keyName: "startDate",
                                    type: 'dateTime',
                                    required: true,
                                },
                                {
                                    name: "End Date",
                                    keyName: "endDate",
                                    type: 'dateTime',
                                    required: true,
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Semester added successfully.";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <CollectionComponent
                title="Semesters"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "Semester",
                        field: "semester",
                        editComponent: (row) => {
                            return InputFactory('selectAutocomplete', {
                                control, errors,
                                name: 'semester',
                                id: 'semester',
                                defaultValue: row.value,
                                options: Object.values(SemesterEnum).map(value => ({ value, label: value })),
                                required: true,
                                onChange: (event: any) => {
                                    row.rowData.semester = event?.value;
                                }
                            });
                        }
                    },
                    {
                        title: "Year",
                        field: "year",
                    },
                    {
                        title: "Start Date",
                        field: "startDate",
                        editComponent: (row) => InputFactory('dateTime', {
                            control, errors,
                            name: 'startDate',
                            id: 'startDate',
                            defaultValue: row.value,
                            required: true,
                            onChange: (event: any) => {
                                row.rowData.startDate = event;
                            }
                        }),
                    },
                    {
                        title: "End Date",
                        field: "endDate",
                        editComponent: (row) => InputFactory('dateTime', {
                            control, errors,
                            name: 'endDate',
                            id: 'endDate',
                            defaultValue: row.value,
                            required: true,
                            onChange: (event: any) => {
                                row.rowData.endDate = event;
                            }
                        }),
                    },
										{
												title: "Registration open until",
												field: "registrationOpenUntil",
                        editComponent: (row) => InputFactory('dateTime', {
                            control, errors,
                            name: 'registrationOpenUntil',
                            id: 'registrationOpenUntil',
                            defaultValue: row.value,
                            required: true,
                            onChange: (date: any) => {
                                row.rowData.registrationOpenUntil = date;
                            }
                        }),
												render: RenderDate(["registrationOpenUntil"]),
												editable: CheckPermissions(props.userPermissions || [], [PermissionsEnum.EditSemesterRegistrationOpen]) ? "always": "never"
										}
                ]}
                backendPagination
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                noDelete
                itemName="semester"
                onRowUpdate={async (input: any, oldData: any) => {
                    await api.update(oldData.id, input);
                    refresh();
                    enqueueSnackbar('Semester edited successfully!', { variant: 'success' });
                }}
                searchText={search}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
                disableKey="archived"
                mainPermission={PermissionsEnum.EditSemester}
            />

        </React.Fragment>
    )
};

const mapStateToProps = (store: any) => {
  return {
    userPermissions: store.UserReducer.permissions,
  };
};

export default connect(mapStateToProps)(Semesters);
