import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import api from "../api/rooms.api"

const Room = () => {
    let [room, setRoom] = useState<{
        building_id: number,
        name: string,
        id: number,
        capacity: number,
        building: any,
        courseOfferings: any[],
        archived: boolean,
    }>();
    

    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        (async () => {
            let { data: allrooms } = await api.findOne(+id);
            setRoom(allrooms);
        })();
    }, []);



    if (!room) {
        return (
            <React.Fragment />
        )
    }

    return (
        <React.Fragment>
            <Head title='Room' path={`/room/${id}`} />

            <SingleComponent
                title="Room info"
                fields={[
                    {
                        name: "Room name",
                        value: room.name,
                    },
                    {
                        name: "Capacity",
                        value: room.capacity,
                    },
                    {
                        name: "Building",
                        value: room.building.name,
                    },
                ]}
            />
        </React.Fragment>
    )

};

export default Room;