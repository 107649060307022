import React from 'react';
import { useController } from 'react-hook-form';
import TextField from "@material-ui/core/TextField";
import { InputComponentProps } from './InputComponentProps';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import '../../../styles/Form.scss';

const EmailInput: React.FunctionComponent<InputComponentProps> = (props) => {
  const emailPattern = /^[a-zA-Z0-9][a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/;
  const { field, meta } = useController({
    ...props,
    rules: {
      ...props.rules,
      validate: (value: string) => (value?.length > 0) ? (value.length >= 8 && emailPattern.test(value)) : true,
    },
    name: props.customControlName || props.name,
  });

  const { t } = useTranslation();

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className='input-label'>{props.name + (props.required ? "*" : "")}</InputLabel>
      <TextField
        id={props.id}
        error={meta.invalid}
        onChange={(value) => {
          if (props.onChange) props.onChange(value);
          return field.onChange(value);
        }}
        onBlur={field.onBlur}
        value={field.value}
        inputRef={field.ref}
        InputLabelProps={{
          shrink: true,
        }} />
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      {thisError?.type === 'required' && (<span className="error">{t("FORM_INPUT_REQUIRED")}</span>)}
      {thisError?.type === 'validate' && <span className='error'>{t("INVALID_EMAIL")}</span>}
    </React.Fragment>
  )
}

export default EmailInput;
