import React, { useEffect, /*useImperativeHandle,*/ useRef/*, forwardRef*/ } from "react";
import { useController } from "react-hook-form";
import { InputComponentProps } from "./InputComponentProps";
import { useTranslation } from "react-i18next";
import Guard from "../../guards/Guard";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import humanFileSize from "../../../util/functions/humanFileSize";
import "../../../styles/FileInput.scss";
import '../../../styles/Form.scss';

const FileInput: React.FunctionComponent<InputComponentProps> = /*forwardRef(*/(props/*, ref*/) => {
  const { field } = useController({
    ...props,
    defaultValue: props?.defaultValue ? { name: props.defaultValue } : "",
    rules: {
      ...props.rules,
    },
    name: props.customControlName || props.name,
  });

  let fileTypes: any = {
    image: "image/*",
    excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    pdf: '.pdf',
    word: '.docx',
    video: "video/mp4,video/x-m4v,video/*",
    directory: "*/*",
  };

  let chooseDirectory = props.fileType === "directory";
  let isMultifile = chooseDirectory || props.multiFile;

  let inputRef = useRef<any>(null);

  useEffect(() => {
    if (chooseDirectory) {
      inputRef.current.webkitdirectory = true;
      inputRef.current.directory = true;
      inputRef.current.mozdirectory = true;
      inputRef.current.nwdirectory = true;
    }
  }, [chooseDirectory]);

  const { t } = useTranslation();

  // useImperativeHandle(ref, () => ({
  //   updateResetName(name: string) {
  //     field.onChange({ name });
  //   }
  // }));

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className="input-label">{props.name + (props.required ? "*" : "")}</InputLabel>
      <div className="file-upload">
        <div className="file-names">
          <Guard
            condition={field.value}
            fallback={<p>No {chooseDirectory ? "folders" : "files"} currently selected</p>}
          >
            <List dense={true}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={isMultifile ? `${field.value.length} files selected` : field.value.name}
                  secondary={
                    field.value &&
                    (isMultifile
                      ? humanFileSize(
                        Array.from(field.value).reduce(
                          (total: number, curr: any) => total + curr.size,
                          0
                        )
                      )
                      : humanFileSize(field.value.size))
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => field.onChange("")}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Guard>
        </div>
        <div className="upload-button">
          <input
            accept={
              Array.isArray(props.fileType)
                ? props.fileType.map((type) => fileTypes[type]).join(",")
                : fileTypes[props.fileType || "image"]
            }
            id={props.id}
            multiple={props.multiFile}
            ref={inputRef}
            type="file"
            style={{
              display: "none",
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (props.onChange) props.onChange(event);
              const files = event?.currentTarget?.files || [];
              props.resetReset?.();
              field.onChange((isMultifile ? files : files?.[0]) || "");
            }}
          />
          <label htmlFor={props.id}>
            <IconButton color="primary" aria-label="upload picture" component="span">
              <Add />
            </IconButton>
          </label>
        </div>
      </div>
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      {thisError?.type === "required" && (
        <span className="error">{t("FORM_INPUT_REQUIRED")}</span>
      )}
      {thisError?.type === "validate" && (
        <span className="error">{t("INVALID")}</span>
      )}
    </React.Fragment>
  );
}/*)*/;
export default FileInput;
