import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import Home from "../../pages/Home";
import Guard from "./Guard";

const GuardedRoute: React.FunctionComponent<{
	path: string;
	component: React.ReactNode;
	condition: Function;
}> = (props) => {
	let { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const [condition, setCondition] = useState(undefined);
	useEffect(() => {
		(async () => {
			try {
				let condition = await props.condition();
				setCondition(condition);
				if (!condition) {
					enqueueSnackbar("Unauthorized access to " + props.path, {
						variant: "error",
					});
					history.push('/');
				}
			} catch {
				enqueueSnackbar("Unauthorized access to " + props.path, {
					variant: "error",
				});
				history.push('/');
			}
		})()
	}, []);
	return (
		<Route path={props.path}>
			{condition === undefined ? <></>
				: <Guard
					children={props.component}
					fallback={<Home />}
					condition={condition}
				/>
			}
		</Route>
	);
};

export default GuardedRoute;
