import { useQuery } from "react-query";
import enrollmentApi from "../api/enrollment.api";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Head from "../components/Head";
import { CSSProperties } from "@material-ui/styles";
import { makeStyles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import extractErrorText, { Err } from "../util/functions/extractErrorText";

interface Transcript {
  semesters: TranscriptSemester[];
  totalCreditHours: number;
  totalCompletedCreditHours: number;
  gpa?: number;
  letterGrade?: string;
}

type SemesterCourse = {
  code: string;
  creditHours: number;
  gpa?: number;
  letterGrade: string;
  name: string;
  percentage?: number;
};

type Semester = {
  semester: string;
  year: number;
};

type TranscriptSemester = {
  courses: SemesterCourse[];
  creditHours: number;
  completedCreditHours: number;
  gpa?: number;
  letterGrade?: string;
  semester: Semester;
};

const styles: {
  grid: CSSProperties,
  gridSpanLine: CSSProperties,
  topBar: CSSProperties,
  verticalSpace: CSSProperties,
  beforeLast: CSSProperties,
  creditHoursCol: CSSProperties,
} = {
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)"
  },
  gridSpanLine: {
    gridColumn: "1 / span 6"
  },
  topBar: {
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: "2px 0"
  },
  verticalSpace: {
    margin: "9px 0"
  },
  beforeLast: {
    gridColumn: "5"
  },
  creditHoursCol: {
    gridColumn: "3"
  }
};

const SemesterGrades = (props: {
  classes: ClassNameMap<keyof typeof styles>;
  transcriptSemester: TranscriptSemester;
}) => {
  return (
    <div className={props.classes.verticalSpace}>
      <Typography variant="h4" className={props.classes.gridSpanLine}>
        {props.transcriptSemester.semester.semester}{" "}
        {props.transcriptSemester.semester.year}
      </Typography>
      {props.transcriptSemester.courses.map((course) => {
        return <div className={`${props.classes.grid} ${props.classes.verticalSpace}`}>
          <Typography>{course.code}</Typography>
          <Typography>{course.name}</Typography>
          <Typography>{course.creditHours}</Typography>
          <Typography>{course.percentage !== undefined ? `${course.percentage}%` : "-"}</Typography>
          <Typography></Typography>
          <Typography>{course.letterGrade}</Typography>
        </div>;
      })}

      <div className={props.classes.grid}>
        <Typography variant="h6">Semester total</Typography>
        <Typography className={props.classes.creditHoursCol}>{props.transcriptSemester.courses.length > 0 ? props.transcriptSemester.completedCreditHours : "-"}</Typography>
        <Typography className={props.classes.beforeLast}></Typography>
        <Typography>{props.transcriptSemester.letterGrade || "-"}</Typography>
      </div>
    </div>
  );
};

const OfficialTranscript: React.FunctionComponent<{
  children?: never;
  language?: string;
}> = (props) => {
  const classes = makeStyles(styles)();
  const { id: enrollmentId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  let { data, isLoading, isError } = useQuery(
    ["transcript", +enrollmentId],
    async () => {
      let transcript: Transcript = (
        await enrollmentApi.viewStudentTranscript({
          enrollmentId: +enrollmentId,
          language: props.language,
        })
      ).data;

      return transcript;
    },
    { onError: async (e) => enqueueSnackbar(await extractErrorText(e as Err), { variant: 'error' }) },
  );
  if (isLoading) return <span>Loading...</span>;
  if (!isError)
    return (
      <>
        <Head path={`/official-transcript/${enrollmentId}`} title="Transcript" />
        <div className={`${classes.grid} ${classes.topBar}`}>
          <Typography>Course code</Typography>
          <Typography>Course name</Typography>
          <Typography>Credit hours</Typography>
          <Typography>Percentage</Typography>
          <Typography>Gpa</Typography>
          <Typography>Letter grade</Typography>
        </div>
        {data?.semesters.map((semester) => {
          return <><br /><SemesterGrades classes={classes} transcriptSemester={semester} /></>;
        })}
        <br />
        <div className={classes.grid}>
          <Typography variant="h4">Total</Typography>
          <Typography className={classes.creditHoursCol}>{data?.semesters?.length ? data?.totalCompletedCreditHours : "-"}</Typography>
          <Typography className={classes.beforeLast}>{data?.gpa ?? "-"}</Typography>
          <Typography>{data?.letterGrade || "-"}</Typography>
        </div>
      </>
    );
  return <></>
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(OfficialTranscript);
