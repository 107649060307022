import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/faqs.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React, { useEffect, useState } from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { MTableAction } from "material-table";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router";

const FaqGroups = () => {
    let [
        data,
        pagesCount,
        loading,
        page,
        pageSize,
        search,
        onPageChange,
        onPageSizeChange,
        onSearchChange,
        refresh,
        onHideChange,
    ] = useFindRequestQueryParams(api.findGroups, "faqGroups");
    const history = useHistory();
    const [faqs, setFaqs] = useState<any[]>([]);

    useEffect(function () {
        (async () => {
            const { data: { data } } = await api.getAll();
            setFaqs(data);
        })();
    }, []);
    const { enqueueSnackbar } = useSnackbar();
    return (
        <React.Fragment>
            <Head title="Faq Groups" path="/faqGroups" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.CreateFAQGroup]}>
                    <Button
                        color="primary"
                        label="Add Faq Group"
                        withForm
                        form={{
                            title: "Add Faq Group",
                            description:
                                "Add a new faq group.",
                            apiRequest: api.createGroup,
                            inputs: [
                                {
                                    name: "Name",
                                    keyName: "name",
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    name: "FAQs",
                                    keyName: "faqIds",
                                    type: 'multipleSelectAutocomplete',
                                    required: true,
                                    options: faqs.map ? faqs?.map(faq => { return { value: faq.id, label: faq?.translations?.find((translation: any) => translation?.language === 'en')?.question || '-' }; }) : [],
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Faq Group added successfully.";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <CollectionComponent
                title="Faq Groups"
                loading={loading}
                data={data}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                    }
                ]}
                backendPagination
                backendSearch
                pagesCount={pagesCount}
                page={page}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                noDelete
                itemName="faqGroup"
                onRowUpdate={async (input: any, oldData: any) => {
                    await api.updateGroup(oldData.id, input);
                    refresh();
                    enqueueSnackbar('Faq Group edited successfully!', { variant: 'success' });
                }}
                searchText={search}
                onSearchChange={(text: string) => {
                    onSearchChange(text);
                }}
                disableKey="archived"
                mainPermission={PermissionsEnum.EditFAQGroup}
                viewDisabledInSeparateTab
                onHideChange={onHideChange}
                actions={[
                    {
                        icon: "undo",
                        tooltip: "Restore FAQ Group",
                        onClick: async (e: any, data: any) => {
                            let confirmed = window.confirm(
                                `Are you sure you want to restore this FAQ Group?`
                            );
                            if (confirmed) {
                                await api.restoreGroup(data.id);
                                enqueueSnackbar('FAQ Group restored successfully!', { variant: 'success' });
                                refresh();
                            }
                        },
                        errorMessage: handleResponseError,
                        requiredPermission: PermissionsEnum.RestoreFAQGroup,
                        render: (p: any) => {
                            if (p.data.archived === false) return <></>;
                            return (
                                <PermissionsGuard
                                    requiredPermissions={[PermissionsEnum.RestoreFAQGroup]}
                                >
                                    <MTableAction {...p} />
                                </PermissionsGuard>
                            );
                        },
                    },
                    {
                        icon: () => <DeleteIcon />,
                        tooltip: "Archive FAQ Group",
                        requiredPermission: PermissionsEnum.ArchiveFAQGroup,
                        onClick: async (_e: any, data: any) => {
                            if (data.archived) {
                                alert("FAQ Group already deleted");
                                return;
                            }
                            const confirmed = window.confirm(
                                "Are you sure you want to delete this FAQ Group?"
                            );
                            if (!confirmed) return;
                            await api.archiveGroup(data.id);
                            enqueueSnackbar('FAQ Group deleted successfully!', { variant: 'success' });
                            refresh();
                        },
                        render: (p: any) => {
                            if (p.data.archived === true) return <></>;
                            return (
                                <PermissionsGuard
                                    requiredPermissions={[PermissionsEnum.ArchiveFAQGroup]}
                                >
                                    <MTableAction {...p} />
                                </PermissionsGuard>
                            );
                        },
                    },
                    {
                        onClick: (_: any, row: any) => history.push(`/faqGroup/${row.id}`),
                        icon: () => <OpenInNew />,
                        tooltip: "View faq group",
                        requiredPermission: PermissionsEnum.ViewFAQGroup,
                    },
                ]}
            />

        </React.Fragment>
    )
};

export default FaqGroups;
