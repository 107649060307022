import axios from "axios";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import { ExportFormat } from "./base.api";
import findOptions from "./interfaces/findOptions.interface";

export const login = async (credentials: {
	email: string;
	password: string;
}) => {
	return axios.post(`/users/login/employee`, credentials);
};

export const signup = async (data: { email: string }) => {
	return axios.post(`/users/signup`, data);
};

export const findUsers = async (options: findOptions) => {
	return axios.get(
		`/users?page=${options.page}&page_size=${options.page_size}`
	);
};

export const findEmployees = async (options: findOptions) => {
	return axios.get(`/users/employees?${JSONToQueryParams(options)}`);
};

export const findInstructors = async (options: findOptions) => {
	return axios.get(
		`/users/instructors?page=${options.page}&page_size=${options.page_size}`
	);
};

export const findStudents = async (options: findOptions) => {
	return axios.get(`/users/students?${JSONToQueryParams(options)}`);
};

export const findAllStudents = async () => {
	return axios.get(`/users/students/all`);
};

export const findStudentsWithOutstandingPayment = async (
	options: findOptions
) => {
	return axios.get(
		`/users/students/outstanding-payments?${JSONToQueryParams(options)}`
	);
};

export const getStudentIdFromSeq = async (seq: number) => {
	return axios.get(`/users/students/with-seq/${seq}`);
}

export const closeRegistration = async (id: number) => {
	return axios.delete(`/users/students/${id}/registration`);
};

export const findEmployee = async (id: number) => {
	return axios.get(`/users/employee/${id}`);
};

export const edit = async (data: any) => {
	return axios.patch(`/users/`, {
		user: data,
	});
};

export const update = async (user: any) => {
	return axios.patch(`/users/update`, {
		user,
	});
};

export const editStudent = async (student: any) => {
	return axios.patch(`/users/students/update`, {
		student,
	});
};

export const inviteEmployee = async (details: { email: string }) => {
	return axios.post("/users/invite", details);
};

export const inviteInstructor = async (details: { email: string }) => {
	return axios.post("/users/inviteInstructor", details);
};

export const acceptInvitation = async (details: {
	firstName: string;
	lastName: string;
	password: string;
	email: string;
}) => {
	return axios.post(`/users/invitation?eml=${details.email}`, details);
};

export const forgetPassword = async (body: { email: string }) => {
	return axios.get(`/users/resetForgotten/${body.email}`);
};

export const resetPassword = async (body: {
	password: string;
	email: string;
}) => {
	return axios.post(`/users/resetForgotten?eml=${body.email}`, {
		password: body.password,
	});
};

export const banEmployee = async (id: number) => {
	return axios.post(`/users/ban/${id}`);
};

export const toggleBan = async (id: number) => {
	return axios.post(`/users/toggleBan/${id}`);
};

export const setStudentStatus = async (id: number, data: any) => {
	return await axios.post(`/users/set-student-status/${id}`, data);
};

export const getAllFacultyMembers = async () => {
	return axios.get(`/users/facultyProfiles/all`);
};

export const getStudent = (id: number) => {
	return axios.get(`/users/students/${id}`);
};

export const getUserStudentDetails = () => {
	return axios.get(`/users/student/details`);
};

export const getStudentOutstandingBalance = () => {
	return axios.get(`/users/student/outstanding-balance`);
}

export const enroll = (id: number, data: any) => {
	return axios.post(`/users/students/${id}/enroll`, data);
};

export const changeCurrentConcentrationChoice = (id: number, data: any) => {
	return axios.patch(`/users/student/concentration-choices/${id}`, data);
};

export const changeCurrentProgramChoice = (id: number, data: any) => {
	return axios.patch(`/users/student/program-choices/${id}`, data);
};

export const currentProgramConcentrationOfferings = () => {
	return axios.get("/users/student/current-program-concentration-choices");
};

export const changeCurrentScholarship = (
	id: number,
	data: { amount: number; comments?: string }
) => {
	return axios.post(`/users/student/${id}/add-scholarship/`, data);
};

export const canRegister = () => {
	return axios.get("/users/students/registration");
};

export const addExpense = (id: number, data: any) =>
	axios.post(`/users/students/${id}/addExpense`, data);

export const acceptScholarship = (id: number) => axios.post(`/users/student/scholarships/${id}/accept`);

export const rejectScholarship = (id: number) => axios.post(`/users/student/scholarships/${id}/reject`);

export const getStudentGrades = (id: number) => async (options: findOptions) => axios.get(`/users/students/${id}/grades?${JSONToQueryParams(options)}`);

export const exportStudents = (options: findOptions, format: ExportFormat) => {
	return axios.get(`/users/students/export?${JSONToQueryParams({ ...options, format })}`, { responseType: "blob" })
}

export function getStudentFinancialData(id: string, findOptions?: findOptions) {
	return axios.get(`/users/students/${id}/financial-data?${JSONToQueryParams(findOptions)}`);
}

export const getMoodleSignInUrl = () => axios.get('/users/student/moodlesigninurl');

export const hasMoodleAccess = () => axios.get('/users/student/moodleAccess');
export const exportStudentsWithOutstandingPayments = (options: findOptions, format: ExportFormat) => {
	return axios.get(`/users/students/outstanding-payments/export?${JSONToQueryParams({ ...options, format })}`, { responseType: "blob" })
}
export const generateOfficialTranscripts = (data: any) => axios.post("/enrollments/generate-official-transcripts", data/*, { responseType: "blob" }*/);

export const downloadGeneratedTranscripts = (zip: string) => axios.post("/enrollments/downloadGeneratedTranscripts", { zip }, { responseType: "blob" });

export const exportStudentEnrollments = (format: string) => axios.get(`/users/students/exportEnrollments?${JSONToQueryParams({ format })}`, { responseType: "blob" });

export const exportStudentScholarships = (format: string) => axios.get(`/users/students/exportScholarships?${JSONToQueryParams({ format })}`, { responseType: "blob" });

export const exportStudentGrades = (format: string) => axios.get(`/users/students/exportGrades?${JSONToQueryParams({ format })}`, { responseType: "blob" });

export const exportCourseStaff = (format: string) => axios.get(`/users/students/exportStaff?${JSONToQueryParams({ format })}`, { responseType: "blob" });

export const exportUsers = (options: findOptions, format: ExportFormat) => {
	return axios.get(`/users/employees/export?${JSONToQueryParams({ ...options, format })}`, { responseType: "blob" })
}

export function exportStudentsAcademicData(format: ExportFormat) {
	return axios.get(`/users/students/academic-data/export?${JSONToQueryParams({ format })}`, { responseType: "blob" })
}

export const getFacultyActiveCourses = () => axios.get('/users/faculty/my-active-courses');
export const getFacultyPastCourses = () => axios.get('/users/faculty/my-past-courses');
export const getFacultyStudents = () => axios.get('/users/faculty/my-students');