import { generateRoutes } from "./base.api";
import axios from "axios";

const addCourseHistoryToStudentProfile = (id: number, courseId: number) => {
    return axios.post(`/enrollments/${id}`, courseId);
}

const makeTranscript = ({
  enrollmentId,
  language,
}: {
  enrollmentId: number;
  language?: string;
}) => {
  return axios.post(`/enrollments/${enrollmentId}/unofficial-transcript`, {
    language,
  });
};

const viewStudentTranscript = ({
	enrollmentId,
	language,
}: {
	enrollmentId: number;
	language?: string;
}) => {
	return axios.post(`/enrollments/${enrollmentId}/official-transcript`, {
		language,
	});
};

const generateOfficialTranscript = ({
	enrollmentId,
	language,
}: {
	enrollmentId: number;
	language?: string;
}) => {
	return axios.post(`/enrollments/${enrollmentId}/generate-official-transcript`, {
		language,
	}, { responseType: "blob" });
};

export const getCurrentCourses = () => {
  return axios.get(`/enrollments/current-courses`);
}

export const dropCourse = (courseGradeId: number) => {
	return axios.post(`/enrollments/drop-course/${courseGradeId}`);
}

const addCommentToEnrollment = (id: number, comment: string) => axios.post(`/enrollments/comment/${id}`, { comment: comment });

export const routes = {
	...generateRoutes("/enrollments"),
	addCourseHistoryToStudentProfile,
	makeTranscript,
	getCurrentCourses,
	dropCourse,
	viewStudentTranscript,
	addCommentToEnrollment,
	generateOfficialTranscript
}

export default routes;
