import axios from "axios";
import { generateRoutes } from "./base.api";

const routes = generateRoutes("/courseOfferingBasedRoles");

const getActiveRoles = () => axios.get("/courseOfferingBasedRoles/active");

export default {
    ...routes,
    getActiveRoles,
};
