import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api, { exportCourseOfferingGrades } from "../api/courseOfferings.api";
import { getAllFacultyMembers } from "../api/users.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import extractErrorText, { Err } from '../util/functions/extractErrorText';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { WeekDaysEnum } from "../util/enum/weekDays.enum";
import { findAllTimeSlots } from "../api/timeSlots.api";
import { getAllRooms } from "../api/rooms.api";
import courseScheduleApi from "../api/courseSchedule.api";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import courseStaffApi from "../api/courseStaff.api";
import GradingIcon from "@mui/icons-material/Grading";
import Form, { FormInputType } from "../components/forms/Form";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import Guard from "../components/guards/Guard";
import { MTableAction } from "material-table";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import courseOfferingBasedRolesApi from '../api/courseOfferingBasedRoles.api';
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import { useForm } from "react-hook-form";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { useSnackbar } from "notistack";
import { GradeTypeEnum } from "../util/enum/gradeType.enum";
import moment from "moment";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ComponentGuard from "../components/guards/ComponentGuard";
import { CourseOfferingPermissionsEnum } from "../util/enum/courseOfferingPermissions.enum";

const CourseOffering = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [data, setData] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [facultyMemberOptions, setFacultyMemberOptions] = useState([] as any[]);
  const [courseFacultyMemberOptions, setCourseFacultyMemberOptions] = useState(
    [] as any[]
  );
  const [timeSlotOptions, setTimeSlotOptions] = useState([] as any[]);
  const [roomOptions, setRoomOptions] = useState([] as any[]);
  const { t } = useTranslation();
  const [selectedStudentProfile, setSelectedStudentProfile] = useState(null as any);
  const [courseRoles, setCourseRoles] = useState<{ name: string, id: number }[]>([]);
  const { control, errors } = useForm();
  const [courseGradeInput, setCourseGradeinput] = useState<{ name: string, keyName: string, required: boolean, type: FormInputType, options?: { value: string, label: string }[] }>();
  const [courseOfferingPermissions, setCourseOfferingPermissions] = useState<CourseOfferingPermissionsEnum[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  async function loadCourseOffering() {
    try {
      setLoading(true);
      let { data: offering } = await api.findOne(+id);
      let { data: courseOfferingPermissions } = await api.getCourseOfferingPermissions(+id);
      setCourseOfferingPermissions(courseOfferingPermissions.permissions);
      parseCourseOffering(offering);
      setLoading(false);
    } catch {
      history.push("/");
    }
  }

  function parseCourseOffering(offering: any) {
    setCourseGradeinput(offering.isPassOrFail ? {
      name: "Passed",
      type: "select",
      keyName: "passed",
      options: [{
        value: 'passed',
        label: 'Passed'
      }, {
        value: 'failed',
        label: 'Failed'
      }],
      required: true
    } : {
      name: "Percentage grade",
      required: true,
      type: "decimal",
      keyName: "percentageGrade",
    })
    for (let translation of offering.course.translations) {
      if (translation.language === props.language) {
        offering[`course__name`] = translation.name;
        offering[`course__description`] = translation.description;
      }
    }
    offering.staff = {};
    if (offering.staffRoles) {
      for (let staffRole of offering.staffRoles) {
        if (offering.staff[staffRole.userId]) offering.staff[staffRole.userId].roles.push(staffRole.role);
        else offering.staff[staffRole.userId] = { member: staffRole.facultyProfile, roles: [staffRole.role] };
      }
    }
    offering.staffRoles = [];
    for (let key of Object.keys(offering.staff)) offering.staffRoles.push(offering.staff[key]);
    delete offering.staff;
    setData(offering);
    setCourseFacultyMemberOptions(
      toOptions(
        offering.staffRoles,
        (p) => `${p.member.user.firstName} ${p.member.user.lastName}`,
        (p) => p.member.userId
      )
    );
  }

  function toOptions(
    data: any,
    label: (element: any) => string,
    value: (element: any) => string
  ) {
    let options: any[] = [];
    if (!data) return options;
    for (let piece of data) {
      let option = {} as any;
      option.label = label(piece);
      option.value = value(piece);
      options.push(option);
    }
    return options;
  }

  useEffect(() => {
    (async () => {
      loadCourseOffering();
      let { data: members } = await getAllFacultyMembers();
      setFacultyMemberOptions(
        toOptions(
          members,
          (p) => `${p.user.firstName} ${p.user.lastName}`,
          (p) => p.userId
        )
      );
      let {
        data: { data: timeSlots },
      } = await findAllTimeSlots();
      setTimeSlotOptions(
        toOptions(
          timeSlots,
          (p) => `${p.name}`,
          (p) => p.id
        )
      );
      let {
        data: { data: rooms },
      } = await getAllRooms();
      setRoomOptions(
        toOptions(
          rooms.filter((room: any) => !room.archived),
          (r) => `${r.building.name}: ${r.name}`,
          (r) => r.id
        )
      );
    })();
    try {
      (async () => {
        let { data: activeRoles } = await courseOfferingBasedRolesApi.getActiveRoles();
        setCourseRoles(activeRoles);
      })()
    } catch {
    }
  }, []);

  const refresh = loadCourseOffering;

  let renderScheduleSessionStaff = (row: any) => {
    let names: string = "";
    for (let member of row.facultyMembers) {
      names = names + member.user.firstName + " " + member.user.lastName + ", ";
    }
    return names.substring(0, names.length - 2);
  };

  return (
    <React.Fragment>
      <Head title="Course Offerings" path={`/course-offering/${id}`} />
      <SingleComponent
        title="Course Info"
        fields={[
          {
            name: t("CODE"),
            value: data.course_code,
          },
          {
            name: t("DEPARTMENT_ID"),
            value: data.department_id,
          },
          {
            name: t("NAME"),
            value: data.course__name,
          },
          {
            name: t("DESCRIPTION"),
            value: data.course__description,
          },
          {
            name: t("SEMESTER"),
            value: data.semester ? data.semester.semester : "-",
          },
          {
            name: t("YEAR"),
            value: data.semester ? data.semester.year : "-",
          },
          {
            name: t("IS_PASS_OR_FAIL"),
            value: RenderBoolean(["isPassOrFail"])(data),
          },
          {
            name: t("REGISTRATION_OPEN_UNTIL"),
            value: RenderDate(["registrationOpenUntil"])(data),
            edit: {
              form: {
                inputs: [
                  {
                    name: t("REGISTRATION_OPEN_UNTIL"),
                    keyName: "registrationOpenUntil",
                    type: "dateTime",
                    required: true
                  }
                ],
                apiRequest: async (data: any) => {
                  await api.edit(+id, data);
                  loadCourseOffering();
                },
                successMessage: () => "Edit successful",
                errorMessage: extractErrorText
              },
              editPermissions: [PermissionsEnum.EditCourseOfferingRegistrationOpen]
            }
          },
          {
            name: t("CAPACITY"),
            value: data.capacity,
            edit: {
              form: {
                inputs: [
                  {
                    name: t("CAPACITY"),
                    keyName: "capacity",
                    type: "number",
                    required: true
                  }
                ],
                apiRequest: async (data: any) => {
                  await api.edit(+id, data);
                  loadCourseOffering();
                },
                successMessage: () => "Edit sucessful",
                errorMessage: extractErrorText
              },
              editPermissions: [PermissionsEnum.EditCourseOfferingCapacity]
            },
          },
          {
            name: t("START_DATE"),
            value: RenderDate(["startDate"])(data),
            edit: {
              form: {
                inputs: [
                  {
                    name: t("START_DATE"),
                    keyName: "startDate",
                    type: "dateTime",
                    required: true
                  }
                ],
                apiRequest: async (data: any) => {
                  await api.edit(+id, data);
                  loadCourseOffering();
                },
                successMessage: () => "Edit successful",
                errorMessage: extractErrorText
              },
              editPermissions: [PermissionsEnum.EditCourseOfferingDates]
            }
          },
          {
            name: t("END_DATE"),
            value: RenderDate(["endDate"])(data),
            edit: {
              form: {
                inputs: [
                  {
                    name: t("END_DATE"),
                    keyName: "endDate",
                    type: "dateTime",
                    required: true
                  }
                ],
                apiRequest: async (data: any) => {
                  await api.edit(+id, data);
                  loadCourseOffering();
                },
                successMessage: () => "Edit successful",
                errorMessage: extractErrorText
              },
              editPermissions: [PermissionsEnum.EditCourseOfferingDates]
            }
          },
        ]}
      />
      <div className="buttons-section">
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.EditCourseStaff]}
        >
          <Button
            color="primary"
            label="Assign staff"
            withForm
            form={{
              title: "Assign staff",
              description:
                "Enter the details of the staff member(s) you would like to assign to this course offering.",
              apiRequest: async (data: any) => await api.assignStaff(+id, data),
              inputs: [
                {
                  type: "multipleSelectAutocomplete",
                  name: "Faculty members",
                  keyName: "facultyMemberIDs",
                  required: true,
                  options: facultyMemberOptions,
                },
                {
                  type: "multipleSelectAutocomplete",
                  name: "Roles",
                  keyName: "roleIds",
                  required: true,
                  options: courseRoles.map((role) => ({
                    label: role.name,
                    value: role.id,
                  })),
                },
              ],
              successMessage: () => {
                refresh();
                return "Staff member(s) assigned successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </PermissionsGuard>
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.EditCourseSchedules]}
        >
          <Button
            color="primary"
            label="Assign Schedule"
            withForm
            form={{
              title: "Assign Schedule",
              description: "Create the schedule for this course",
              apiRequest: async (data: any) =>
                await api.assignSchedule(+id, data),
              inputs: [
                {
                  type: "multiSelect",
                  name: "Faculty members",
                  keyName: "facultyMemberIds",
                  required: true,
                  options: courseFacultyMemberOptions,
                },
                {
                  type: "select",
                  name: "Week Day",
                  keyName: "day",
                  required: true,
                  options: Object.entries(WeekDaysEnum).map((e) => ({
                    label: e[0],
                    value: e[1],
                  })),
                },
                {
                  type: "select",
                  name: "Time Slot",
                  keyName: "timeSlotId",
                  required: true,
                  options: timeSlotOptions,
                },
                {
                  type: "number",
                  name: "Frequency",
                  keyName: "frequency",
                  required: true,
                },
                {
                  type: "select",
                  name: "Room",
                  keyName: "roomId",
                  required: true,
                  options: roomOptions,
                },
              ],
              successMessage: () => {
                refresh();
                return "Schedule session assigned successfully.";
              },
              errorMessage: extractErrorText,
              confirmation: {
                apiRequest: courseScheduleApi.available(+id),
                confirmationText:
                  "Possible schedule conflict detected, would you like to schedule session anyway?",
                notConfirmedMessage: "session not scheduled",
              },
            }}
          />
        </PermissionsGuard>
        <PermissionsGuard requiredPermissions={courseOfferingPermissions.includes(CourseOfferingPermissionsEnum.PullGradesFromMoodle) ? [] : [PermissionsEnum.PullMoodleGrades]}>
          <Button
            color="primary"
            label="Pull moodle grades"
            handleClick={async () => {
              try {
                const { data: { data, errors } } = await api.validateMoodleGrades(+id)
                let confirmedWithErrors = false;
                if (errors.length > 0) {
                  let errorText = errors?.join("\n\n");
                  if (data) {
                    errorText += "\n\nContinue?";
                    confirmedWithErrors = true;
                    if (!window.confirm(errorText)) return;
                  } else {
                    window.alert(errorText);
                    return;
                  }
                }
                if (!data) {
                  enqueueSnackbar('Nothing to pull', {
                    variant: "warning"
                  })
                  return;
                }
                if (!confirmedWithErrors) if (!window.confirm('Are you sure you want to continue?')) return;
                await api.confirmMoodleGrades(+id, data);
                refresh();
                enqueueSnackbar('Successfully pulled grades from moodle', {
                  variant: "success"
                })
              } catch (error) {
                enqueueSnackbar(await extractErrorText(error as Err), {
                  variant: "error"
                })
              }
            }}
          />
        </PermissionsGuard>
      </div>
      <br />
      <br />
      <CollectionComponent
        data={data.staffRoles}
        columns={[
          {
            title: "First Name",
            field: "member.user.firstName",
            editable: 'never',
          },
          {
            title: "Last Name",
            field: "member.user.lastName",
            editable: 'never',
          },
          {
            title: "Roles",
            field: "roles",
            render: (row) => row.roles.map((role: Object & { name: string }) => role.name).join(', '),
            editComponent: (props) => {
              return InputFactory('multipleSelectAutocomplete', {
                control,
                errors,
                name: 'Roles',
                customControlName: 'roles',
                options: courseRoles.map((role) => ({
                  label: role.name,
                  value: role.id,
                })),
                required: false,
                id: 'edit-staff-member-course-offering-roles',
                defaultValue: props.rowData.roles.map((r: any) => r.id),
                onChange: (row: any) => {
                  props.onChange(row.map((r: any) => ({ id: r.value })))
                },
              });
            }
          },
        ]}
        loading={loading}
        title="Assigned Staff"
        noDelete
        onRowUpdate={async (newData: any, oldData: any) => {
          let resp = await courseStaffApi.edit(newData.member.userId, +id, newData);
          await refresh();
          return resp;
        }}
        actions={[{
          icon: () => <AssignmentReturnIcon />,
          async onClick(_event, data) {
            if (!window.confirm("Are you sure you want to un-assign staff")) return;
            await api.removeStaff(+id, data.member.userId);
            await refresh();
          },
          requiredPermission: PermissionsEnum.EditCourseStaff,
          tooltip: 'unassign staff member'
        }]}
        mainPermission={PermissionsEnum.EditCourseStaff}
      />
      <CollectionComponent
        data={data.courseSchedules}
        columns={[
          {
            title: "Room",
            field: "room.name",
          },
          {
            title: "Day",
            field: "day",
          },
          // {
          //   title: "Time Slot",
          //   field: "timeSlot.name",
          // },
          {
            title: "Start",
            field: "timeSlot.start",
          },
          {
            title: "End",
            field: "timeSlot.end",
          },
          {
            title: "Frequency",
            field: "frequency",
          },
          {
            title: "Staff Members",
            field: "facultyMembers",
            render: renderScheduleSessionStaff,
          },
        ]}
        loading={loading}
        title="Schedule"
        noEdit
        onRowDelete={async (row: any) => {
          await courseScheduleApi.remove(row.id);
          await refresh();
        }}
        mainPermission={PermissionsEnum.EditCourseSchedules}
      />
      <ComponentGuard requiredPermissions={[PermissionsEnum.ViewCourseGrades]}>
        <Form
          inputs={courseGradeInput ? [courseGradeInput] : []}
          apiRequest={async (data: {
            credit: boolean;
            percentageGrade: number;
            passed: 'passed' | 'failed';
          }) => {
            if (!selectedStudentProfile) {
              alert("How did you open this?");
              return;
            }

            await api.grade(+id, {
              credit: data.passed === 'failed' || data.passed === 'passed',
              percentageGrade: data.percentageGrade,
              studentProfileId: selectedStudentProfile.userId,
              passed: data.passed,
            });
            await refresh();
          }}
          title={`Grade student "${selectedStudentProfile?.user?.firstName}" for course offering`}
          description="Grade student for course offering"
          withModal
          modalOpen={modalOpen}
          onModalClose={() => setModalOpen(false)}
        />
        <Form
          inputs={[{
            type: 'dateTime',
            name: 'Drop Date',
            keyName: 'dropDate',
            required: false,
            defaultValue: selectedStudentProfile?.courseGrade?.dropDate || Date.now(),
          }]}
          apiRequest={async (data: {
            dropDate: Date | null;
          }) => {
            if (!selectedStudentProfile) {
              alert("How did you open this?");
              return;
            }
            await api.manageStudentDrop(+id, selectedStudentProfile.userId, data);
            enqueueSnackbar('Drop date changed successfully!', { variant: 'success' });
            await refresh();
          }}
          title={`Manage course drop for student "${selectedStudentProfile?.user?.firstName}"`}
          description="Manage course drop for student for course offering"
          withModal
          modalOpen={modal2Open}
          onModalClose={() => setModal2Open(false)}
        />
        <CollectionComponent
          data={data.studentProfiles}
          title="Students"
          loading={loading}
          viewOnly
          export={{
            type: "custom",
            fn(options, format) {
              return exportCourseOfferingGrades(+id, options, format);
            },
            formats: ["csv", "pdf"]
          }}
          actions={[
            {
              icon: () => <GradingIcon />,
              onClick: (_: any, studentProfile: any) => {
                setSelectedStudentProfile(studentProfile);
                setModalOpen(true);
              },
              name: "grade",
              tooltip: "Grade student",
            },
            {
              icon: () => <CancelPresentationIcon />,
              onClick: (_: any, studentProfile: any) => {
                setSelectedStudentProfile(studentProfile);
                setModal2Open(true);
              },
              name: "drop",
              tooltip: "Manage student drop",
            },
            {
              icon: () => <LockIcon />,
              onClick: async (event: any, studentProfile: any) => {
                await api.lock(data.id, studentProfile.userId);
                await refresh();
              },
              name: "lock",
              tooltip: "Lock student grade",
            },
            {
              icon: () => <LockOpenIcon />,
              onClick: async (event: any, studentProfile: any) => {
                await api.unlock(data.id, studentProfile.userId);
                await refresh();
              },
              name: "unlock",
              requiredPermission: PermissionsEnum.UnlockCourseGrades,
              tooltip: "Unlock student grade",
            },
            {
              icon: () => <VisibilityOffIcon />,
              onClick: async (_: any, studentProfile: any) => {
                await api.hideFromTranscript(data.id, studentProfile.userId);
                await refresh();
              },
              name: "hideFromTranscript",
              tooltip: "Hide grade from transcript",
            },
            {
              icon: () => <VisibilityIcon />,
              onClick: async (_: any, studentProfile: any) => {
                await api.showInTranscript(data.id, studentProfile.userId);
                await refresh();
              },
              name: "showInTranscript",
              tooltip: "Show grade in transcript",
            },
          ]}
          customComponents={{
            Action: (actionProps: any) => {
              if (actionProps.action?.name === "grade") {
                if (actionProps.data?.courseGrade?.dropDate === null) {
                  let isLocked = actionProps.data?.courseGrade?.isLocked === true;

                  if (isLocked) {
                    return (
                      <PermissionsGuard
                        requiredPermissions={courseOfferingPermissions.includes(CourseOfferingPermissionsEnum.ModifyCourseGrades) ? [] : [PermissionsEnum.ModifyCourseGrades]}
                      >
                        <MTableAction {...actionProps} />
                      </PermissionsGuard>
                    );
                  }

                  return (
                    <Guard condition={!isLocked}>
                      <MTableAction {...actionProps} />
                    </Guard>
                  );
                } else {
                  return <></>;
                }
              } else if (actionProps.action?.name === "drop") {
                return (
                  <PermissionsGuard
                    requiredPermissions={[PermissionsEnum.ManageStudentCourseOfferingDrop]}
                  >
                    <MTableAction {...actionProps} />
                  </PermissionsGuard>
                );
              }
              else if (actionProps.action?.name === "lock") {
                if (actionProps.data.courseGrade?.isLocked === true) {
                  return <></>;
                }
                let isCourseStaffWithModifyGradePermission = courseOfferingPermissions.includes(CourseOfferingPermissionsEnum.ModifyCourseGrades);
                if (!isCourseStaffWithModifyGradePermission) {
                  return (
                    <PermissionsGuard
                      requiredPermissions={[PermissionsEnum.ModifyCourseGrades]}
                    >
                      <MTableAction {...actionProps} />
                    </PermissionsGuard>
                  );
                }
                return <MTableAction {...actionProps} />;
              } else if (actionProps.action?.name === "unlock") {
                if (actionProps.data.courseGrade?.isLocked === false) {
                  return <></>;
                }
                if (actionProps.action.requiredPermission) {
                  let isCourseStaffWithUnlockGradePermission = courseOfferingPermissions.includes(CourseOfferingPermissionsEnum.UnlockCourseGrades);
                  if (!isCourseStaffWithUnlockGradePermission)
                    return (
                      <PermissionsGuard
                        requiredPermissions={[
                          actionProps.action.requiredPermission,
                        ]}
                      >
                        <MTableAction {...actionProps} />
                      </PermissionsGuard>
                    );
                  else return <MTableAction {...actionProps} />;
                }
                return <MTableAction {...actionProps} />;
              } else if (actionProps.action?.name === "hideFromTranscript") {
                if (actionProps.data.courseGrade?.hiddenFromTranscript === true) {
                  return <></>;
                }
                return (
                  <PermissionsGuard
                    requiredPermissions={[
                      PermissionsEnum.HideCourseGradeFromTranscript,
                    ]}
                  >
                    <MTableAction {...actionProps} />
                  </PermissionsGuard>
                );
              } else if (actionProps.action?.name === "showInTranscript") {
                if (
                  actionProps.data.courseGrade?.hiddenFromTranscript === false
                ) {
                  return <></>;
                }
                return (
                  <PermissionsGuard
                    requiredPermissions={[
                      PermissionsEnum.ShowCourseGradeInTranscript,
                    ]}
                  >
                    <MTableAction {...actionProps} />
                  </PermissionsGuard>
                );
              }
              return <MTableAction {...actionProps} />;
            },
          }}
          columns={[
            {
              title: "First name",
              field: "user.firstName",
            },
            {
              title: "Last name",
              field: "user.lastName",
            },
            // {
            //   title: "Grade",
            //   render: (studentProfile) => studentProfile.courseGrade?.percentageGrade || "-",
            // },
            {
              title: "Grade",
              render: (studentProfile) => {
                const grade = studentProfile.courseGrade;
                if (grade.dropDate) {
                  return "Dropped";
                }

                if (!grade.type) {
                  return "-";
                }

                let map: Record<string, unknown> = {
                  [GradeTypeEnum.Credit]: "CR",
                  [GradeTypeEnum.Graded]: grade.percentageGrade || "-",
                  [GradeTypeEnum.PassOrFail]: grade.passed == null && "-"
                    || grade.passed ? "Passed" : "Failed"
                };

                return map[grade.type] || "-";
              }
            },
            // {
            //   title: "Credit",
            //   render: RenderBoolean(["courseGrade", "credit"]),
            // },
            // {
            //   title: "Passed",
            //   render: RenderNullableBoolean(["courseGrade", "passed"])
            // },
            {
              title: "Is locked",
              render: RenderBoolean(["courseGrade", "isLocked"]),
            },
            {
              title: "hidden from transcript",
              render: RenderBoolean(["courseGrade", "hiddenFromTranscript"]),
            },
            {
              title: 'Drop Date',
              render: (studentProfile) => studentProfile.courseGrade?.dropDate ? moment(studentProfile.courseGrade.dropDate).format("MMMM Do YYYY, h:mm:ss a") : "-",
            }
          ]}
        />
      </ComponentGuard>
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    language: store.SettingsReducer.language,
    user: store.UserReducer.user,
  };
};

export default connect(mapStateToProps)(CourseOffering);
