import React from 'react';
import { Route } from 'react-router-dom';
import AuthGuard from './AuthGuard';
import Login from '../Login';

const AuthRoute: React.FunctionComponent<{
  path: string,
  component: React.ReactNode
}> = props => {

  return (
    <Route path={props.path}>
      <AuthGuard children={props.component} fallback={(<Login />)}/>
    </Route>
  )
}

export default AuthRoute;
