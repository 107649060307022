import React from 'react';
import Guard from './Guard';
import { useMediaQuery } from 'react-responsive';

const DesktopGuard: React.FunctionComponent<{
  fallback?: React.ReactNode
}> = props => {

  const isDesktop = useMediaQuery({ query: `(min-width: 760px)` });

  return (
    <Guard condition={isDesktop} fallback={props.fallback}>
      {props.children}
    </Guard>
  )
}

export default DesktopGuard;
