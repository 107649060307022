import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import api from "../api/applicationProcesses.api"
import { IQuestion } from "./ProgramForm";
import { useSnackbar } from "notistack";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import { SemesterEnum } from "../util/enum/semester.enum";

interface FinancialAidRequestData {
    program: string;
    semester: { semester: SemesterEnum, year: number };
    questions: IQuestion[];
};

const FinancialAidData = () => {
    let [financialAidRequestData, setFinancialAidRequestData] = useState<FinancialAidRequestData[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        (async () => {
            try {
                let { data: { financialAidRequests } } = await api.viewFinanceSection(+id);
                if (financialAidRequests && Array.isArray(financialAidRequests))
                    setFinancialAidRequestData(financialAidRequests?.map((request: any) => {
                        let questions: IQuestion[] = [];
                        if (request?.financeSections)
                            for (let section of request.financeSections?.sections)
                                questions = questions.concat(section?.questions || []);
                        return {
                            program: request?.program?.code, semester: { semester: request?.semester?.semester, year: request?.semester?.year }, questions,
                        }
                    }));
            } catch (error) {
                enqueueSnackbar(await extractErrorText(error as Err), { variant: 'error' });
            }
        })();
    }, []);

    return (
        <React.Fragment>
            <Head title='Financial Aid Data' path={`/financialAidData/${id}`} />

            {financialAidRequestData?.map(single => <><SingleComponent
                title={"Financial Aid Request"}
                fields={[{ name: 'Program', value: single?.program }, { name: 'Semester', value: `${single?.semester?.semester} ${single?.semester?.year}` }].concat(single?.questions?.map(question => ({ name: question?.question, value: question?.defaultValue, displayLineBreaks: true, }))) || []}
            /><br /></>)}
        </React.Fragment>
    )

};

export default FinancialAidData;