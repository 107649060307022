import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  addGradePoint,
  archiveGradePoint,
  editGradePoint,
  findOne,
  unarchiveGradePoint,
} from "../api/gradingSchemas.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../components/Button";
import { MTableAction } from "material-table";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import { useForm } from "react-hook-form";

const GradingSchema = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { control, handleSubmit, errors, reset } = useForm<{
    inputs: [];
  }>();

  useEffect(() => {
    loadGradingSchema();
  }, []);

  async function loadGradingSchema() {
    try {
      setLoading(true);
      const gs = await findOne(+id);
      setData(gs.data);
      setLoading(false);
    } catch {
      history.push("/");
    }
  }

  const refresh = loadGradingSchema;

  return (
    <React.Fragment>
      <Head title="Grading schema" path="/grading-schema" />
      <div className="buttons-section">
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.ModifyGradingSchemas]}
        >
          <Button
            color="primary"
            label="Add grade point"
            withForm
            form={{
              inputs: [
                {
                  name: "Letter grade",
                  type: "text",
                  keyName: "letterGrade",
                  required: true,
                },
                {
                  name: "Minimum grade",
                  type: "decimal",
                  keyName: "minimumGrade",
                  required: true,
                },
                {
                  name: "GPA",
                  type: "decimal",
                  keyName: "gpa",
                  required: true,
                },
                {
                  name: 'Fail Grade',
                  keyName: 'isFail',
                  type: 'checkbox',
                  required: false,
                }
              ],
              apiRequest: async (data: any) => {
                await addGradePoint({
                  gradingSchemaId: +id,
                  ...data,
                });
                await refresh();
              },
            }}
          />
        </PermissionsGuard>
      </div>
      <SingleComponent
        title="Grading Schema"
        fields={[
          {
            name: "Name",
            value: data.name,
          },
        ]}
      />
      <br />
      <br />
      <CollectionComponent
        title="Grade points"
        loading={loading}
        data={data.grades || []}
        columns={[
          {
            title: "Letter grade",
            field: "letterGrade",
          },
          {
            title: "Minimum grade",
            field: "minimumGrade",
          },
          {
            title: "GPA",
            field: "gpa",
          },
          {
            title: 'Fail Grade',
            field: 'isFail',
            render: (row: any) => row.isFail ? 'Yes' : 'No',
            editComponent: (props) => InputFactory('checkbox', {
              control,
              errors,
              id: 'grade-is-fail',
              name: 'Fail Grade',
              customControlName: 'isFail',
              onChange: (e: any) => {
                props.rowData.isFail = e;
              },
              defaultValue: props?.value,
            })
          },
        ]}
        noDelete
        onRowUpdate={async (input: any, oldData: any) => {
          await editGradePoint(oldData.id, input);
          await refresh();
        }}
        actions={[
          {
            icon: "undo",
            tooltip: "Restore grade point",
            onClick: async (_e: any, data: any) => {
              await unarchiveGradePoint(data.id);
              refresh();
            },
            render: (p: any) => {
              if (p.data.archived === false) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.ModifyGradingSchemas]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete grade point",
            onClick: async (_e: any, data: any) => {
              await archiveGradePoint(data.id);
              await refresh();
            },
            render: (p: any) => {
              if (p.data.archived === true) return <></>;
              return (
                <PermissionsGuard
                  requiredPermissions={[PermissionsEnum.ModifyGradingSchemas]}
                >
                  <MTableAction {...p} />
                </PermissionsGuard>
              );
            },
          },
        ]}
        mainPermission={PermissionsEnum.ModifyGradingSchemas}
        itemName="grade point"
        disableKey="archived"
      />
    </React.Fragment>
  );
};

export default GradingSchema;
