import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import '../../../styles/Form.scss';

const SectionTitle: React.FunctionComponent<{ text: string }> = (props) => {
  return (
    <React.Fragment>
      <br />
      <InputLabel className="section-title" id="simple-section-title-label">{props.text}</InputLabel>
      <br />
    </React.Fragment>
  );
};

export default SectionTitle;
