import Head from "../components/Head";
import Button from "../components/Button";
import api from "../api/admissionRequirements.api";
import { handleResponseError } from "./recipesDatabase/utils";
import React, { useEffect, useState } from "react";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ComponentGuard from "../components/guards/ComponentGuard";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory, useParams } from "react-router-dom";
import videoApi from '../api/videos.api';
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import extractErrorText, { Err } from "../util/functions/extractErrorText";

const AdmissionRequirement = (props: any) => {
    const { id } = useParams<{ id: string }>();
    const [admissionRequirement, setAdmissionRequirement] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const getVideos = async () => {
        let { data: { data: videoData } } = await videoApi.getAll();
        setVideos(videoData || []);
    }
    const loadAdmissionRequirement = async () => {
        try {
            setLoading(true);
            let { data } = await api.findOne(+id);
            let translation = data?.translations?.find((t: any) => t.language === props.language);
            setAdmissionRequirement({ ...data, ...(translation ? { text: translation.text } : {}) });
            setLoading(false);
        } catch (error) {
            enqueueSnackbar(await extractErrorText(error as Err), { variant: 'error' });
            history.push('/');
        }
    }
    useEffect(() => {
        getVideos()
        loadAdmissionRequirement();
    }, []);
    const refresh = loadAdmissionRequirement;
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Head title="AdmissionRequirements" path="/admissionRequirements" />
            <div className="buttons-section">
                <ComponentGuard requiredPermissions={[PermissionsEnum.AddVideoToAdmissionRequirement]}>
                    <Button
                        color="primary"
                        label="Add Videos to Admission Requirement"
                        withForm
                        form={{
                            title: "Add Videos to Admission Requirement",
                            description:
                                "Add videos to this admission requirement.",
                            apiRequest: (data: any) => api.addVideoToAdmissionRequirement(+id, data),
                            inputs: [
                                {
                                    name: 'Videos',
                                    keyName: 'videos',
                                    required: true,
                                    type: 'multipleSelectAutocomplete',
                                    options: videos.map(video => ({ label: video.name, value: video.id })),
                                },
                            ],
                            successMessage: () => {
                                refresh();
                                return "Video(s) successfully added to Admission Requirement!";
                            },
                            errorMessage: handleResponseError,
                        }}
                    />
                </ComponentGuard>
            </div>
            <SingleComponent
                title={t("ADMISSION_REQUIREMENT")}
                fields={[
                    {
                        name: t("NAME"),
                        value: admissionRequirement.name,
                    },
                    {
                        name: t("TEXT"),
                        value: admissionRequirement.text,
                    },
                    {
                        name: t("ATTACHMENTS"),
                        value: admissionRequirement.attachments?.join(', ') || '-'
                    },
                ]}
            />
            <CollectionComponent
                title={t("VIDEOS")}
                loading={loading}
                data={admissionRequirement?.videos || []}
                columns={[
                    {
                        title: t("NAME"),
                        field: "name",
                    },
                    {
                        title: t("LINK"),
                        field: "url",
                    },
                ]}
                viewOnly
                itemName="admissionRequirement"
                actions={[
                    {
                        icon: () => <DeleteIcon />,
                        tooltip: "Remove Video from Admission Requirement",
                        requiredPermission: PermissionsEnum.RemoveVideoFromAdmissionRequirement,
                        onClick: async (_e: any, data: any) => {
                            const confirmed = window.confirm(
                                "Are you sure you want to delete this video form the Admission Requirement?"
                            );
                            if (!confirmed) return;
                            let resp = await api.removeVideoFromAdmissionRequirement(+id, data.id);
                            refresh();
                            return resp;
                        },
                    },
                ]}
            />

        </React.Fragment>
    )
};

const mapStateToProps = (store: any) => {
    return {
        language: store.SettingsReducer.language,
    };
};

export default connect(mapStateToProps)(AdmissionRequirement);