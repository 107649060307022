import React from "react";
import { useController } from "react-hook-form";
import { InputComponentProps } from "./InputComponentProps";
import { useTranslation } from "react-i18next";
import ChipInput from "material-ui-chip-input";
import InputLabel from "@material-ui/core/InputLabel";
import '../../../styles/Form.scss';

const MultiTextInput: React.FunctionComponent<InputComponentProps> = (
  props
) => {
  const { field, meta } = useController({
    ...props,
    rules: {
      ...props.rules,
      validate: (value) => props.required ?
        value.length >= 1 ? undefined : t("FORM_INPUT_REQUIRED")
        : true,
    },
    name: props.customControlName || props.name,
  });

  const { t } = useTranslation();

  //  for when the name contains full stops
  let nameHops = props.customControlName ? props.customControlName.split('.') : props.name.split('.');
  let thisError: any = props.errors;
  for (let hop of nameHops) {
    thisError = thisError ? hop ? thisError[hop] : thisError : undefined;
  }

  return (
    <React.Fragment>
      <InputLabel className='input-label'>{props.name + (props.required ? "*" : "")}</InputLabel>
      <ChipInput
        id={props.id}
        newChipKeyCodes={[9, 13]}
        error={meta.invalid}
        onChange={(s) => {
          if (props.onChange) props.onChange(s);
          return field.onChange(s);
        }}
        onBlur={field.onBlur}
        defaultValue={Array.isArray(props?.defaultValue) ? props.defaultValue : []}
        allowDuplicates={false}
      />

      <span
        style={{
          marginBottom: 15,
          display: "inline-block",
        }}
      >
        Press <kbd>Enter</kbd> or <kbd>Tab</kbd> to add to list
      </span>
      {props.helperText && <>{props.helperTextCustomStyle?.compact ? <></> : <br />}<InputLabel style={props.helperTextCustomStyle?.style || {}} id={`simple-helper-text-label-${props.id}`}>{props.helperText}</InputLabel></>}
      {thisError?.type === "required" && (
        <span className="error">{t("FORM_INPUT_REQUIRED")}</span>
      )}
      {thisError?.type === "validate" && (
        <span className="error">{thisError?.message || t("INVALID")}</span>
      )}
    </React.Fragment>
  );
};

export default MultiTextInput;
