import { Dispatch } from 'react';
import * as types from '../types/drawer.types';

export const changeSelectedItem = (index: number) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: types.CHANGE_DRAWER_INDEX,
    payload: {
      index
    }
  })
}
